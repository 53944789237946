import { Application } from 'types';
import { Box } from '@mui/material';
import ExpandableSection from 'ui/components/expandable-section/ExpandableSection';
import SummaryTextGrid from 'ui/components/summary-text-grid/SummaryTextGrid';
import PermitCard from 'app-sections/common/applications/permits/PermitCard';

export interface PermitsProps {
  data: Application;
}

export default function Permits(props: PermitsProps) {
  const { data } = props;

  return (
    <ExpandableSection title="Permits" defaultExpanded={true} >
      <Box mb={3}>
        <SummaryTextGrid
          items={[
            {
              label: "Application Type",
              desc: data.permitNumbers.length > 1 ? "Multi lot application" : "Single lot application",
            },
          ]}
        />
      </Box>

      <Box>
        {data.permitNumbers.map((permitNumber) => (
          <Box key={permitNumber.recordId} mt={4}>
            <PermitCard data={permitNumber} />
          </Box>
        ))}
      </Box>
    </ExpandableSection>
  );
}
