export const parseMoney = (amountCents: number): string => {
  if (!isNaN(amountCents)) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(amountCents / 100);
  }
  return '-';
};
