import { UserProfile } from 'types';
import * as Yup from 'yup';

export interface FormValues {
  surveyorId: string;
  surveyor: UserProfile;
}

export const validationSchema = Yup.object({
  surveyorId: Yup.string().trim(),
});
export const defaultValues: FormValues = {
  surveyorId: "",
  surveyor: {
    email: "",
    id: "",
    userId: "",
    firstName: "",
    lastName: "",
    fullName: "",
    contactPhone: "",
    roles: [],
    divisions: [],
    accelaId: '',
    firmName: "",
    firmAddress: "",
    registrationNumber: "",

    emergencyEmail: '',
    badgeNumber: '',
    emergencyPhone: '',
    isActive: true,
  }
};

const detailsFields = {
};

export const getValidationSchema = () => {
  return Yup.object({
    ...detailsFields,
  });
};
