import { getSslValidation } from 'ui/form-validations/sslValidation';
import * as Yup from 'yup';

export interface FormValues {
  ssl: string;
}

export const defaultValues: FormValues = {
  ssl: '',
};

const detailsFields = {
  ssl: getSslValidation().required('Field is required'),
};

export const getValidationSchema = () => {
  return Yup.object({
    ...detailsFields,
  });
};
