import { AccelaPermitConditions } from 'types';

import { request } from '../config';

const PERMIT_BY_ID_URL = '/accela/get-permit.json';
const GET_CONDITIONS_URL = '/accela/get-conditions';

export function getPermitById<T>(permit_id: string): Promise<T> {
  return request(
    {
      url: PERMIT_BY_ID_URL,
      params: {
        permit_id,
      },
      method: 'GET',
    },
    (data) => data.model
  );
}

export function getPermitsByIds<T>(permitIds: string[]): Promise<T> {
  return Promise.all(permitIds.map((permitId) => getPermitById(permitId))) as Promise<T>;
}

export function getConditionsInfo(permitIds: string[], signal?: AbortSignal): Promise<AccelaPermitConditions[]> {
  return Promise.all(permitIds.map((permitId) => getPermitConditionsById(permitId, signal)));
}

export function getPermitConditionsById(permitId: string, signal?: AbortSignal): Promise<AccelaPermitConditions> {
  return request(
    {
      url: GET_CONDITIONS_URL,
      method: 'GET',
      params: { permit_id: permitId },
      signal,
    },
    (data) => {
      return { ...data.model, id: permitId };
    }
  );
}
