import Error404Page from 'core/layouts/error-pages/Error404Page';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

import { applicationDetailsReviewRoutes } from './applicationDetailsReviewRoutes';

const Summary = lazy(
  () =>
    import(
      "app-sections/user/applications/details/components/sections/summary/Summary"
    )
);

const ReviewDetails = lazy(
  () =>
    import(
      "app-sections/user/applications/details/components/sections/review-details/ReviewDetails"
    )
);

export const applicationDetailsRoutes: RouteObject[] = [
  {
    path: "",
    element: <Navigate replace to="summary" />,
  },
  {
    path: "summary",
    element: (
      <Suspense fallback={<DataLoader />}>
        <Summary />
      </Suspense>
    ),
  },
  {
    path: "review-details",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetails />
      </Suspense>
    ),
    children: applicationDetailsReviewRoutes,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
  {
    path: ":any/*",
    element: <Error404Page />,
  },
];
