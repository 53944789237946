

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function BookingsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 24" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.62329 3.75391C1.62329 2.92548 2.29486 2.25391 3.12329 2.25391H22.6983C23.5267 2.25391 24.1983 2.92548 24.1983 3.75391V20.2539C24.1983 21.0823 23.5267 21.7539 22.6983 21.7539H3.12329C2.29486 21.7539 1.62329 21.0823 1.62329 20.2539V3.75391Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.62329 6.75391H24.1983"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9732 17.2539L18.8888 15.3149C18.5863 15.0334 18.176 14.8752 17.7482 14.8752C17.3204 14.8752 16.9101 15.0334 16.6076 15.3149L14.5232 17.2539V6.75391H20.9732V17.2539Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
