import { Application, Params } from "types";

import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Pagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import ApplicationsList from "../applications-list/ApplicationsList";
import ApplicationsTable from "../applications-table/ApplicationsTable";

export interface ApplicationsDataProps {
  isFetching: boolean;
  parsedSearchParams: Params;
  data: Application[];
  page: string;
  totalPages: number;
  onPageChange: (page: string) => void;
}

export default function ApplicationsData(props: ApplicationsDataProps) {
  const {
    data,
    page,
    onPageChange,
    totalPages,
    isFetching,
    parsedSearchParams,
  } = props;
  const theme = useTheme();
  const matchesTablet = useMediaQuery(theme.breakpoints.up("tablet"));

  const currentPage = Number(page);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(String(value));
  };

  const tab = parsedSearchParams.states.includes("completed") ? 'closed' : 'in_progress';

  return (
    <>
      <Box minHeight="4px">
        {isFetching && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="secondary" />
          </Box>
        )}
      </Box>
      {!matchesTablet && <ApplicationsList data={data} tab={tab} />}
      {matchesTablet && <ApplicationsTable data={data} tab={tab} />}
      {totalPages > 0 && (
        <Box mt={-4}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent={matchesTablet ? "flex-end" : "center"}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handleChange}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}
