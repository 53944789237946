

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26 25">
      <g fill="none">
        <path
          d="M8.5 12.0039H24.25"
          stroke="#67707E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5 15.7539L24.25 12.0039L20.5 8.25391"
          stroke="#67707E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.7496 16.5V21C16.7852 21.7902 16.1756 22.4606 15.3856 22.5H3.11258C2.32297 22.4601 1.71391 21.7898 1.74958 21V3C1.71335 2.20998 2.32271 1.53937 3.11258 1.5H15.3856C16.1756 1.53938 16.7852 2.20981 16.7496 3V7.5"
          stroke="#67707E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
