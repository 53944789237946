import { updateAdditionalAddresses } from 'core/api/applications';
import { updateOneCacheItem } from 'core/cache/helpers';
import { useState } from 'react';
import { AdditionalAddressItem, Application } from 'types';
import AddressFormatted from 'ui/components/address-formatted/AddressFormatted';
import ButtonLoader from 'ui/components/button-loader/ButtonLoader';
import { ConfirmationDialog } from 'ui/components/confirmation-dialog/ConfirmationDialog';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import PencilIcon from 'ui/icons/PencilIcon';
import TrashIcon from 'ui/icons/TrashIcon';

import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface ItemViewProps {
  parentId: string;
  item: AdditionalAddressItem;
  isEditable: boolean;
  items: AdditionalAddressItem[];
  onButtonEdit: () => void;
}

export default function ItemView(props: ItemViewProps) {
  const { item, onButtonEdit, isEditable, parentId, items } = props;

  const [dialogState, setDialogState] = useState<boolean>(false);
  const theme = useTheme();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: (data: AdditionalAddressItem[]) => updateAdditionalAddresses(parentId, data),
    onSuccess: (newItems: AdditionalAddressItem[]) => {
      updateOneCacheItem<Application>(queryClient, ['applications', parentId], { additionalAddresses: newItems });
    },
  });

  const onDialogResult = (result: boolean): void => {
    if (result) {
      setDialogState(false);
      deleteMutation.mutate(items.filter((i) => i.id !== item.id));
    }
  };

  const isLoading = deleteMutation.isPending;

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box
        flex={'1 1'}
        sx={{
          fontSize: '2rem',
          mb: 1,
          color: theme.palette.primary.main,
          fontWeight: 'bold',
          width: '100%',
          wordWrap: 'break-word',
          '& div': {
            wordWrap: 'break-word !important',
          },
        }}
      >
        <AddressFormatted address={item} commaSeparated={true} isInline={true} />
      </Box>
      {isEditable && (
        <Box mt={-0.5} display="flex" justifyContent="flex-end" width="80px" alignItems="flex-start" flex={'0 0 80px'}>
          {!isLoading && (
            <IconButton
              aria-label="Delete"
              disabled={isLoading}
              onClick={() => {
                setDialogState(true);
              }}
            >
              <TrashIcon sx={{ fontSize: '2rem' }} />
            </IconButton>
          )}
          {isLoading && (
            <Box width="40px" height="40px" display="flex" justifyContent="center" alignItems="center">
              <ButtonLoader isActive={true} noMargin={true} />
            </Box>
          )}

          <IconButton
            aria-label="Edit"
            disabled={isLoading}
            onClick={() => {
              onButtonEdit();
            }}
          >
            <PencilIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Box>
      )}
      {deleteMutation.isError && (
        <Box mt={2}>
          <ErrorsList title="Error removing address" errors={deleteMutation.error} />
        </Box>
      )}
      <ConfirmationDialog
        title=""
        description={
          <Box mb={2} padding={0} maxWidth="576px">
            <Box mt={2} textAlign="center" color="main.dark">
              <Typography component="h2" variant="h2">
                Confirm deletion
              </Typography>
            </Box>
            <Box fontSize="2rem" textAlign="center">
              You are about to delete this address. After deletion this it won’t be available anymore.
            </Box>
          </Box>
        }
        opened={dialogState}
        confirmButtonVariant="contained"
        onConfirm={() => {
          onDialogResult(true);
        }}
        onCancel={() => {
          setDialogState(false);
        }}
      />
    </Box>
  );
}
