import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ArrowLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 22">
      <g fill="none">
        <path
          d="M13.5422 20.375L4.60891 11.4417C4.49166 11.3246 4.42578 11.1657 4.42578 11C4.42578 10.8343 4.49166 10.6754 4.60891 10.5583L13.5422 1.625"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
