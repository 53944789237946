import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Params, UserData } from 'types';
import StatusCard from 'ui/components/status-card/StatusCard';
import { isClient } from 'ui/helpers/users';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

const WARNING_STATES = ['swo'];

function getSWOInfoContent(user: UserData | null): Params {
  if (isClient(user?.userProfile.roles || [])) {
    return {
      title: 'This application is on hold',
      content: (
        <>
          A Stop Work Order (SWO) restriction has been placed on this address or on an associated permit. The Review
          Process will be halted until the SWO is lifted.
          <br />
          You shall receive a notification once the restriction has been lifted.
        </>
      ),
    };
  }

  return {
    title: 'This application is on hold',
    content: (
      <>
        A Stop Work Order (SWO) restriction has been placed on this address or on an associated permit.
        <br />
        The Review Process will be halted until the SWO is lifted.
      </>
    ),
  };
}

export default function ApplicationStateListener() {
  const { id: activeApplicationId = '' } = useParams();
  const applicationQueryKey = ['applications', activeApplicationId];
  const { setSwoError } = useApplicationsStore();
  const { currentUser } = useAuthStore();

  const [showStateInfo, setShowStateInfo] = useState<Params | null>(null);

  const { data, isSuccess } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== '',
  });

  useEffect(() => {
    if (data && isSuccess) {
      const isStateNotWarning = !WARNING_STATES.includes(data.state);

      if (isStateNotWarning) {
        setShowStateInfo(null);
      } else {
        setShowStateInfo(getSWOInfoContent(currentUser));
      }

      if (data.state === 'swo') {
        setSwoError(true);
      } else {
        setSwoError(false);
      }
    }
  }, [isSuccess, data?.state]);

  if (!showStateInfo) {
    return null;
  }

  return (
    <Box mt={5}>
      <StatusCard title={showStateInfo.title} content={showStateInfo.content} isLoading={false} status="failed" />
    </Box>
  );
}
