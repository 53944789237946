

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CheckSuccessIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 22">
      <path
        d="M6.625 8.50083L9.37 12.3433C9.59901 12.6639 9.96369 12.8603 10.3574 12.875C10.7511 12.8896 11.1294 12.721 11.3817 12.4183L20.375 1.625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M14.9857 4.18723C11.3015 1.90725 6.50302 2.67084 3.7085 5.98178C0.913987 9.29273 0.967244 14.1513 3.83366 17.4002C6.70008 20.6491 11.5142 21.3073 15.1475 18.9471C18.7809 16.5869 20.1364 11.921 18.3332 7.9814"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
