import { useAuthStore } from "core/store/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "ui/components/confirmation-dialog/ConfirmationDialog";
import { isAdmin } from "ui/helpers/users";

import { Box, Typography } from "@mui/material";

export default function UserInactiveInfo() {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuthStore();
  if (!currentUser) {
    return null;
  }

  if (
    isAdmin(currentUser.userProfile.roles) ||
    currentUser.userProfile.isActive ||
    location.pathname === "/profile"
  ) {
    return null;
  }

  return (
    <ConfirmationDialog
      title=""
      description={
        <Box mb={2}>
          <Box mt={2} textAlign="center" color="main.dark">
            <Typography component="h2" variant="h2">
              Your profile is deactivated
            </Typography>
          </Box>
          <Box fontSize="2rem" textAlign="center">
            Only users with active profiles can interact with the site and
            receive notifications.
            <br /> Click button below to reactivate your profile.
          </Box>
        </Box>
      }
      opened={true}
      confirmButtonClass={"primary"}
      confirmButtonVariant="contained"
      confirmButtonText="Go to my profile"
      onConfirm={() => {
        navigate("/profile");
      }}
    />
  );
}
