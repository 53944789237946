import { isNowBeforeSlaStartedAt, slaDeadline } from 'app-sections/common/applications/helpers';
import { Application } from 'types';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import RemainingTimeCounter from './RemainingTimeCounter';

export interface ApplicationRemainingTimeCounterProps {
  application: Application;
  onExpiration: () => void;
}

export default function ApplicationRemainingTimeCounter(props: ApplicationRemainingTimeCounterProps) {
  const { application, onExpiration } = props;

  const slaNotStarted = isNowBeforeSlaStartedAt(application);
  // console.log('slaNotStarted', slaNotStarted);
  const hoverText = slaNotStarted
    ? 'SLA starts at 9 am the next day after the documents are submitted by the DC register surveyor.'
    : '';
  return (
    <Tooltip placement="bottom" title={hoverText}>
      <Box>
        <RemainingTimeCounter
          date={slaDeadline(application)}
          onExpiration={onExpiration}
          isPaused={application.state === 'client_action_needed' || slaNotStarted}
        />
      </Box>
    </Tooltip>
  );
}
