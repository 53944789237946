import { get, mergeWith } from "lodash-es";
import { FieldErrors, FieldValues } from "react-hook-form";
import { Params } from "types";

export const checkErrors = (
  field: keyof FieldValues,
  errors: FieldErrors
): boolean => {
  return get(errors, field) ? true : false;
};

export const getErrorMessage = (
  field: keyof FieldValues,
  errors: FieldErrors
): string | null => {
  return (get(errors, `${field}.message`) as unknown as string) || null;
};

export const mergeData = (defaultValues: Params, values: Params): Params => {
  return mergeWith({}, defaultValues, values, (a, b) =>
    b === null ? a : undefined
  );
};
