import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ViewIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.25096C7.969 5.18296 3.8 7.99996 1.179 10.885C0.609182 11.5176 0.609182 12.4784 1.179 13.111C3.743 15.935 7.9 18.817 12 18.748C16.1 18.817 20.258 15.935 22.824 13.111C23.3938 12.4784 23.3938 11.5176 22.824 10.885C20.2 7.99996 16.031 5.18296 12 5.25096Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.75 12C15.7494 14.071 14.0703 15.7494 11.9993 15.749C9.9284 15.7487 8.24982 14.0696 8.25 11.9987C8.25018 9.92775 9.92906 8.24902 12 8.24902C12.9948 8.24876 13.9489 8.6439 14.6523 9.34743C15.3556 10.051 15.7505 11.0052 15.75 12V12Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
