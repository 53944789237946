import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';

export interface InfoCardProps {
  label: string;
  info: string | JSX.Element;
  icon: JSX.Element;
  isWarning?: boolean;
  styled?: boolean;
}

export default function InfoCard(props: InfoCardProps) {
  const { label, info, icon, isWarning = false, styled = true } = props;

  const theme = useTheme();

  const iconClasses = isWarning
    ? "rounded-small card-type-error no-margin-bottom"
    : "rounded-small card-type-superdark no-margin-bottom";

  const color = isWarning
    ? theme.palette.error.main
    : theme.palette.primary.main;

  return (
    <Card className="rounded-small small-margin-bottom">
      <CardContent
        sx={{
          margin: 0,
          padding: "22px 22px !important",
          display: "flex",
          alignItems: "start",
        }}
      >
        <Card className={iconClasses} sx={{ mr: 2, flexShrink: 0 }}>
          <CardContent
            sx={{
              width: 55,
              height: 48,
              margin: 0,
              padding: "0 !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </CardContent>
        </Card>
        <Box>
          <Typography
            variant="subtitle1"
            component="h3"
            sx={{
              fontWeight: "normal",
              margin: 0,
              lineHeight: 1.3,
              color: color,
            }}
          >
            {label}
          </Typography>
          {styled && (<Box
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: color,
              lineHeight: 1.3,
            }}
          >
            {info}
          </Box>)}
          {!styled && info}
        </Box>
      </CardContent>
    </Card>
  );
}
