import { Box, Skeleton } from '@mui/material';

export default function FormButtonsSkeleton() {
  return (
    <Box mx={-1} display="flex" justifyContent="space-between">
      <Box mx={1}>
        <Skeleton variant="rectangular" width={85} height={40} />
      </Box>
      <Box mx={1}>
        <Skeleton variant="rectangular" width={150} height={40} />
      </Box>
    </Box>
  );
}
