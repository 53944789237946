import InfoMessage from '../info-message/InfoMessage';

export interface ErrorsListProps {
  title?: string;
  errors: string[];
}

export default function ErrorsList(props: ErrorsListProps) {
  const { errors, title = "Please provide correct data" } = props;

  const isErrorsArray = Array.isArray(errors);

  return (
    <>
      {errors && isErrorsArray && (
        <InfoMessage
          type="error"
          title={title}
          content={
            <ul>
              {errors.map((err, idx) => {
                return <li key={idx}>{err}</li>;
              })}
            </ul>
          }
          notClosable={true}
        />
      )}
      {errors && !isErrorsArray && (
        <InfoMessage
          type="error"
          title={title}
          content="Unknown error."
          notClosable={true}
        />
      )}
    </>
  );
}
