import { SvgIcon, SvgIconProps } from '@mui/material';

export default function StepSuccessIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 20">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.87333 17.4012L2.34749 11.8746C1.42871 10.9047 1.44906 9.37961 2.3934 8.43459C3.33774 7.48958 4.86279 7.46813 5.83333 8.38622L9.34833 11.9012L15.9317 3.12372C16.7488 2.03319 18.2953 1.81157 19.3858 2.62872C20.4764 3.44587 20.698 4.99236 19.8808 6.08289L11.5917 17.1371C11.1625 17.7091 10.5062 18.0668 9.79277 18.1175C9.07938 18.1682 8.3791 17.9069 7.87333 17.4012Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
