import { Application, ApplicationState, Status } from 'types';

const APPLICATION_COMPLETED_STATES: Array<ApplicationState> = ['completed', 'completed_below_grade', 'completed_partially', 'denied'];

export function isCompleted(application: Application): boolean {
  return APPLICATION_COMPLETED_STATES.includes(application.state);
}

export function isCompletedByFullStatus(status: Status): boolean {
  return APPLICATION_COMPLETED_STATES.map( state => `app.statuses.applications.${state}`).includes(status.name);
}
