import { ApplicationState } from 'types';

import { Box } from '@mui/material';

export interface ApplicationSWOInfoProps {
  data: ApplicationState;
}

export default function ApplicationSWOInfo(props: ApplicationSWOInfoProps) {
  const { data } = props;
  if (data !== 'swo') {
    return null;
  }

  return <Box sx={{ color: '#d50037' }}>SLA stopped due SWO</Box>;
}
