import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ArrowRightIcon from 'ui/icons/ArrowRightIcon';

import { Box, SxProps, Theme, useTheme } from '@mui/material';

interface BreadCrumbItem {
  link: string;
  label: string;
}

export interface BreadCrumbsProps {
  data: BreadCrumbItem[];
}

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      margin: "0 0 8px 0",
      padding: 0,
      listStyle: "none",
      fontSize: "1.2rem",
      fontWeight: "normal",
      "& a": {
        textDecoration: "none",
        color: theme.palette.primary.dark,
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    separator: {
      width: 10,
      height: 10,
      margin: "-6px 8px 0 8px",
      "& svg": {
        width: 10,
        height: 10,
        "& path": {
          strokeWidth: 2.5,
        },
      },
    },
  };
};

export default function BreadCrumbs(props: BreadCrumbsProps) {
  const { data } = props;

  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <Box component="ul" sx={styles.root}>
      {data.map((item: BreadCrumbItem, index: number) => (
        <Fragment key={index}>
          <li>
            {item.link && <Link to={item.link}>{item.label}</Link>}
            {!item.link && <span>{item.label}</span>}
          </li>
          {index < data.length - 1 && (
            <Box component="li" sx={styles.separator}>
              <ArrowRightIcon />
            </Box>
          )}
        </Fragment>
      ))}
    </Box>
  );
}
