import { Application, ApplicationState } from 'types';

import { SxProps, Theme, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import { stepperStatusesForApplication } from '../../../sections/summary/components/general-info/GeneralInfo';
import { getSubStateName } from '../../../sections/summary/components/status-stepper/StatusStepper';

const STATE_CLASS: Record<ApplicationState, string> = {
  draft: "upcoming",
  pending_payment: "pendingPayment",
  paid: "paid",
  pending: "pending",
  surveyor_office_review: "inReview",
  zoning_review: "inReview",
  completed: "completed",
  completed_below_grade: "completed",
  completed_partially: "completed",
  denied: "cancelled",
  deleted_by_client: "cancelled",
  swo: "swo",
  client_action_needed: "clientActionNeeded",
};

export interface ApplicationStateBadgeProps {
  appState: ApplicationState;
  application: Application;
  type?: "client" | "admin";
}

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    badge: {
      display: "flex",
      textTransform: "uppercase",
      borderRadius: "5px",
      height: "35px",
      fontSize: "14px",
      padding: "0 10px",
      alignItems: "center",
      "&.cancelled, &.swo": {
        border: `1px solid #aa002c`,
        background: "#ffe9ee",
        color: "#535863",
      },
      "&.completed": {
        border: "1px solid #166d12",
        background: "#e9f3e8",
        color: "#535863",
      },

      "&.upcoming": {
        border: `1px solid rgba(103, 112, 126, 0.5)`,
        color: theme.palette.text.primary,
        background: "rgba(225, 229, 233, 0.5)",
      },
      "&.pending": {
        border: `1px solid #67707E`,
        color: theme.palette.text.primary,
        background: "rgba(225, 229, 233, 0.5)",
      },
      "&.pendingPayment": {
        border: `1px solid #B05E11`,
        color: theme.palette.text.primary,
        background: "#FFF3E6",
      },
      "&.clientActionNeeded": {
        border: `1px solid #B05E11`,
        color: theme.palette.text.primary,
        background: "#FFF3E6",
      },
      "&.draftInProgress": {
        border: `1px solid #67707E`,
        color: theme.palette.text.primary,
        background: "#0062A0",
      },
      "&.inReview, &.readyForIssuance": {
        border: `1px solid #67707E`,
        color: theme.palette.common.white,
        background: "#0062A0",
      },
    },
  };
};

export default function ApplicationStateBadge(
  props: ApplicationStateBadgeProps
) {
  const { appState, application } = props;
  const theme = useTheme();
  const styles = getStyles(theme);
  const stepperStatus = stepperStatusesForApplication(application);
  const label = getSubStateName(
    stepperStatus.status,
    stepperStatus.subStatus,
    theme
  );

  return (
    <Box sx={styles.badge} className={STATE_CLASS[appState]}>
      {label}
    </Box>
  );
}
