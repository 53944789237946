import { SvgIcon, SvgIconProps } from '@mui/material';

export default function FileTypeImageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4769 21.75C22.4769 22.5784 21.8054 23.25 20.9769 23.25H3.02295C2.19452 23.25 1.52295 22.5784 1.52295 21.75V2.25C1.52295 1.42157 2.19452 0.75 3.02295 0.75H18.0229C18.4141 0.750039 18.7899 0.902905 19.07 1.176L22.025 4.059C22.3142 4.34146 22.4772 4.72872 22.4769 5.133V21.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27295 9.75C9.51559 9.75 10.5229 8.74264 10.5229 7.5C10.5229 6.25736 9.51559 5.25 8.27295 5.25C7.03031 5.25 6.02295 6.25736 6.02295 7.5C6.02295 8.74264 7.03031 9.75 8.27295 9.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18.8129 18.2007L14.8879 12.3127C14.7028 12.0341 14.3919 11.8652 14.0575 11.8614C13.723 11.8576 13.4084 12.0194 13.2169 12.2937L10.5259 16.1387L8.85795 14.8007C8.63657 14.6233 8.35085 14.547 8.07052 14.5906C7.7902 14.6342 7.54107 14.7935 7.38395 15.0297L5.27295 18.2007"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
