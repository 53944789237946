import { groupBy } from 'lodash-es';
import { Params, ZoningReview } from 'types';
import { request } from '../config';

const REVIEWS_URL = "/zoning_reviews";

export function getZoningReviews<T>(applicationId: string, permitRecordIds: string[]): Promise<T> {
  return request(
    {
      url: `${REVIEWS_URL}.json`,
      method: "GET",
      params: { applicationId, permitRecordIds },
    },
    (data) => data.models.results
  );
}

export function createApprovedZoningReview(
  data: Params,
): Promise<ZoningReview> {
  return request(
    {
      url: `${REVIEWS_URL}/approve.json`,
      method: "POST",
      data: { zoning_review: data },
    },
    (data) => data.model
  );
}

export function createDeniedZoningReview(
  data: Params,
): Promise<ZoningReview> {
  return request(
    {
      url: `${REVIEWS_URL}/deny.json`,
      method: "POST",
      data: { zoning_review: data },
    },
    (data) => data.model
  );
}

export function createPendingZoningReview(
  data: Params,
): Promise<ZoningReview> {
  return request(
    {
      url: `${REVIEWS_URL}/pend.json`,
      method: "POST",
      data: { zoning_review: data },
    },
    (data) => data.model
  );
}

export function createPendingFromSurveyorOfficeZoningReview(
  data: Params,
): Promise<ZoningReview> {
  return request(
    {
      url: `${REVIEWS_URL}/pend_from_surveyor_office.json`,
      method: "POST",
      data: { zoning_review: data },
    },
    (data) => data.model
  );
}

export function zoningReviewsGroupedByPermit(applicationId: string, permitRecordIds: string[], zoningReviews: ZoningReview[]) {
  const zoningReviewsGrouped = groupBy(
    zoningReviews,
    'permitRecordId'
  );
  const permits = permitRecordIds.map(
    (permitRecordId) => {
      return {
        recordId: permitRecordId,
        applicationId: applicationId,
        zoningReviews: zoningReviewsGrouped[permitRecordId] || []
      }
    }
  )
  return permits;
}

export function answerZoningReview(
  id: string,
  data: Params,
): Promise<ZoningReview> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}/answer.json`,
      method: "PATCH",
      data: { zoning_review: { ...data } },
    },
    (data) => data.model
  );
}
