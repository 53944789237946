import ApplicationSWOInfo from 'app-sections/admin/applications/components/application-swo-info/ApplicationSWOInfo';
import { commonTableStyles } from 'app-sections/common/applications/commonTableStyles';
import { getStateBadgeLabel } from 'app-sections/common/applications/details/components/details-header/components/application-state-badge/helpers';
import ApplicationAddresses from 'app-sections/user/applications/components/applications/application-addresses/ApplicationAddresses';
import { Application } from 'types';

import { Box, Card, SxProps, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import ApplicationDataId from '../application-data-id/ApplicationDataId';
import ApplicationDataOptions from '../application-data-options/ApplicationDataOptions';

export interface ApplicationsTableProps {
  data: Application[];
}

const getStyles = (): { [key: string]: SxProps } => {
  return commonTableStyles;
};

export default function ApplicationsTable(props: ApplicationsTableProps) {
  const { data } = props;

  const styles = getStyles();
  return (
    <Card className="no-shadow">
      <Box sx={styles.tableWrapper}>
        <Table sx={styles.table} aria-label="Agency Applications table">
          <TableHead>
            <TableRow>
              <TableCell>Appl. #</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Permit(s)</TableCell>
              <TableCell>Application Address</TableCell>
              <TableCell>SSL</TableCell>
              <TableCell>Applicant</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: Application) => (
              <TableRow key={item.id} className={item.state === 'swo' || item?.swoSetAt ? 'is-warning' : ''}>
                <TableCell scope="row">
                  <ApplicationDataId item={item} />
                  <ApplicationSWOInfo data={item.state} />
                </TableCell>
                <TableCell>{getStateBadgeLabel('client', item.state)}</TableCell>
                <TableCell>
                  {item.permitRecordIds.map((id) => (
                    <div key={id}>
                      {id}
                      <br />
                    </div>
                  ))}
                </TableCell>
                <TableCell scope="row">
                  <ApplicationAddresses application={item} />
                </TableCell>

                <TableCell>{item.ssl}</TableCell>
                <TableCell>{item.clientProfile?.fullName}</TableCell>
                <TableCell align="right">
                  <ApplicationDataOptions item={item} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
}
