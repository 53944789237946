import ApplicationStateListener from 'app-sections/common/applications/application-state-listener/ApplicationStateListener';

import { Box, Container } from '@mui/material';

import DetailsTabs from './components/details-tabs/DetailsTabs';
import DetailsTitle from './components/details-title/DetailsTitle';

export default function DetailsHeader() {
  return (
    <Box mt={5}>
      <Container className="disable-center">
        <DetailsTitle />
        <ApplicationStateListener />
        <DetailsTabs />
      </Container>
    </Box>
  );
}
