import { Grid, Skeleton } from "@mui/material";

import FormButtonsSkeleton from "../form-buttons-skeleton/FormButtonsSkeleton";

export interface LinesSkeletonProps {
  elements?: number;
  isWithButtons?: boolean;
  spacing?: number;
  lineHeight?: number;
  color?: string;
}

export default function LinesSkeleton(props: LinesSkeletonProps) {
  const {
    elements = 6,
    isWithButtons = true,
    spacing = 4,
    lineHeight = 15,
    color,
  } = props;
  return (
    <Grid container spacing={spacing}>
      {Array.from(Array(elements).keys()).map((item, index) => (
        <Grid key={`skeleton-${index}`} item xs={12}>
          <Skeleton
            variant="rectangular"
            height={lineHeight}
            sx={color ? { bgcolor: color } : {}}
          />
        </Grid>
      ))}

      {isWithButtons && (
        <Grid item xs={12}>
          <FormButtonsSkeleton />
        </Grid>
      )}
    </Grid>
  );
}
