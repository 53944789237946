import { useState } from "react";
import { ExternalButton } from 'ui/components/external-button/ExternalButton';
import ButtonLoader from "ui/components/button-loader/ButtonLoader";

import { Button } from '@mui/material';

const getUrl = () => {
  return `/api/oauth/login_redirect?host=${window.location.origin}`;
  // return `https://tertius.dcra.dc.gov/access-dc`;
};

export interface RegisterButtonProps {
  label?: string;
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
  variant?: "contained" | "text" | "outlined";
}

export default function RegisterButton(props: RegisterButtonProps) {
  const {
    label = "Register",
    color = "primary",
    variant = "contained",
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Button
      color={color}
      variant={variant}
      disableElevation
      component={ExternalButton}
      onClick={() => {
        setIsLoading(true);
        window.location.href = getUrl();
      }}
    >
      <ButtonLoader isActive={isLoading} />
      {label}
    </Button>
  );
}
