import './sidebar-layout.css';

import { Outlet } from 'react-router-dom';
import ScrollController from 'ui/components/scroll-controller/ScrollController';

import LayoutErrorBoundary from '../../../errors/LayoutErrorBoundary';
import PublicToolbar from '../public-toolbar/PublicToolbar';

export default function PublicAppLayout() {
  return (
    <div className="root">
      <div className="layout-wrapper">
        <PublicToolbar />
        <main className="content" id="main-scroll-container" >
          <ScrollController />
          <LayoutErrorBoundary>
            <Outlet />
          </LayoutErrorBoundary>
        </main>
      </div>
    </div>
  );
}
