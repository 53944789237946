import * as Yup from 'yup';

// Client want to have only numbers in phone
export const getSslValidation = () => {
  return Yup.string()
    .trim()
    .matches(/^\d{4}\d{4}$/, 'SSL number is incorrect')
    .nullable()
    .transform((value: string, originalValue: string) => (String(originalValue).trim() === '' ? null : value));
};
