

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function InfoCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 22" fill="none">
      <path
        d="M12.875 14.75H12.25C11.5596 14.75 11 14.1904 11 13.5V10.375C11 10.0298 10.7202 9.75 10.375 9.75H9.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.6875 6.625C10.5149 6.625 10.375 6.76491 10.375 6.9375C10.375 7.11009 10.5149 7.25 10.6875 7.25C10.8601 7.25 11 7.11009 11 6.9375C11 6.76491 10.8601 6.625 10.6875 6.625V6.625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 20.375C16.1777 20.375 20.375 16.1777 20.375 11C20.375 5.82233 16.1777 1.625 11 1.625C5.82233 1.625 1.625 5.82233 1.625 11C1.625 16.1777 5.82233 20.375 11 20.375Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
