import { useEffect, useState } from 'react';
import { Params } from 'types';

import { Box, Tab, Tabs } from '@mui/material';

import { CLOSED_STATES_VALUES, DEFAULT_STATES_VALUES, DRAFT_STATES_VALUES, PENDING_STATES_VALUES } from '../../Applications';

import { APP_FILTER_TABS } from './config';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface ApplicationsTabsProps {
  parsedSearchParams: Params;
  onChange: (sections: string[]) => void;
}

export default function ApplicationsTabs(props: ApplicationsTabsProps) {
  const { parsedSearchParams, onChange, } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const section = APP_FILTER_TABS[newValue].id;
    let statesValue;
    switch(section) {
      case "closed":
        statesValue = CLOSED_STATES_VALUES;
        break;
      case "draft":
        statesValue = DRAFT_STATES_VALUES;
        break;
      case "pending":
        statesValue = PENDING_STATES_VALUES;
        break;
      default:
        statesValue = DEFAULT_STATES_VALUES;
        break;
    }
    onChange(statesValue);
  };

  useEffect(() => {
    let index = 2;
    const states = parsedSearchParams.states as string[];
    if (states && states.includes(PENDING_STATES_VALUES[0])) {
      index = 1;
    }
    if (states && states.includes(CLOSED_STATES_VALUES[0])) {
      index = 3;
    }
    if (states && states.includes(DRAFT_STATES_VALUES[0])) {
      index = 0;
    }

    setValue(index);
  }, [parsedSearchParams.states]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Applications list filters"
        indicatorColor="secondary"
        className="uppercase"
				variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {APP_FILTER_TABS.map((item: Params, index: number) => (
          <Tab key={item.id} label={item.name} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Box>
  );
}
