import { SvgIcon, SvgIconProps } from '@mui/material';

export default function RankingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 29 25">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2354 24H10.0781V3.46667C10.0781 2.65665 10.7614 2 11.6043 2H17.7092C18.5521 2 19.2354 2.65665 19.2354 3.46667V24Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.247 22.6154C27.247 23.3801 26.4498 24 25.4664 24H19.2344V15H25.4664C26.4498 15 27.247 15.6199 27.247 16.3846V22.6154Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0751 24H3.84307C2.85969 24 2.0625 23.3402 2.0625 22.5263V11.4737C2.0625 10.6598 2.85969 10 3.84307 10H10.0751V24Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
