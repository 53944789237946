import { Controller } from "react-hook-form";

import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";

export type BasicTextInputType = "text" | "email" | "number";

export interface BasicTextInputProps {
  label: string;
  helperText?: string;
  control: any;
  inputName: string;
  placeholder?: string;
  type?: BasicTextInputType;
  onlyLetters?: boolean;
  onlyNumbers?: boolean;
  required?: boolean;
  disabled?: boolean;
  inputComponent?: any;
  inputProps?: any;
  maxLength?: number;
  onChange?: any;
  errorsInputName?: string;
  adornment?: { position: "start" | "end"; label: string };
}

export default function BasicTextInput(props: BasicTextInputProps) {
  const {
    control,
    label,
    inputName,
    helperText,
    placeholder,
    type = "text",
    onlyLetters = false,
    onlyNumbers = false,
    required,
    disabled = false,
    inputComponent,
    maxLength,
    onChange,
    adornment,
  } = props;

  const LETTERS_REGEX = /^[a-zA-Z\s]+$/;
  const NUMBERS_REGEX = /^[0-9\s]+$/;

  return (
    <Controller
      name={inputName}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error}>
          <InputLabel
            required={required ? true : false}
            shrink
            htmlFor={inputName}
          >
            {label} {}
          </InputLabel>
          <Input
            {...field}
            type={type}
            error={!!fieldState.error}
            placeholder={placeholder}
            disabled={disabled}
            inputComponent={inputComponent}
            inputProps={
              (maxLength && {
                maxLength: maxLength,
              }) ||
              {}
            }
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange();
            }}
            onKeyDown={(event) => {
              const key = event.keyCode;
              if(key === 37 || key === 38 || key === 39 || key === 40 || key === 8 || key === 46) {
                return;
              }
              if (onlyLetters && !LETTERS_REGEX.test(event.key)) {
                event.preventDefault();
              }
              if (onlyNumbers && !NUMBERS_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            endAdornment={
              adornment &&
              adornment.position === "end" && (
                <InputAdornment position="end">
                  {adornment.label}
                </InputAdornment>
              )
            }
            startAdornment={
              adornment &&
              adornment.position === "start" && (
                <InputAdornment position="start">
                  {adornment.label}
                </InputAdornment>
              )
            }
          />
          {(!!fieldState.error || helperText) && (
            <FormHelperText>
              {fieldState.error?.message || helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
