import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Container, SxProps, Typography } from '@mui/material';

import AppToolbar from '../components/app-toolbar/AppToolbar';

const getStyles = (): { [key: string]: SxProps } => {
  return {
    root: {
      display: "block",
      "& img": {
        maxWidth: "100%",
      },
    },
    wrapper: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };
};

function Error404Page() {
  const styles = getStyles();

  return (
    <>
      <AppToolbar />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={styles.wrapper}
      >
        <Container maxWidth="lg" sx={styles.root}>
          <Box mt={-6}>
            <Typography variant="h1" component="h1" className="with-indicator">
              Page not found
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" mt={5}>
            <Button
              variant="contained"
              disableElevation
              component={RouterLink}
              to="/"
              color="secondary"
            >
              Back to Dashboard
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Error404Page;
