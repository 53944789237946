import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';
import ErrorsList from 'ui/components/errors-list/ErrorsList';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

export interface ApplicationsPublicResolverProps {
  children: any;
}

export default function ApplicationsPublicResolver(
  props: ApplicationsPublicResolverProps
) {
  const { children } = props;
  const { id } = useParams();

  const { setActiveApplicationId } = useApplicationsStore();
  const [isValid, setIsValid] = useState<boolean>(false);

  const { isLoading, isError, error, isSuccess } = useQuery({
    queryKey: ["applications", id],
    queryFn: () => getApplicationById(id || "", true),
    enabled: id !== "",
    gcTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    setActiveApplicationId(id || "");
    setIsValid(false);
    if (isSuccess) {
      setIsValid(true);
    }
    if (error) {
      // just to show error message
      setIsValid(true);
    }
  }, [id, error, isSuccess]);

  if (isLoading || !isValid) {
    return (
      <Box mt={4}>
        <DataLoader />
      </Box>
    );
  }

  if (!isLoading && isError) {
    return (
      <Box p={3}>
        <ErrorsList
          title="Unexpected loading error"
          errors={error as string[]}
        />
      </Box>
    );
  }

  return <>{children}</>;
}
