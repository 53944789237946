import { Params } from 'types';
import { FileInfo } from 'ui/components/multi-file-uploader/MultiFileUploader';

export const parseDocumentResponse = (data: Params): FileInfo => {
  return {
    // id: data.id,
    documentId: data.id,
    objectId: data.objectId,
    thumbnail: "",
    url: data.fileUrl?.url,
    uppyId: data.id,
    name: data.file.file_name,
    isLoaded: true,
    isRemoved: false,
    type: data.type,
    createdAt: data.createdAt,
  };
};

export const parseDocumentCollectionResponse = (data: Params[]): FileInfo[] => {
  return data.map((item) => parseDocumentResponse(item));
};
