import { Box, Grid, useTheme } from "@mui/material";
import { ZoningReviewPermitProps } from "app-sections/admin/applications/details/components/sections/review-details/components/review-details-tabs-content/zoning-review/helpers";
import { useAuthStore } from "core/store/auth";
import { Link } from 'react-router-dom';
import { Application } from "types";
import StatusCard from 'ui/components/status-card/StatusCard';
import { isManager, isTechnician, mainRole } from "ui/helpers/users";
import RegisteredSurveyor from "../../../../../../common/applications/details/components/sections/summary/components/dc-registered-surveyor/RegisteredSurveyor";
import Details from "../../../../../../common/applications/details/components/sections/summary/components/details/Content";
import GeneralInfo from "../../../../../../common/applications/details/components/sections/summary/components/general-info/GeneralInfo";
import Log from "../../../../../../common/applications/details/components/sections/summary/components/log/Content";
import ParticipatingDivisions from "../../../../../../common/applications/details/components/sections/summary/components/participating-divisions/Content";
import ReviewManagement from "../status-info/components/review-management/ReviewManagement";
import { useQuery } from '@tanstack/react-query';
import PaymentStatusInfo from "app-sections/common/applications/details/components/sections/payment/payment-status-info/PaymentStatusInfo";
import { getReviews } from 'core/api/reviews';
import { useSearchParams } from "react-router-dom";
import { Review } from 'types';

export interface SummaryContentProps {
  data: Application;
  permits: ZoningReviewPermitProps[];
}

export default function SummaryContent(props: SummaryContentProps) {
  const { data, permits } = props;

  const { currentUser } = useAuthStore();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  const canManage = currentUser && (isManager(currentUser.userProfile.roles) || isTechnician(currentUser.userProfile.roles));

  const paymentStatus = searchParams.get('payment_status')

  const { data: reviewsData } = useQuery({
    enabled: Boolean(data?.id) && data?.id !== "",
    queryKey: ["applications", data?.id || "", "reviews"],
    queryFn: () => getReviews<Review[]>(data?.id || ""),
  });
  const noDocumentsUploaded = reviewsData && reviewsData?.length > 0 && reviewsData.at(-1)?.state  && reviewsData.at(-1)?.state === 'pending';

  return (
    <Grid container columnSpacing={4} spacing={4}>
      {paymentStatus === "success" && data.paymentState && (
        <Grid item xs={12}>
          <PaymentStatusInfo status={data.paymentState} applicationId={data.id} />
        </Grid>
      )}

      {currentUser && data.surveyorId &&
        (noDocumentsUploaded || reviewsData?.length === 0) && (
          <Grid item xs={12}>
            <StatusCard
              title="Supporting documents needed from DC Registered Surveyor"
              content={
                <>
                  The DOB review process begins after the invited DC Registered Surveyor
                  uploads all necessary documents.
                  <Box>
                    <Link
                      style={{ color: theme.palette.primary.dark }}
                      to={`/applications/${data.id}/details/review-details/surveyorsOffice`}
                    >
                      Go to the Review tab to upload the documents.
                    </Link>
                  </Box>
                 
                </>
              }
              isLoading={false}
              status="pending"
            />
          </Grid>
        )}

      {!data.surveyorId && (
        <Grid item xs={12}>
          <StatusCard
            title="Invite a dc registered surveyor to your application"
            content="Only DC Registered Surveyor can upload the necessary documents to proceed with your
            application. To this, you need to select and invite a DC Registered Surveyor."
            isLoading={false}
            status="pending"
          />
        </Grid>
      )}

      <GeneralInfo
        application={data}
        permits={permits}
      />

      <Grid item xs={12}>
        <RegisteredSurveyor isLoading={false} application={data} />
      </Grid>

      {canManage && (
        <>
          <Grid item xs={12}>
            <ParticipatingDivisions application={data} />
          </Grid>
          <Grid item xs={12}>
            <ReviewManagement
              application={data}
              role={mainRole(currentUser.userProfile.roles)}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Details data={data} />
      </Grid>

      <Grid item xs={12}>
        <Log applicationId={data.id} />
      </Grid>
    </Grid>
  );
}
