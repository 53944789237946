import { getCurrentUserData } from 'core/api/auth';
import { useAuthStore } from 'core/store/auth';
import { useUiStore } from 'core/store/ui';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { isError } from 'lodash-es';

export interface CheckIsLoggedInProps {
  children: any;
}

export default function CheckIsLoggedIn(props: CheckIsLoggedInProps) {
  const { children } = props;

  const { setCurrentUser } = useAuthStore();
  const { setIsPublicSection } = useUiStore();

  const [searchParams] = useSearchParams();

  const {isError, data, isSuccess } = useQuery({
    queryKey: ["auth"],
    queryFn: () => getCurrentUserData(),
    enabled: !searchParams.get("logged-out"),
    retry: 0,
  });

  useEffect(() => {
    if (isSuccess) {
      setCurrentUser(data.user);
      setIsPublicSection(true);
    }
    if (isError) {
      setIsPublicSection(true);
    }
  }, [isError, isSuccess]);

  return <>{children}</>;
}