import AlertTriangle from 'ui/icons/AlertTriangle';
import CheckSuccessIcon from 'ui/icons/CheckSuccessIcon';
import ClockIcon from 'ui/icons/ClockIcon';

import { Box, Card, LinearProgress, SxProps, Typography, useMediaQuery, useTheme,} from '@mui/material';

const getStyles = (): { [key: string]: SxProps } => {
  return {
    failed: {
      background: "#ffe9ee",
      border: "1px solid #D50037",
    },
    success: {
      background: "#e9f3e8",
    },
    pending: {
      background: "#fff3e6",
    },
  };
};

const getIcon = (type: StatusCardState): JSX.Element => {
  if (type === "failed") {
    return <AlertTriangle style={{ fontSize: "inherit" }} />;
  }

  if (type === "success") {
    return <CheckSuccessIcon style={{ color: "#28a96b", fontSize: "inherit" }} />;
  }

  return <ClockIcon style={{ color: "#e27816", fontSize: "inherit" }} />;
};

export type StatusCardState = "failed" | "success" | "pending";

export interface StatusCardProps {
  status: StatusCardState;
  title: string;
  content: string | JSX.Element;
  button?: JSX.Element | null;
  isLoading?: boolean;
}

export default function StatusCard(props: StatusCardProps) {
  const { status, content, button, title, isLoading = false } = props;

  const styles = getStyles();
	const theme = useTheme();
  const matchesTablet = useMediaQuery(theme.breakpoints.up("tablet"));

  return (
    <Card elevation={0} sx={styles[status]}>
      <Box
				p={matchesTablet ? 3 : 2}
				display="flex"
				flexWrap={matchesTablet ? "nowrap" : "wrap"}
				justifyContent={matchesTablet ? "auto" : "space-around"}>
        <Box
					flexBasis={matchesTablet ? "initial" : "5%"}
					fontSize={matchesTablet ? "60px" : "30px"}
					>
					{getIcon(status)}
				</Box>
        <Box ml={2} flexBasis={matchesTablet ? "initial" : "85%"}>
          <Typography
            component="h5"
            variant="h5"
            sx={{ textTransform: "uppercase", mb: 0.5 }}
						>
            {title}
          </Typography>
          <Box fontSize="2rem">{content}</Box>
        </Box>
        {button && (
          <Box
						display="flex"
						flex="1"
						flexBasis={matchesTablet ? "initial" : "100%"}
						justifyContent="flex-end"
						mt={matchesTablet ? 0 : 2}
						px={2}
						>
            <Box>{button}</Box>
          </Box>
        )}
      </Box>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Card>
  );
}
