import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiCollectionResponse } from 'types';

import { API_URL } from '../config/index';
import { getErrorMessagesAsArray } from './errorHandling';

export const client = (() => {
  // axios.defaults.xsrfCookieName = "CSRF-TOKEN";
  // axios.defaults.xsrfHeaderName = "X-CSRF-Token";

  return axios.create({
    baseURL: API_URL,
    // timeout: 1000,
    headers: {
      Accept: "*/*",
      "Content-type": "application/json; charset=UTF-8",
    },
    xsrfCookieName: "CSRF-TOKEN",
    xsrfHeaderName: "X-CSRF-Token",
  });
})();

export const request = async function (
  options: AxiosRequestConfig,
  parseFn?: (data: any) => void
) {
  const onSuccess = function (response: AxiosResponse) {
    const { data } = response;
    return parseFn ? parseFn(data) : data;
  };

  const onError = function (error: AxiosError) {
    return Promise.reject(getErrorMessagesAsArray(error));
  };

  return client(options).then(onSuccess).catch(onError);
};

export const getCollectionResponse = function <T>(
  data: any
): ApiCollectionResponse<T> {
  return {
    results: data.models.results,
    totalEntries: data.models.total_entries,
    totalPages: data.models.total_pages,
    hasNextPage: data.models.has_next_page,
    hasPreviousPage: data.models.has_previous_page,
  };
};
