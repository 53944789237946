import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

export interface TimerProps {
  date: string;
  onExpiration: () => void;
  isSilent?: boolean;
  isPaused?: boolean;
}

const getItemTime = (createdAt: string): number => {
  const startTime = dayjs(createdAt);
  const duration = startTime.diff(dayjs(), "seconds");
  return duration;
};

const formatLeadingZero = (value: number): string => {
  return ("0" + String(value)).slice(-2);
};

export default function Timer(props: TimerProps) {
  const { date, onExpiration, isSilent = false, isPaused = false } = props;

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + getItemTime(date));

  const { hours, days, minutes, pause, resume } = useTimer({
    expiryTimestamp,
    onExpire: onExpiration,
  });

  useEffect(() => {
    const t = getItemTime(date);
    if (t < 0) {
      onExpiration();
    }
  }, []);

  useEffect(() => {
    if (isPaused) {
      pause();
    } else {
      resume();
    }
  }, [isPaused]);

  if (isSilent) {
    return null;
  }

  return (
    <span>
      {formatLeadingZero(days)}&nbsp;day(s) {""}
      {formatLeadingZero(hours)}&nbsp;hour(s) {""}
      {formatLeadingZero(minutes)}&nbsp;min(s)
    </span>
  );
}
