import { Box, Card, CardContent, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getApplications } from "core/api/applications";
import { omit } from "lodash-es";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ApiCollectionResponse, Application } from 'types';
import ErrorsList from "ui/components/errors-list/ErrorsList";
import LinesSkeleton from "ui/components/skeletons/lines-skeleton/LinesSkeleton";
import { parseFormValuesToParams, parseSearchParams } from "../../common/applications/helpers";
import ApplicationsData from "./components/applications/applications-data/ApplicationsData";
import ApplicationSearchForm from "./components/applications/applications-search/ApplicationsSearchForm";
import { FormValues } from "./components/applications/applications-search/validation-schema";

export const DEFAULT_STATES_VALUES = [
  "pending",
  "surveyor_office_review",
  "zoning_review",
  "client_action_needed",
  "denied",
  "completed",
  "completed_below_grade",
  "completed_partially",
  "deleted_by_client",
];

const setDefaultValues = (params: any): any => {
  params['public'] = true;

  if (!params["searchByAddress"]) {
      params["searchByAddress"] = "";
    }
  if (!params["searchByPermitNumber"]) {
    params["searchByPermitNumber"] = "";
  }
  
  if(!params["searchByAddress"].length && !params["searchByPermitNumber"].length) {
    params["states"] = "";
  } else {
    params["states"] = DEFAULT_STATES_VALUES;
  }
  
  return params;
};

export default function Applications() {
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();
  const parsedSearchParams = setDefaultValues(parseSearchParams(searchParams));
  const [loadedData, setLoadedData] = useState<
    ApiCollectionResponse<Application> | undefined
  >();

  const queryKey = ["applications/get", searchParamsString];
  const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery({
    queryKey,
    queryFn: () => getApplications(parsedSearchParams),
  });

  const [ifReset, setIfReset] = useState<number>(0);


  const updateSearchParams = (paramsToUpdate: any) => {
    setSearchParams((currentValues) => {
      const combinedParams = {
        ...parseSearchParams(currentValues),
        ...paramsToUpdate,
      };
      return setDefaultValues(combinedParams);
    });
  };

  useEffect(() => {
    if (Object.keys(parsedSearchParams).length === 0) {
      setIfReset((prev) => prev + 1);
    }
    if (isSuccess) {
      setLoadedData(data);
    }
  }, [searchParamsString, queryClient, isSuccess]);

  return (
    <Box mt={5} display={"flex"} justifyContent={"center"}>
      <Container className="disable-center">
        <>
          <Typography variant="h1" component="h1" className="with-indicator-red">
            Wallcheck Applications
          </Typography>

          <ApplicationSearchForm
            ifReset={ifReset}
            defaults={omit(parsedSearchParams, ["states"]) as FormValues}
            currentParams={parsedSearchParams}
            isLoading={isLoading}
            onFormChange={(v) => {
              updateSearchParams(parseFormValuesToParams({ ...v, page: "1" }));
            }}
            onFormSubmit={(v) => {
              updateSearchParams(parseFormValuesToParams({ ...v, page: "1" }));
            }}
          />

          {isLoading && !isError && !loadedData &&(
            <Card>
              <CardContent>
                <Box mb={4}>
                  <LinesSkeleton
                    elements={4}
                    isWithButtons={false}
                    lineHeight={35}
                  />
                </Box>
              </CardContent>
            </Card>
          )}
          {loadedData && !isError && (
            <>
              <ApplicationsData
                parsedSearchParams={parsedSearchParams}
                isFetching={isFetching || isLoading}
                data={loadedData.results}
                page={parsedSearchParams?.page || "1"}
                totalPages={loadedData.totalPages}
                onPageChange={(page: string) => {
                  setSearchParams((currentValues) => {
                    return { ...parseSearchParams(currentValues), page };
                  });
                }}
              />
            </>
          )}

          {isError && error && (
            <ErrorsList
              title="Unexpected loading error"
              errors={error as string[]}
            />
          )}
        </>
      </Container>
    </Box>
  );
}
