import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent, Grid } from '@mui/material';
import { DEFAULT_STATES_VALUES } from 'app-sections/admin/applications/Applications';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import BasicTextInput from 'ui/form-fields/basic-fields/text-input/TextInput';
import FormWrapper from 'ui/form-fields/form-wrapper/FormWrapper';
import { defaultValues, FormValues, getValidationSchema } from './validation-schema';

export interface ApplicationSearchFormProps {
  isLoading: boolean;
  onFormSubmit: (values: FormValues) => void;
  onFormChange: (values: FormValues) => void;
  defaults?: FormValues;
  currentParams: any;
  ifReset: number;
}

export default function ApplicationSearchForm(
  props: ApplicationSearchFormProps
) {
  const {
    onFormSubmit,
    onFormChange,
    // isLoading,
    defaults,
    ifReset,
    currentParams,
  } = props;

  const { handleSubmit, control, watch, reset, setValue } =
    useForm<FormValues>({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: { ...defaultValues, ...(defaults && { ...defaults }) },
      resolver: yupResolver(getValidationSchema()),
    });

  const onSubmit = (data: FormValues) => {
    onFormSubmit(data);
  };

  const handleChange = () => {
    const data = watch();
    onFormChange(data);
  };

  const handleReset = (isSilent: boolean = false) => {
    reset(defaultValues);
    setValue("searchByAddress", null);
    setValue("searchByPermitNumber", null);
    if (!isSilent) {
      setTimeout(() => {
        handleChange();
      }, 1);
    }
  };

  useEffect(() => {
    if (ifReset > 0) {
      handleReset(true);
    }
  }, [ifReset]);

  const currentStatus = watch("state");
  const appStatusesIds = currentParams?.states || DEFAULT_STATES_VALUES;

  if (!appStatusesIds.includes(currentStatus) && currentStatus !== "") {
    setTimeout(() => {
      setValue("state", "");
    }, 1);
  }

  return (
    <Card>
      <CardContent>
        <FormWrapper<FormValues>
          onChangeCallback={handleChange}
          onSubmitCallback={onSubmit}
          handleSubmit={handleSubmit}
        >
          <>
            <Grid container columnSpacing={4} spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <BasicTextInput
                  label="Address"
                  inputName="searchByAddress"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <BasicTextInput
                  label="Permit number"
                  inputName="searchByPermitNumber"
                  control={control}
                />
              </Grid>
            </Grid>
          </>
        </FormWrapper>
      </CardContent>
    </Card>
  );
}
