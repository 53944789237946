import dayjs, { Dayjs } from 'dayjs';
import { checkWeekendsAndHolidays, getUSHolidaysSafe } from 'ui/helpers/parseDate';

function changeDirection(direction: number): number {
  return direction * -1;
}

export function isTodayFree(now: dayjs.Dayjs = dayjs()): boolean {
  return checkWeekendsAndHolidays(now, getUSHolidaysSafe(now));
}

// ONLY work days should be counted. Weekends and bank holidays should be removed
export function countDate(slaDeadline: Dayjs, now: dayjs.Dayjs = dayjs()): string {
  let direction = -1;
  let deadline = dayjs(slaDeadline);
  const diff = deadline.diff(now, "seconds");
  if (diff < 0) {
    direction = changeDirection(direction);
  }

  let fullDays = Math.abs(deadline.diff(now, "days"));

  // CHECK today's day
  if (isTodayFree(now)) {
    const endOfToday = now.endOf("day");
    const todayDiff = endOfToday.diff(now, "seconds");
    deadline = deadline.add(todayDiff * direction, "seconds");
    fullDays = fullDays - 1;
  }

  // Check other full days
  const freeDays = countFreeDays(deadline, fullDays, direction, now);
  return deadline.add(freeDays * direction, "days").toISOString();
}

function countFreeDays(
  deadline: dayjs.Dayjs,
  daysCount: number,
  direction: number,
  now: dayjs.Dayjs = dayjs()
): number {
  let freeDaysCounter = 0;
  let loop = 1;
  let days = daysCount;
  const date = deadline;

  while (days > 0) {
    const datePlus = date.add(loop * direction, "day");
    if (checkWeekendsAndHolidays(datePlus, getUSHolidaysSafe(now))) {
      freeDaysCounter++;
    }
    days--;
    loop++;
  }

  return freeDaysCounter;
}
