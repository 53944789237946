// reviews.json?page=1&limit=4&applicationId={{application_id}}&expand=true&divisionName=intake

import { getDivisionOrderById } from 'app-sections/common/applications/details/components/sections/status-info/components/divisions-info/helpers';
import { orderBy } from 'lodash-es';
import { ApiCollectionResponse, Params, Review, ReviewSectionState, ReviewSectionStateId } from 'types';

import { getCollectionResponse, request } from '../config';

const REVIEWS_URL = "/reviews";
const GET_REVIEWS_URL = "/reviews.json";

export function getReviews<T>(applicationId: string): Promise<T> {
  return request(
    {
      url: GET_REVIEWS_URL,
      method: "GET",
      params: { applicationId, expand: true },
    },
    (data) =>
      orderBy(data.models.results, [
        (i) => getDivisionOrderById(i.divisionName),
      ])
  );
}

export function getReviewById(id: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}.json`,
      method: "GET",
      params: { expand: true },
    },
    (data) => data.model
  );
}

export function changeReviewer(
  reviewId: string,
  reviewerId: string
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}/change-reviewer.json`,
      method: "PATCH",
      data: { review: { reviewerId } },
    },
    (data) => data.model
  );
}

export function addReview(
  divisionName: string,
  applicationId: string
): Promise<Review> {
  return request(
    {
      url: GET_REVIEWS_URL,
      method: "POST",
      data: { review: { applicationId, divisionName } },
    },
    (data) => data.model
  );
}

export function addReviewDivision(
  divisionName: string,
  applicationId: string
): Promise<Review> {
  return request(
    {
      url: GET_REVIEWS_URL,
      method: "POST",
      data: { review: { applicationId, divisionName } },
    },
    (data) => data.model
  );
}

export function deleteReviewDivision(reviewId: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}`,
      method: "DELETE",
    },
    (data) => data.model
  );
}

export function updateReviewState(
  reviewId: string,
  sectionId: ReviewSectionStateId,
  state: ReviewSectionState
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}.json`,
      method: "PATCH",
      data: { review: { [sectionId]: state } },
    },
    (data) => data.model
  );
}

export function updateReview(
  id: string,
  data: Params,
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}.json`,
      method: "PATCH",
      data: { review: { ...data } },
    },
    (data) => data.model
  );
}


export function submitToReview(
  id: string,
  data: Params,
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}/submit-to-review.json`,
      method: "PATCH",
      data: { review: { ...data } },
    },
    (data) => data.model
  );
}

export function rejectReview(
  id: string,
  data: Params,
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}/reject.json`,
      method: "PATCH",
      data: { review: { ...data } },
    },
    (data) => data.model
  );
}

export function changeReview(
  id: string,
  data: Params,
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}/change-decision.json`,
      method: "PATCH",
      data: { review: { ...data } },
    },
    (data) => data.model
  );
}

export function approveReview(
  id: string,
  data: Params,
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}/approve.json`,
      method: "PATCH",
      data: { review: { ...data } },
    },
    (data) => data.model
  );
}

export function signReview(
  id: string,
  data: Params,
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}/sign.json`,
      method: "PATCH",
      data: { review: { ...data } },
    },
    (data) => data.model
  );
}

export function getReviewsForDashboard(
  params: Params
): Promise<ApiCollectionResponse<Review>> {
  return request(
    {
      url: GET_REVIEWS_URL,
      method: "GET",
      params,
    },
    (data) => {
      return getCollectionResponse<Review>(data);
    }
  );
}
