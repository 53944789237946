import { assetUrl } from 'helpers/assetUrl';

import { Box, Button, Container, Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';

import Footer from './components/Footer';
import RegisterButton from './RegisterButton';
import ButtonLoader from 'ui/components/button-loader/ButtonLoader';
import { ExternalButton } from 'ui/components/external-button/ExternalButton';

const bgUrl = assetUrl(`/images/bgs/wallcheck-bg.png`);

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    header: {
      background: `#1B305B center / cover no-repeat url(${bgUrl})`,
      display: "flex",
      alignItems: "center",
      minHeight: 630,
    },
    subtitle: {
      mb: 2,
      color: "#fff",
      fontSize: "2.4rem",
      fontWeight: "bold",
    },

    listHeader: {
      mt: 0,
      mb: 2,
      color: theme.palette.primary.main,
      fontSize: "2rem",
      fontWeight: "bold",
      display: "flex",

      "&:before": {
        display: "flex",
        flexWidth: "32px",
        width: 32,
        height: 32,
        background: theme.palette.primary.main,
        color: "#fff",
        borderRadius: 16,
        fontSize: "1.4rem",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "10px",
        content: "counter(section-counter)",
        flexShrink: 0,
      },
    },

    listContainer: {
      mt: 4,
      counterReset: "section-counter",
      p: 0,
      listStyle: "none",
      "& li": {
        counterIncrement: "section-counter",
      },
    },

    listContent: {
      marginLeft: "42px",
      fontSize: "2rem",
    },

    bottomContainer: {
      color: "#fff",
      background: theme.palette.primary.main,
      flex: 1,
      pb: 3 ,
    }
  };
};

export default function Login() {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <>
      <Box sx={styles.header}>
        <Container>
          <Typography
            variant="h1"
            component="h1"
            className="with-indicator-red"
            sx={{ color: "#fff" }}
          >
            Get a <br/>
            Wall Check
          </Typography>
          <Box sx={styles.subtitle}>
            with the Dept. of Buildings (DOB)
          </Box>
          <RegisterButton color="error" label="Log in with Access DC" />
        </Container>
      </Box>
      <Box sx={{ background: "#fff", flex: 1, pb: 3 }}>
        <Container sx={{ pt: 1, position: "relative" }}>
          <Typography
            variant="h1"
            component="h1"
            className="with-indicator-red centered"
            sx={{ mt: 4 }}
          >
            How it works
          </Typography>

          <Grid
            container
            columnSpacing={4}
            spacing={4}
            sx={styles.listContainer}
            component="ul"
          >
            <Grid component="li" item xs={12} sm={12} md={4} lg={4}>
              <Box component="h3" sx={styles.listHeader}>
                Submit an Authorization Request
              </Box>
              <Box sx={styles.listContent}>
                Sign in to the website with Access DC or hire an agent or an independent <a href='https://dob.dc.gov/sites/default/files/dc/sites/dob/publication/attachments/DC_Registered_Surveyors_List%209.12.23.pdf'> DC Registered Land Surveyor</a> to begin the application process by filling out the authorization form and paying the fee.
              </Box>
            </Grid>
            <Grid component="li" item xs={12} sm={12} md={4} lg={4}>
              <Box component="h3" sx={styles.listHeader}>
                Submit Supporting Documents
              </Box>
              <Box sx={styles.listContent}>
                Your independent DC Registered Land Surveyor, who is qualified to perform the wall check, must submit supporting documentation to initiate the DOB application review.
              </Box>
            </Grid>
            <Grid component="li" item xs={12} sm={12} md={4} lg={4}>
              <Box component="h3" sx={styles.listHeader}>
                Track the status
              </Box>
              <Box sx={styles.listContent}>
                Track your Wall Check process from start to finish with real-time notifications and review updates. Upon completion of DOB review, the approved wall check will be available for download.
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={styles.bottomContainer}>
        <Container sx={{ pt: 1, position: "relative", textAlign: "center" }}>
          <Typography
            variant="h1"
            component="h1"
            className="with-indicator-red centered"
            sx={{ mt: 4, color: "#fff" }}
          >
            View status of the applications
          </Typography>

          <Box sx={styles.listContent} my={4}>
            Once DOB has been selected all permit based inspections must be performed by the Department.
          </Box>

          <Button
            color={"error"}
            variant={"contained"}
            disableElevation
            component={ExternalButton}
            onClick={() => {
              window.location.href = "/app/public";
            }}
          >
            Check applications
          </Button>
        </Container>
      </Box>

      <Footer />
    </>
  );
}
