import { SvgIcon, SvgIconProps } from '@mui/material';

export default function PencilIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4933 1.51182C17.9213 0.942582 17.1455 0.625452 16.3385 0.630929C15.5315 0.636406 14.7601 0.964036 14.1958 1.54099L2.14167 13.5918L0.625 19.3752L6.41 17.8593L18.4642 5.80849C19.0415 5.24457 19.3694 4.47325 19.3749 3.66624C19.3803 2.85923 19.0629 2.08352 18.4933 1.51182V1.51182Z"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8516 1.88672L18.1199 6.15339"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1094 3.62695L16.3777 7.89362"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.14062 13.5918L5.13229 14.8685L6.41229 17.8551"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2436 5.75977L5.13281 14.8681"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
