import { Box, useTheme } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { countDate, isTodayFree } from './helpers';
import Overdue from './overdue/Overdue';
import Timer from './timer/Timer';

export interface RemainingTimeCounterProps {
  date: Dayjs;
  onExpiration: () => void;
  isPaused?: boolean;
}

export default function RemainingTimeCounter(props: RemainingTimeCounterProps) {
  const { date, onExpiration, isPaused, } = props;

  const [isExpired, setIsExpired] = useState<boolean>(false);

  const handleExpiration = () => {
    setIsExpired(true);
    onExpiration();
  };

  const theme = useTheme();

  return (
    <>
      {!isExpired && (
        <Box sx={{color: theme.palette.success.main}}>
          <Timer
            date={countDate(date)}
            onExpiration={handleExpiration}
            isPaused={isPaused || isTodayFree()}
          />
        </Box>
      )}
      {isExpired && <Overdue date={countDate(date)} isPaused={isTodayFree()} />}
    </>
  );
}
