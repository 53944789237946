import { LogoConfig } from './ToolbarLogo';

export const DEFAULT_LOGO_CONF: LogoConfig = {
  fileName: "wallcheck.svg",
  width: 280,
  label: "Wallcheck",
};

// const EXTENDED_LOGO_CONF: LogoConfig = {
//   fileName: "logo-inspections.svg",
//   width: 325,
//   label: "Tertius DOB Constructions Inspections",
// };

export const getLogoConfig = (): LogoConfig => {
  return DEFAULT_LOGO_CONF;
};
