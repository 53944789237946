import { useEffect, useState } from 'react';
import CloseIcon from 'ui/icons/CloseIcon';

import { Box, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export type ConfirmationDialogSubmitButtonClass = 'success' | 'fail' | 'primary' | 'secondary';

export interface ConfirmationDialogProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  opened: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmButtonVariant?: 'outlined' | 'contained';
  confirmButtonClass?: ConfirmationDialogSubmitButtonClass;
  confirmButtonText?: string;
  isWithClosingIcon?: boolean;
  confirmButtonDisabled?: boolean;
  centeredButtons?: boolean;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    title,
    description,
    opened,
    onConfirm,
    onCancel,
    confirmButtonVariant = 'outlined',
    confirmButtonClass = '',
    confirmButtonText = 'Confirm',
    isWithClosingIcon = false,
    confirmButtonDisabled = false,
    centeredButtons = false,
  } = props;
  const [open, setOpen] = useState(opened);

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {typeof title === 'string' && (
          <Box color="primary.dark">
            <Typography component="h4" variant="h5">
              {title}
            </Typography>
          </Box>
        )}
        {typeof title !== 'string' && title}
        {isWithClosingIcon && (
          <IconButton
            aria-label="close"
            onClick={() => handleCancel()}
            style={{ position: 'absolute', top: 8, right: 15, color: ' ' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {typeof description === 'string' && (
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        )}
        {typeof description !== 'string' && description}
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{
          display: 'flex',
          ...(centeredButtons && { justifyContent: 'center' }),
        }}
      >
        {onCancel && (
          <Box mr={2}>
            <Button variant="outlined" onClick={handleCancel} autoFocus>
              Cancel
            </Button>
          </Box>
        )}
        <Button
          color={confirmButtonClass ? 'primary' : 'secondary'}
          variant={confirmButtonVariant}
          onClick={handleConfirm}
          className={confirmButtonClass}
          disableElevation
          disabled={confirmButtonDisabled}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
