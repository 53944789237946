import { SvgIcon, SvgIconProps } from '@mui/material';

export default function AlertTriangleCustom(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g fill="none">
        <path
          d="M12 18.75C11.7929 18.75 11.625 18.9179 11.625 19.125C11.625 19.3321 11.7929 19.5 12 19.5C12.2071 19.5 12.375 19.3321 12.375 19.125C12.375 18.9179 12.2071 18.75 12 18.75V18.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12 15.75V8.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.621 1.75976C13.3174 1.14165 12.6887 0.75 12 0.75C11.3114 0.75 10.6826 1.14165 10.379 1.75976L0.906011 21.0578C0.674185 21.5294 0.702213 22.0872 0.980139 22.5332C1.25807 22.9792 1.74652 23.2501 2.27201 23.2498H21.728C22.2535 23.2501 22.742 22.9792 23.0199 22.5332C23.2978 22.0872 23.3258 21.5294 23.094 21.0578L13.621 1.75976Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
