import { updateOneCacheItem } from 'core/cache/helpers';
import dayjs, { Dayjs } from 'dayjs';
import { Application, ApplicationState, Params } from 'types';

import { QueryClient } from '@tanstack/react-query';

import type { Location } from '@remix-run/router';
export const parseSearchParams = (searchParams: URLSearchParams): Params => {
  const output: Params = {};
  searchParams.forEach((v, k) => {
    output[k] = k === 'states' ? searchParams.getAll(k) : v;
  });

  return output;
};

export const parseFormValuesToParams = (values: Params): Params => {
  const output: Params = {};
  Object.keys(values).forEach((key: string) => {
    if (values[key] === null) {
      output[key] = '';
    } else {
      output[key] = values[key];
    }
  });

  return output;
};

export function updateApplicationStateInCache(
  queryClient: QueryClient,
  application: Application,
  newState: ApplicationState
) {
  const newAppData = { ...application, state: newState } as Application;
  application && updateOneCacheItem<Application>(queryClient, ['applications', application.id], newAppData);
  return true;
}

export function applicationPublicView(location: Location): boolean {
  return location.pathname.includes('/public/applications');
}

export function applicationIsInZoningOrClientActionNeeded(application: Application): boolean {
  return (
    application.state === 'zoning_review' ||
    (application.state === 'client_action_needed' &&
      application.statuses.map((x) => x.name).includes('app.statuses.applications.zoning_review'))
  );
}

export function isNowBeforeSlaStartedAt(application: Application) {
  const slaStartedAt = dayjs(application.submittedAt).add(1, 'd').hour(9).minute(0);
  return dayjs().isBefore(slaStartedAt);
}

export const slaDeadline = (data: Application): Dayjs => {
  let slaDiff;

  const slaStartedAt = dayjs(data.submittedAt).add(1, 'd').hour(9).minute(0);
  if (dayjs(data.slaStoppedAt).isBefore(slaStartedAt, 'day') || dayjs().isBefore(slaStartedAt)) {
    slaDiff = dayjs(data.slaDeadlineAt).diff(slaStartedAt, 'seconds');
  } else if (data.slaStoppedAt) {
    slaDiff = dayjs(data.slaDeadlineAt).diff(data.slaStoppedAt, 'seconds');
  } else {
    slaDiff = dayjs(data.slaDeadlineAt).diff(dayjs(), 'seconds');
  }
  return dayjs().add(slaDiff, 'seconds');
};
