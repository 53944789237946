import { useState } from 'react';

import { Box, IconButton, SxProps } from '@mui/material';

import CloseIcon from '../../icons/CloseIcon';

export interface ClosablePanelProps {
  children: any;
  onClose?: () => void;
}
const getStyles = (): { [key: string]: SxProps } => {
  return {
    container: {
      position: "relative",
    },
    buttonContainer: {
      position: "absolute",
      right: 20,
      top: 20,
    },
  };
};

export default function ClosablePanel(props: ClosablePanelProps) {
  const { children, onClose } = props;
  const [closed, setClosed] = useState<boolean>(false);

  const styles = getStyles();

  const handleClick = () => {
    setClosed(true);
    if (onClose) {
      onClose();
    }
  };

  if (closed) {
    return null;
  }

  return (
    <Box sx={styles.container}>
      {children}
      <IconButton
        sx={styles.buttonContainer}
        aria-label="close"
        size="small"
        onClick={handleClick}
      >
        <CloseIcon fontSize="inherit" style={{ color: "#fff" }} />
      </IconButton>
    </Box>
  );
}
