import { useEffect, useRef, useState } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';

export const DEFAULT_FORM_TIMEOUT = 350;

export interface FormWithChangeProps<T> {
  children: JSX.Element;
  handleSubmit: UseFormHandleSubmit<any>;
  onChangeCallback: () => void;
  onSubmitCallback: (data: T) => void;
}

/**
 * A wrapper for a form that reacts on change (as a native event) an onChange (as react event)
 * Provided because od MUI Select does not trigger onChange event and reacting with useEffect/useDeepCompareEffect is too complicated and may cause endless loops or with deep-nested forms just doesn't work
 */

const getTimestamp = (): number => {
  return Math.round(new Date().getTime() / 50);
};

let timeout: number;

export default function FormWrapper<T>(props: FormWithChangeProps<T>) {
  const { children, onChangeCallback, handleSubmit, onSubmitCallback } = props;

  const myRef = useRef<HTMLFormElement>(null);
  const [nativeChangeEvent, setNativeChangeEvent] = useState<number>(0);
  const [isListenerCreated, setListenerCreated] = useState<boolean>(false);

  const onSubmit = (data: T) => {
    clearTimeout(timeout);
    onSubmitCallback(data);
  };

  useEffect(() => {
    // Prevent change on component init

    if (nativeChangeEvent > 0) {
      clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        //  set debounce on changes
        onChangeCallback();
      }, DEFAULT_FORM_TIMEOUT);
    }
  }, [nativeChangeEvent]);

  // Create form onchange native listener, because React onChange does not fetch every event
  useEffect(() => {
    const current = myRef.current;
    if (!isListenerCreated) {
      current?.addEventListener("change", () => {
        setNativeChangeEvent(getTimestamp());
      });
      setListenerCreated(true);
    }
    return () => {
      current?.removeEventListener("change", () => {
        setNativeChangeEvent(getTimestamp());
      });
    };
  }, []);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      ref={myRef}
      onChange={() => {
        setNativeChangeEvent(getTimestamp());
      }}
    >
      <>{children}</>
    </form>
  );
}
