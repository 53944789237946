import { Controller } from 'react-hook-form';
import { cleanMaskedValues } from 'ui/helpers/maskedValues';

import { FormControl, FormHelperText, Input, InputAdornment, InputLabel } from '@mui/material';

import { TextMaskCustom } from '../text-mask/TextMaskt';

export type MaskedTextInputType = "text" | "email" | "number";

export interface MaskedTextInputProps {
  label: string;
  helperText?: string;
  control: any;
  inputName: string;
  placeholder?: string;
  type?: MaskedTextInputType;
  required?: boolean;
  disabled?: boolean;
  onChange?: any;
  errorsInputName?: string;
  adornment?: { position: "start" | "end"; label: string };

  inputProps?: any;
  cleanupFn?: (value: string) => string;
}

export default function MaskedTextInput(props: MaskedTextInputProps) {
  const {
    control,
    label,
    inputName,
    helperText,
    placeholder,
    type = "text",
    required,
    disabled = false,
    onChange,
    adornment,

    cleanupFn,
    // mask definition here
    inputProps,
  } = props;

  return (
    <Controller
      name={inputName}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error}>
          <InputLabel
            required={required ? true : false}
            shrink
            htmlFor={inputName}
          >
            {label} {}
          </InputLabel>
          <Input
            {...field}
            type={type}
            error={!!fieldState.error}
            placeholder={placeholder}
            disabled={disabled}
            inputComponent={TextMaskCustom}
            inputProps={inputProps}
            onChange={(e) => {
              field.onChange({
                ...e,
                target: {
                  ...e.target,
                  value: cleanupFn
                    ? cleanupFn(e.target.value)
                    : cleanMaskedValues(e.target.value),
                },
              });
              onChange && onChange();
            }}
            endAdornment={
              adornment &&
              adornment.position === "end" && (
                <InputAdornment position="end">
                  {adornment.label}
                </InputAdornment>
              )
            }
            startAdornment={
              adornment &&
              adornment.position === "start" && (
                <InputAdornment position="start">
                  {adornment.label}
                </InputAdornment>
              )
            }
          />
          {(!!fieldState.error || helperText) && (
            <FormHelperText>
              {fieldState.error?.message || helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
