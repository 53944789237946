import { APP_VER } from 'core/config';
import { useAuthStore } from 'core/store/auth';
import { isAdmin } from 'ui/helpers/users';

import { AppBar, Box, Icon, SxProps, Theme, useTheme } from '@mui/material';

import { getNavigationConfig } from '../navigation/config';
import Navigation from '../navigation/Navigation';
import AppNavbarToggleButton from './app-navbar-toggle-button/AppNavbarToggleButton';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      background: theme.palette.background.paper,
    },
    bar: {
      position: "relative",
      display: "flex",
      justifyContent: {xs: "flex-start", md: "center"},
      alignItems: "center",
      height: theme.spacing(8),
      background: theme.palette.secondary.main,
      borderRadius: `0 0 0 ${theme.shape.borderRadius}px`,
      "&.admin": {
        background: "#535863",
      },
    },
    backButtonContainer: {
      position: "absolute",
      left: 0,
      top: 0,
      color: theme.palette.background.paper,
    },
    navigationContainer: {
      display: "flex",
      flexGrow: 1,
      width: "100%",
      overflow: "auto",
    },
    userNameHeader: {
      textTransform: "capitalize",
      zIndex: 2,
      textAlign: "left",
    },
    appVer: {
      position: "absolute",
      fontSize: "1rem",
      opacity: "0.7",
      top: "64px",
      right: 0,
      width: "28px",
      textAlign: "right",
    },
  };
};

export default function AppNavbar() {
  const theme = useTheme();
  const styles = getStyles(theme);

  const { currentUser } = useAuthStore();

  let isUserAdmin = false;
  if (currentUser) {
    isUserAdmin = isAdmin(currentUser.userProfile.roles);
  }

  return (
    <Box sx={styles.root}>
      <AppBar elevation={0} color="transparent" position="static">
        <Box sx={styles.bar} className={isUserAdmin ? "admin" : ""}>
          <Box
            sx={{
              ...styles.backButtonContainer,
              display: { xs: "block", md: "none", position: "relative" },
            }}
          >
            <AppNavbarToggleButton>
              <Icon>arrow_back</Icon>
            </AppNavbarToggleButton>
          </Box>

          <Box
            component="h5"
            fontSize="1.8rem"
            color="#fff"
            m={0}
            p={0}
            sx={styles.userNameHeader}
          >
            {currentUser && <>{currentUser.userProfile.fullName}</>}
          </Box>
        </Box>
      </AppBar>

      <Box sx={styles.appVer}>v{APP_VER}</Box>

      <Box sx={styles.navigationContainer}>
        {currentUser && (
          <Navigation
            navigation={getNavigationConfig(
              currentUser.userProfile.roles,
              currentUser.userProfile.id
            )}
          />
        )}
      </Box>
    </Box>
  );
}
