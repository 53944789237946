import { Params } from 'types';

export type ApplicantValidationVariantsId =
  | "propertyOwner"
  | "agent"
  | "contractor"
  | "dcRegisteredSurveyor"
  | "other";

export interface ApplicantValidationVariant {
  name: string;
  id: ApplicantValidationVariantsId;
}

export const APPLICANT_VALIDATION_VARIANTS: ApplicantValidationVariant[] = [
  { name: "Property Owner", id: "propertyOwner" },
  { name: "Agent", id: "agent" },
  { name: "Contractor", id: "contractor" },
  { name: "DC Registered Surveyor", id: "dcRegisteredSurveyor" },
  { name: "Other", id: "other" },
];
export const APPLICANT_VALIDATION_VARIANTS_MAP = APPLICANT_VALIDATION_VARIANTS.reduce((obj, item) => ({...obj, [item.id]: item.name}), {} as {[key: string]: string});

export const getApplicantById = (
  id: ApplicantValidationVariantsId
): ApplicantValidationVariant | undefined => {
  return APPLICANT_VALIDATION_VARIANTS.find((variant) => variant.id === id);
};

export const KEY = "step/2";

export const INITIAL_VARIANTS: {
  [key in ApplicantValidationVariantsId]: Params;
} = {
  propertyOwner: {},
  agent: {},
  contractor: {},
  dcRegisteredSurveyor: {},
  other: {}
};

export const getVariant = (
  data: Params
): ApplicantValidationVariantsId | "" => {
  const result = Object.keys(INITIAL_VARIANTS).find((key: string) => {
    if (data[key]) {
      return true;
    }
    return false;
  });

  return (result as ApplicantValidationVariantsId) || "";
};

export const setVariantInitialData = (
  data: Params
): {
  [key in ApplicantValidationVariantsId]: Params;
} => {
  let variants = INITIAL_VARIANTS;
  if (data.whoApplying) {
    variants[data.whoApplying as ApplicantValidationVariantsId] = data;
  }

  return variants;
};


export interface DataPart {
  data: Params;
  isValid: boolean;
}

export interface DataParts {
  applicantValidation: DataPart;
  surveyor: DataPart;
  projectInformation: DataPart;
  stakeholders: DataPart;
  notes: DataPart;
}
