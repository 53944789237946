import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4C16.536 4 4 16.536 4 32C4 47.464 16.536 60 32 60Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32 32V22"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32 32L44.4987 44.5013"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
