import ApplicationDetailsLayout from "core/layouts/components/application-details-layout/ApplicationDetailsLayout";
import ApplicationStepsLayout from "core/layouts/components/application-steps-layout/ApplicationStepsLayout";
import { Navigate, RouteObject } from "react-router-dom";

import ApplicationsResolver from "../protected-routes/applications-resolver/ApplicationsResolver";
import { applicationDetailsRoutes } from "./applicationDetailsRoutes";
import { applicationStepsRoutes } from "./applicationStepsRoutes";

export const applicationRoutes: RouteObject[] = [
  {
    path: "",
    element: <Navigate replace to="/404" />,
  },
  {
    path: "step",
    element: (
      <ApplicationsResolver>
        <ApplicationStepsLayout />
      </ApplicationsResolver>
    ),
    children: applicationStepsRoutes,
  },
  {
    path: "details",
    element: (
      <ApplicationsResolver>
        <ApplicationDetailsLayout />
      </ApplicationsResolver>
    ),
    children: applicationDetailsRoutes,
  },
];
