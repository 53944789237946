import { assetUrl } from 'helpers/assetUrl';
import BookingsIcon from 'ui/icons/BookingsIcon';
import DashboardIcon from 'ui/icons/DashboardIcon';
import FileTypePdfIcon from 'ui/icons/FileTypePdfIcon';
import ProfileIcon from 'ui/icons/ProfileIcon';

import { INavigationElement } from '../NavigationElement';

const CLIENT_GUIDE_URL = "https://docskit-dob-wallcheck.prod01.oregon.platform-os.com/";

export const getUserNavigation = (): INavigationElement[] => [
  {
    id: "dashboard",
    name: "Dashboard",
    url: "/",
    path: "/",
    icon: <DashboardIcon />,
    disabled: false,
    show: true,
  },
  {
    id: "client-projects",
    name: "Applications",
    url: "/applications",
    path: "/applications",
    icon: <BookingsIcon />,
    disabled: false,
    show: true,
  },
  {
    id: "client-profile",
    name: "Profile & Settings",
    url: "/profile",
    path: "/profile",
    icon: <ProfileIcon />,
    disabled: false,
    show: true,
  },
  {
    id: "guide",
    name: `User Guide`,
    url: CLIENT_GUIDE_URL,
    path: "/guide",
    icon: <FileTypePdfIcon />,
    disabled: false,
    show: true,
    isExternal: true,
  },
  // {
  //   id: "settings",
  //   name: "Settings",
  //   url: "/settings",
  //   path: "/settings",
  //   icon: <SettingsIcon />,
  //   disabled: false,
  //   show: true,
  //   settings: true,
  // },
];
