import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';

export interface OverdueProps {
  date: string;
  isPaused?: boolean;
}

const getItemTime = (createdAt: string): number => {
  const startTime = dayjs(createdAt);
  const duration = dayjs().diff(startTime, "seconds");
  return duration;
};

const formatLeadingZero = (value: number): string => {
  return ("0" + String(value)).slice(-2);
};

export default function Overdue(props: OverdueProps) {
  const { date, isPaused = false } = props;

  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + getItemTime(date));

  const { hours, days, minutes, pause } = useStopwatch({
    offsetTimestamp: stopwatchOffset,
    autoStart: true,
  });

  useEffect(() => {
    if (isPaused) {
      pause();
    }
  }, [isPaused]);

  return (
    <>
    <span>- </span>
    <span>
      {formatLeadingZero(days)}&nbsp;day(s) {""}
      {formatLeadingZero(hours)}&nbsp;hour(s) {""}
      {formatLeadingZero(minutes)}&nbsp;min(s)
    </span>
    </>
  );
}
