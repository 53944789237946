import { Link } from 'react-router-dom';
import { Application } from 'types';
import ViewIcon from 'ui/icons/ViewIcon';
import { Box, IconButton } from '@mui/material';
import { getPublicLinkToApplication } from 'app-sections/user/applications/steps/config';

export interface ApplicationDataOptionsProps {
  item: Application;
}

export default function ApplicationDataOptions(
  props: ApplicationDataOptionsProps
) {
  const { item, } = props;
  return (
    <>
      <Box mx={-1} display="flex" justifyContent="flex-end">
        <Box mr={0}>
          <IconButton
            aria-label="Go to Application"
            className="outlined-dark filled"
            component={Link}
            to={getPublicLinkToApplication(item)}
          >
            <ViewIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
