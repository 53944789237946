import { manualSwoCheck } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import { useEffect, useState } from 'react';
import ButtonLoader from 'ui/components/button-loader/ButtonLoader';
import CommonSuccessNotification from 'ui/components/common-success-notification/CommonSuccessNotification';
import { isAdmin } from 'ui/helpers/users';

import { Box, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

export default function CheckSwoButton() {
  const { activeApplicationId = '' } = useApplicationsStore();
  const { currentUser } = useAuthStore();

  const { isFetching, data, refetch, isSuccess } = useQuery({
    queryKey: ['application/check-swo', activeApplicationId],
    queryFn: () => manualSwoCheck(activeApplicationId),
    enabled: false,
  });

  const checkSwo = () => {
    refetch();
  };

  const [showSwo, setShowSwo] = useState<number>(0);
  const [showNoSwo, setShowNoSwo] = useState<number>(0);

  useEffect(() => {
    if (isSuccess && data?.isChecked) {
      if (data?.hasSwo) {
        setShowSwo(data.isChecked);
      } else {
        setShowNoSwo(data.isChecked);
      }
    }
  }, [isSuccess, data?.hasSwo, data?.isChecked]);

  if (!currentUser || !isAdmin(currentUser.userProfile.roles) || !activeApplicationId) {
    return null;
  }

  return (
    <>
      <CommonSuccessNotification
        isOpened={showNoSwo}
        mainText="There's no SWO restriction on the address/parcel and the associated permits."
        severity="success"
      />
      <CommonSuccessNotification
        isOpened={showSwo}
        mainText="There's an SWO restriction on the address/parcel and the associated permits."
        severity="error"
      />
      <Box sx={{ position: 'relative', marginLeft: '8px' }} mb={1}>
        <Button
          variant="outlined"
          size="small"
          sx={{ fontSize: '1.1rem', minWidth: '65px' }}
          onClick={checkSwo}
          disabled={isFetching || data?.isLoading}
        >
          <ButtonLoader isActive={isFetching || data?.isLoading} size={16} />
          Check SWO
        </Button>
      </Box>
    </>
  );
}
