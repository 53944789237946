import { difference } from 'lodash-es';
import { Application, UserData, UserRole } from 'types';

export const isAdmin = (roles: UserRole[]): boolean => roles.includes('admin');

export const isDcRegisteredSurveyor = (roles: UserRole[]): boolean =>
  roles.includes('dc_registered_surveyor');

export const isDcRegisteredSurveyorDeactivated = (roles: UserRole[]): boolean =>
  roles.includes('dc_registered_surveyor_deactivated');

export const isManager = (roles: UserRole[]): boolean =>
  roles.includes('surveyor_office_manager') || roles.includes('zoning_manager');

export const isManagerOrSuperAdmin = (roles: UserRole[]): boolean =>
  isManager(roles) || (isAdmin(roles) && !isTechnician(roles));

export const isTechnician = (roles: UserRole[]): boolean =>
  roles.includes('surveyor_office_technician') ||
  roles.includes('zoning_technician');

export const isFromZoning = (roles: UserRole[]): boolean =>
  roles.includes('zoning_manager') || roles.includes('zoning_technician');

export const isFromSurveyorOffice = (roles: UserRole[]): boolean =>
  roles.includes('surveyor_office_manager') ||
  roles.includes('surveyor_office_technician');

export const isSurveyorOfficeManager = (roles: UserRole[]): boolean =>
  roles.includes('surveyor_office_manager');

export const isClient = (roles: UserRole[]): boolean =>
  roles.includes('client');

export function mainRole(roles: UserRole[]): UserRole {
  if (roles.length === 1) {
    return roles[0];
  } else {
    const list = difference(roles, ['admin', 'client']);
    if (list.length > 0) {
      return list[0] as UserRole;
    } else {
      return 'client';
    }
  }
}

export function isAReviewerForApplication(
  user: UserData | null,
  application: Application
): boolean {
  if (user === null) return false;
  return [
    application.surveyorManagerId,
    application.surveyorTechnicianId,
    application.zoningManagerId,
    application.zoningTechnicianId,
  ].includes(user.id);
}

export function isZoningReviewerForApplication(
  user: UserData | null,
  application: Application
): boolean {
  if (user === null) return false;
  return [application.zoningManagerId, application.zoningTechnicianId].includes(
    user.id
  );
}

export function isSurveyorReviewerForApplication(
  user: UserData | null,
  application: Application
): boolean {
  if (user === null) return false;
  return [
    application.surveyorManagerId,
    application.surveyorTechnicianId,
  ].includes(user.id);
}

export function isApplicantForApplication(
  user: UserData | null,
  application: Application
): boolean {
  if (user === null) return false;
  return application.clientId === user.id;
}
