import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import { ExternalButton } from 'ui/components/external-button/ExternalButton';

import { Box, Button } from '@mui/material';

export default function DebugLink() {
  const { activeApplicationId } = useApplicationsStore();
  const { currentUser } = useAuthStore();

  if (window.location.host.includes('wallcheck.dob.dc.gov')) {
    return null;
  }

  if (!currentUser || !activeApplicationId) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative', marginLeft: '8px' }} mb={1}>
      <Button
        variant="outlined"
        size="small"
        sx={{ fontSize: '1.1rem', minWidth: '65px' }}
        component={ExternalButton}
        to={`/../api/applications/${activeApplicationId}/debug`}
      >
        Debug
      </Button>
    </Box>
  );
}
