import { DivisionId, Params, Review } from 'types';
import { UI_DATE_TIME_FORMAT_STRING, parseDate } from 'ui/helpers/parseDate';

import { Box } from '@mui/material';
import { get } from 'lodash-es';

export const DIVISIONS: { name: string; id: DivisionId; order: number }[] = [
  {
    name: "Surveyor's Office (DOB)",
    id: "surveyorsOffice",
    order: 0,
  },
  {
    name: "Zoning (DOB)",
    id: "zoning",
    order: 1,
  },
];

const TAB_STATES_FIELDS = [
  'applicantValidationState',
  'existingCOOState',
  'propertySizeState',
  'permitsState',
  'inspectionsState',
];

export const getDivisionNameById = (id: string): string => {
  return DIVISIONS.find((item) => item.id === id)?.name || "";
};

export const getDivisionOrderById = (id: string): number => {
  return DIVISIONS.find((item) => item.id === id)?.order || 0;
};

export const getReviewLabel = (review: Review): string => {
  if (review.state === "approved") {
    return `Approved on ${parseDate(
      review.completedAt,
      UI_DATE_TIME_FORMAT_STRING
    )}`;
  }

  if (review.state === "client_action_needed" && review.divisionName === 'cfo_issuance') {
    return "Waiting on client approval";
  }

  if (review.state === "client_action_needed") {
    return "Hold for correction";
  }

  const inProgress = TAB_STATES_FIELDS.filter((tab: string) =>
    get(review, tab) && get(review, tab) !== "pending"
  );

  if (inProgress.length > 0) {
    return "In progress";
  }

  return "Review not started";
};

export const getReviewStatus = (review: Review): JSX.Element => {
  const label = getReviewLabel(review);
  if (review.state === "approved") {
    return (
      <Box
        fontSize="2rem"
        fontWeight="bold"
        color="#166D12"
        textTransform="uppercase"
      >
        {label}
      </Box>
    );
  }

  if (review.state === "client_action_needed") {
    return (
      <Box
        fontSize="2rem"
        fontWeight="bold"
        color="#b05e11"
        textTransform="uppercase"
      >
        {label}
      </Box>
    );
  }

  return (
    <Box fontSize="2rem" fontWeight="bold" textTransform="uppercase">
      {label}
    </Box>
  );
};

export const prepareReviewsData = (reviews: Review[]): Params[] => {
  return reviews.map((review) => {
    return {
      title: getDivisionNameById(review.divisionName),
      assignee: review?.reviewerProfile?.fullName || "Not assigned",
      statusLabel: getReviewLabel(review),
      state: review.state,
    };
  });
};
