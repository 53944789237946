import { Params } from 'react-router-dom';
import { ApiCollectionResponse, UserProfile, UserRole } from 'types';

import { getCollectionResponse, request } from '../config';

export function getUserProfiles(
  role: UserRole | UserRole[],
  withDivisions?: boolean,
): Promise<UserProfile[]> {
  return request(
    {
      url: "user-profiles.json",
      method: "GET",
      params: {
        limit: 100,
        roles: role,
        isActive: true,
        withDivisions,
      },
    },
    (data) => data.models.results
  );
}

export function searchAdminProfiles(
  data: Params,
  signal?: AbortSignal
): Promise<ApiCollectionResponse<UserProfile>> {
  return request(
    {
      url: "user-profiles.json",
      method: "GET",
      params: { ...data, expand: true, roles: ["admin", "surveyor_office_technician", "surveyor_office_manager", "zoning_technician", "zoning_manager"] },
      signal,
    },
    (data) => {
      return getCollectionResponse<UserProfile>(data);
    }
  );
}

export function searchRegisteredSurveyorProfiles(
  data: Params
): Promise<ApiCollectionResponse<UserProfile>> {
  return request(
    {
      url: "user-profiles/dc-registered.json",
      method: "GET",
      params: { ...data, expand: true },
    },
    (data) => {
      return getCollectionResponse<UserProfile>(data);
    }
  );
}


export function addAdmin(
  email: string,
  userProfile: Partial<UserProfile>
): Promise<UserProfile> {
  return request(
    {
      url: "users.json",
      method: "POST",
      data: { user: { email, userProfile } },
    },
    (data) => data.model
  );
}

export function getUserProfile(id: string): Promise<UserProfile> {
  return request(
    {
      url: `user_profiles/${id}.json`,
      method: "GET",
    },
    (data) => data.model
  );
}

export function editUserProfile(
  id: string,
  userProfile: Partial<UserProfile>
): Promise<UserProfile> {
  return request(
    {
      url: `user-profiles/${id}.json`,
      method: "PATCH",
      data: { userProfile },
    },
    (data) => data.model
  );
}

export function setUserStatus(
  id: string,
  isActive: boolean
): Promise<UserProfile> {
  return request(
    {
      url: `user-profiles/${id}/toggle-active-status.json`,
      method: "PATCH",
      data: {
        userProfile: {
          isActive,
        },
      },
    },
    (data) => data.model
  );
}
export function userDelete(): Promise<UserProfile> {
  return request(
    {
      url: `users.json`,
      method: "DELETE",
    },
    (data) => data.model
  );
}

export function replaceUserRole(
  id: string,
  oldRole: UserRole,
  newRole: UserRole,
): Promise<UserProfile> {
  return request(
    {
      url: `user-profiles/${id}/replace-role.json`,
      method: "PATCH",
      data: {
        userProfile: {
          oldRole: oldRole,
          newRole: newRole,
        },
      },
    },
    (data) => data.model
  );
}
