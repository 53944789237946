import './sidebar-layout.css';

import UserInactiveInfo from 'app-sections/common/users/user-inactive-info/UserInactiveInfo';
import UpdatesListenerWrapper from 'app-sections/common/websockets/updates-listener-wrapper/UpdatesListenerWrapper';
import { Outlet } from 'react-router-dom';
import ScrollController from 'ui/components/scroll-controller/ScrollController';

import LayoutErrorBoundary from '../../../errors/LayoutErrorBoundary';
import AppSidebar from '../app-sidebar/AppSidebar';
import AppToolbar from '../app-toolbar/AppToolbar';

export default function SidebarLayout() {
  return (
    <div className="root">
      <UserInactiveInfo />
      <AppSidebar />
      <div className="layout-wrapper">
        <AppToolbar />
        <main className="content" id="main-scroll-container">
          <LayoutErrorBoundary>
            <ScrollController />
            <UpdatesListenerWrapper />
            <Outlet />
            {/* <CustomRoutesProvider mobileAnimationType="opacity" /> */}
          </LayoutErrorBoundary>
        </main>
      </div>
    </div>
  );
}
