import { updateAdditionalAddresses } from 'core/api/applications';
import { updateOneCacheItem } from 'core/cache/helpers';
import { useState } from 'react';
import { AdditionalAddressItem, Application, NewAdditionalAddressItem } from 'types';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import AddRoundIcon from 'ui/icons/AddRoundIcon';
import { v4 as uuidv4 } from 'uuid';

import { Box, Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import ItemDialog from '../item-dialog/ItemDialog';
import ItemForm from '../item-form/ItemForm';

export interface AddItemProps {
  parentId: string;
  items: AdditionalAddressItem[];
}

export default function AddItem(props: AddItemProps) {
  const { parentId, items } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const [resetTrigger, setResetTrigger] = useState<number>(0);
  const [submitTrigger, setSubmitTrigger] = useState<number>(0);

  const queryClient = useQueryClient();
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (data: AdditionalAddressItem[]) => updateAdditionalAddresses(parentId, data),
    onSuccess: (newItems: AdditionalAddressItem[]) => {
      updateOneCacheItem<Application>(queryClient, ['applications', parentId], { additionalAddresses: newItems });
      setIsOpened(false);
      setSubmitTrigger(0);
    },
  });

  const handleCancel = () => {
    setSubmitTrigger(0);
    setIsOpened(false);
    setResetTrigger((v) => v + 1);
  };

  return (
    <>
      <Box ml={-1}>
        <Button
          onClick={() => {
            setIsOpened((v) => !v);
          }}
        >
          <AddRoundIcon />
          <Box ml={1.2}>Add new address</Box>
        </Button>
      </Box>
      <ItemDialog
        title="Add new Secondary Address"
        open={isOpened}
        onCancel={handleCancel}
        onSubmit={() => setSubmitTrigger((v) => v + 1)}
        submitText="Add"
        isLoading={isPending}
      >
        <ItemForm
          onFormSubmit={(data: NewAdditionalAddressItem) => {
            mutate([{ ...data, id: uuidv4(), createdAt: new Date().toISOString() }, ...items]);
          }}
          resetTrigger={resetTrigger}
          submitTrigger={submitTrigger}
        />

        {isError && (
          <Box mt={2}>
            <ErrorsList title="Error adding address" errors={error} />
          </Box>
        )}
      </ItemDialog>
    </>
  );
}
