import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

import { ButtonBase, SxProps, Theme, useTheme } from '@mui/material';
import { Box } from '@mui/system';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "20px",
      textAlign: "left",
      width: "100%",
      fontSize: "1.6rem",
      lineHeight: 1,
      height: theme.spacing(6),
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      color: theme.palette.text.primary,
      textDecoration: "none",
      "& .icon-container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: theme.spacing(2),
        color: theme.palette.info.light,
      },
      "&:hover, &.active": {
        background: theme.palette.grey[200],
        color: "#213a6d",
        "& .icon-container": {
          color: "#213a6d",
        },
      },
      "&.active": {
        borderRight: `4px solid ${theme.palette.error.dark}`,
      },
      '&[aria-disabled="true"]': {
        opacity: 0.5,
      },
    },
  };
};

const checkMatch = (pathname: string, pathToCheck: string) => {
  return matchPath(pathname, pathToCheck);
};

const getPathMatch = (
  pathname: string,
  pathToCheck: string | Array<string>
): boolean => {
  if (typeof pathToCheck === "string") {
    return checkMatch(pathname, pathToCheck) !== null;
  } else {
    return pathToCheck.some((path: string) => {
      return checkMatch(pathname, path) !== null;
    });
  }
};

// NAVIGATION
export interface INavigationElement {
  disabled: boolean;
  icon: JSX.Element;
  id: string;
  name: string;
  path: string | Array<string>;
  show?: boolean;
  url: string;
  isExternal?: boolean;
  settings?: boolean;
}

interface NavigationElementProps {
  element: INavigationElement;
}
export default function NavigationElement(props: NavigationElementProps) {
  const { element } = props;
  const theme = useTheme();
  const styles = getStyles(theme);

  const { pathname } = useLocation();

  const match = getPathMatch(pathname, element.path);

  if (element?.isExternal) {
    return (
      <Box
        component="a"
        sx={styles.root}
        target="_blank"
        rel="noopener noreferrer"
        href={element.url}
      >
        <span className="icon-container">{element.icon}</span>
        {element.name}
      </Box>
    );
  }

  return (
    <ButtonBase
      component={RouterLink}
      to={element.url}
      sx={styles.root}
      disabled={element.disabled}
      className={match ? "active" : ""}
    >
      <span className="icon-container">{element.icon}</span>
      {element.name}
    </ButtonBase>
  );
}
