import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getUserProfiles } from 'core/api/users';

export interface SurveyorsSelectProps {
  defaultId: string;
  onPick: (value: string) => void;
}

export default function SurveyorsSelect(
  props: SurveyorsSelectProps
) {
  const { onPick, defaultId } = props;

  const { isFetching, isError, data, error } = useQuery({
    queryKey: ["users"],
    queryFn: () => getUserProfiles("dc_registered_surveyor"),
    enabled: true,
  });
  const users = data || [];

  const [id, setId] = useState<string>(defaultId);
  const handleChange = (event: SelectChangeEvent) => {
    const v = event.target.value;
    setId(v);
    onPick(v);
  };

  return (
    <FormControl
      sx= {{
        '& .MuiSelect-select .MuiBox-root div:last-child': {
          display: {xs: "none", md: "block"}
        }
      }}
    >
      <InputLabel shrink={true}>
        DC Registered Surveyor
      </InputLabel>
      <Select
        value={id}
        onChange={handleChange}
        displayEmpty
        className="white-bg"
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiBox-root': {
                display: "flex",
                justifyContent: "space-between",
                width:  "100%",
                paddingLeft: 2,
                paddingRight: 2,
                flexDirection: {xs: "column", md: "row"},
              },
            },
          },
        }}
      >
        <MenuItem value="" disabled>
          <Box className="placeholder">-- Select --</Box>
        </MenuItem>
        {users.map((item) => (
          <MenuItem key={item.userId} value={item.userId}>
            <Box
              display={"flex"}
              justifyContent="space-between"
            >
              <div>{item.fullName}</div>
              <div>{item.email}</div>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
