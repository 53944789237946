import { LogItem } from 'types';
import { parseDate, UI_DATE_TIME_FORMAT_STRING } from 'ui/helpers/parseDate';

import { Box, Card, SxProps, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { getLogContent } from '../helpers';

export interface DataTableProps {
  data: LogItem[];
}

const getStyles = (): { [key: string]: SxProps } => {
  return {
    table: {
      width: '100%',
      'thead th': {
        background: '##213A6D',
        padding: '16px 16px',
      },
      '& tbody td': {
        padding: '16px',
      },
      '& tbody td:first-of-type': {
        paddingLeft: '16px',
      },
      '& tbody td:last-child': {
        paddingRight: '16px',
      },
      'tr.application_reopened': {
        td: {
          bgcolor: '#e9f3e8',
        },
      },
      'tr.review_rejected': {
        td: {
          bgcolor: '#ffe9ee',
        },
      },
      'tr.application_cancelled_by_client': {
        td: {
          bgcolor: '#ffe9ee',
        },
      },
    },
    cellHelper: {
      fontSize: '1.2rem',
    },
    tableWrapper: {
      width: '100%',
      background: '#fff',
      overflow: 'auto',
    },
  };
};

export default function DataTable(props: DataTableProps) {
  const { data } = props;

  const styles = getStyles();
  return (
    <Card className="no-shadow">
      <Box sx={styles.tableWrapper}>
        <Table sx={styles.table} aria-label="Agency Applications table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: LogItem) => (
              <TableRow key={item.id} className={item.changeType}>
                <TableCell scope="row">
                  <strong>{parseDate(item.createdAt, UI_DATE_TIME_FORMAT_STRING)}</strong>
                </TableCell>

                <TableCell scope="row">{item.creatorProfile?.fullName || '-'}</TableCell>
                <TableCell scope="row">{getLogContent(item.changeType, item.metadata)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
}
