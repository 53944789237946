import { QUADRANTS, STREET_NUMBER_SUFFIX, STREET_TYPES, US_STATES } from 'core/consts/addressParts';
import BasicSelect from 'ui/form-fields/basic-fields/select/BasicSelect';
import BasicTextInput from 'ui/form-fields/basic-fields/text-input/TextInput';
import { capitalizeFirstLetter } from 'ui/helpers/capitalize-first-letter/capitalizeFirstLetter';

import { Grid } from '@mui/material';

interface Labels {
  streetNumber: string;
  streetNumberFraction: string;
  streetName: string;
  streetNameSuffix: string;
  unitNumber: string;
  quadrant: string;
  city: string;
  zip: string;
  state: string;
}

const LABELS: Labels = {
  streetNumber: "streetNumber",
  streetNumberFraction: "streetNumberFraction",
  streetName: "streetName",
  streetNameSuffix: "streetNameSuffix",
  unitNumber: "unitNumber",
  quadrant: "quadrant",
  zip: "zip",
  state: "state",
  city: "city",
};

function prepareLabels(prefix: string, capitalizeAfterPrefix: boolean) {
  const output: Labels = {
    streetNumber: "",
    streetNumberFraction: "",
    streetName: "",
    streetNameSuffix: "",
    unitNumber: "",
    quadrant: "",
    zip: "",
    state: "",
    city: "",
  };
  for (const [key, value] of Object.entries(LABELS)) {
    let label = `${prefix}${value}`;
    if (capitalizeAfterPrefix) {
      label = `${prefix}${capitalizeFirstLetter(value)}`;
    }
    output[key as keyof Labels] = label;
  }
  return output;
}

export interface AddressFieldsProps {
  control: any;
  fieldNamePrefix?: string;
  capitalizeAfterPrefix?: boolean;
  isDCAddress?: boolean;
}

export default function AddressFields(props: AddressFieldsProps) {
  const {
    control,
    fieldNamePrefix = "",
    capitalizeAfterPrefix = false,
    isDCAddress = false,
  } = props;

  const labels =
    fieldNamePrefix === ""
      ? LABELS
      : prepareLabels(fieldNamePrefix, capitalizeAfterPrefix);

  return (
    <Grid container columnSpacing={4} spacing={4}>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <BasicTextInput
          label="Street Number"
          inputName={labels.streetNumber}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <BasicSelect
          collection={STREET_NUMBER_SUFFIX}
          label="Street Number Suffix (if applicable)"
          inputName={labels.streetNumberFraction}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <BasicTextInput
          label="Street Name"
          helperText="Please use the full street name."
          inputName={labels.streetName}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <BasicSelect
          collection={STREET_TYPES}
          label="Street Type"
          inputName={labels.streetNameSuffix}
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <BasicTextInput
          label="Unit number (if applicable)"
          inputName={labels.unitNumber}
          control={control}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6}>
        <BasicSelect
          collection={QUADRANTS}
          label={!isDCAddress ? "Quadrant (optional)" : "Quadrant"}
          inputName={labels.quadrant}
          control={control}
        />
      </Grid>

      {!isDCAddress && (
        <>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <BasicTextInput
              label="City"
              inputName={labels.city}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <BasicSelect
              collection={US_STATES}
              label="State (optional)"
              inputName={labels.state}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <BasicTextInput
              label="ZIP Code"
              inputName={labels.zip}
              control={control}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
