import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

export default function UploadIcon(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon {...props} viewBox="0 0 20 25" fill="none" htmlColor={theme.palette.primary.main}>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.75 4.51099H17.5C18.3284 4.51099 19 5.18256 19 6.01099V21.761C19 22.5894 18.3284 23.261 17.5 23.261H2.5C1.67157 23.261 1 22.5894 1 21.761V6.01099C1 5.18256 1.67157 4.51099 2.5 4.51099H6.25C6.25 2.43992 7.92893 0.760986 10 0.760986C12.0711 0.760986 13.75 2.43992 13.75 4.51099Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.75 15.011L10 18.761L6.25 15.011" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 18.761V9.76099" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 3.76099C10.2071 3.76099 10.375 3.92888 10.375 4.13599C10.375 4.34309 10.2071 4.51099 10 4.51099C9.79289 4.51099 9.625 4.34309 9.625 4.13599C9.625 3.92888 9.79289 3.76099 10 3.76099" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
}
