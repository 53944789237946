import AuthorizationForm from 'app-sections/common/applications/summary/AuthorizationForm';
import Permits from 'app-sections/common/applications/summary/Permits';
import { Application } from 'types';
import FormSectionHeader from 'ui/form-section-header/FormSectionHeader';

export interface Props {
  data: Application;
}

export default function Content(
  props: Props
) {
  const { data } = props;
  return (
    <>
      <FormSectionHeader
        title="Application details"
      />
      <Permits data={data} />
      <AuthorizationForm data={data} />
    </>
  )
};
