import { getStateBadgeLabel } from 'app-sections/common/applications/details/components/details-header/components/application-state-badge/helpers';
import ApplicationAddresses from 'app-sections/user/applications/components/applications/application-addresses/ApplicationAddresses';
import { Fragment } from 'react';
import { Application } from 'types';

import { Box, Card, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme, useTheme } from '@mui/material';

import ApplicationDataId from '../application-data-id/ApplicationDataId';
import ApplicationDataOptions from '../application-data-options/ApplicationDataOptions';

export interface ApplicationsListProps {
  data: Application[];
}
const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    table: {
      width: '100%',
      margin: '0 0 24px 0',
    },
    dateCell: {
      fontWeight: 'bold',
    },
  };
};

export default function ApplicationsList(props: ApplicationsListProps) {
  const { data } = props;

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Card className="no-shadow">
      {data.map((item: Application, index: number) => (
        <Fragment key={item.id}>
          <Table className="no-borders" sx={styles.table} aria-label="Applications">
            <TableHead>
              <TableRow>
                <TableCell>Appl. #</TableCell>
                <TableCell>
                  <ApplicationDataId item={item} color="#fff" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell scope="row">Status</TableCell>
                <TableCell>{getStateBadgeLabel('client', item.state)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Permit(s)</TableCell>
                <TableCell>
                  {item.permitRecordIds.map((id) => (
                    <div key={id}>
                      {id}
                      <br />
                    </div>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Application Address</TableCell>
                <TableCell>
                  <ApplicationAddresses application={item} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">SSL</TableCell>
                <TableCell>{item.ssl}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Applicant</TableCell>
                <TableCell>{item.clientProfile?.fullName}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box mb={2} width="100%" display="flex" justifyContent="center">
            <ApplicationDataOptions item={item} />
          </Box>
        </Fragment>
      ))}
    </Card>
  );
}
