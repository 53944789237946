import { APP_DETAILS_NAV_TABS } from 'app-sections/user/applications/steps/config';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "auto" });
  const el = document.getElementById("main-scroll-container");
  if (el) {
    el.scrollTo({ top: 0, behavior: "auto" });
  }
}

/**
 * Controls that after every change of url scroll page (scroll container element) to top.
 * Takes some exceptions for review tabs
 * @returns
 */
export default function ScrollController() {
  const location = useLocation();

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    if (!pathArray.includes(APP_DETAILS_NAV_TABS[1].id)) {
      scrollToTop();
    }
  }, [location.pathname]);
  return null;
}
