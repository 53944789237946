import Holidays from 'date-holidays';
import dayjs from 'dayjs';

import { getFridayAfterThanksgiving, parseHolidayDay } from './holidaysCalculations';

// https:day.js.org/docs/en/display/format

export const UI_DATE_FORMAT_STRING = "MMM D, YYYY"; // used for showing dates for users
export const UI_DATE_TIME_FORMAT_STRING = "MMM D YYYY, h:mm a"; // used for showing date time for users
export const DATE_FORMAT_STRING = "MM/DD/YYYY";
export const DB_DATE_FORMAT_STRING = "YYYY-MM-DD";
export const DB_DATETIME_FORMAT_STRING = "YYYY-MM-DD HH:mm";

export const parseDate = (
  date: string,
  format: string = `${DATE_FORMAT_STRING}, hh:mm a`
): string => {
  return dayjs(date).format(format);
};

export const parseDateNoTime = (date: string): string => {
  return parseDate(date, DATE_FORMAT_STRING);
};

export const parseTimeTo = (timeTo: string): string => {
  return timeTo === "0:00" ? "23:59:59" : timeTo;
};

export const getHourFormatted = (hour: string): string => {
  return dayjs(`2000-01-01 ${hour}`, DB_DATETIME_FORMAT_STRING).format("h:mma");
};

export const parseTime = (
  datetime: string,
  format: string = UI_DATE_TIME_FORMAT_STRING
): string => {
  return parseDate(datetime, format);
};

export const getDBDate = (date: dayjs.Dayjs): string => {
  return date.format(DB_DATE_FORMAT_STRING);
};

export const getUSHolidays = (year: number = dayjs().year()): string[] => {
  return new Holidays("US", "DC", { types: ["public"] })
    .getHolidays(year)
    .map((o) => parseHolidayDay(o));
};

export const getUSHolidaysSafe = (now: dayjs.Dayjs = dayjs()): string[] => {
  const currentYear = getUSHolidays();
  const nextYear = getUSHolidays(now.add(1, "year").year());
  return [...currentYear, ...nextYear, getFridayAfterThanksgiving()];
};

export const checkWeekendsAndHolidays = (
  date: dayjs.Dayjs,
  holidays: string[]
): boolean => {
  if (isHoliday(date, holidays) || isWeekend(date)) {
    return true;
  }

  return false;
};

export const isHoliday = (date: dayjs.Dayjs, holidays: string[]): boolean => {
  const dateFormatted = getDBDate(date);

  if (holidays.includes(dateFormatted)) {
    return true;
  }
  return false;
};

export const isWeekend = (date: dayjs.Dayjs): boolean => {
  if (date.day() === 6 || date.day() === 0) {
    return true;
  }
  return false;
};
