import { AdditionalSslItem } from 'types';

import { Box } from '@mui/material';

import AddItem from './components/add-item/AddItem';
import ListItem from './components/list-item/ListItem';

export interface AdditionalSslsProps {
  parentId: string;
  title: string;
  isEditable?: boolean;
  items: AdditionalSslItem[];
}

export default function AdditionalSsls(props: AdditionalSslsProps) {
  const { parentId, title, isEditable = false, items } = props;

  return (
    <>
      <Box
        component="h3"
        sx={{
          fontSize: '1.4rem',
          fontWeight: 500,
          color: '#676B74',
          mb: 0.5,
        }}
      >
        {title}
      </Box>

      {items.map((document) => (
        <ListItem key={document.id} item={document} isEditable={isEditable} parentId={parentId} items={items} />
      ))}
      {isEditable && <AddItem parentId={parentId} items={items} />}
      {!isEditable && items.length === 0 && <Box sx={{ fontSize: '2rem', mb: 1 }}>No Additional SSL numbers</Box>}
    </>
  );
}
