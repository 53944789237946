import { Box, CircularProgress, LinearProgress } from '@mui/material';

export interface FullPageLoaderProps {
  size?: number;
  loaderStyle?: "circular" | "linear";
}

export default function FullPageLoader(props: FullPageLoaderProps) {
  const { size = 100, loaderStyle = "circular" } = props;

  if (loaderStyle === "circular") {
    return (
      <Box
        sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
        display="flex"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="secondary" size={size} />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
}
