import { commonTableStyles } from 'app-sections/common/applications/commonTableStyles';
import {
  getDivisionNameLabel,
  getStateBadgeLabel,
} from 'app-sections/common/applications/details/components/details-header/components/application-state-badge/helpers';
import ApplicationAddresses from 'app-sections/user/applications/components/applications/application-addresses/ApplicationAddresses';
import { getApplicationTypeLabelById, VariantOptionId } from 'app-sections/user/applications/steps/step3/config';
import { Application } from 'types';
import { parseDate, UI_DATE_FORMAT_STRING } from 'ui/helpers/parseDate';

import { Box, Card, SxProps, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import ApplicationDataId from '../application-data-id/ApplicationDataId';
import ApplicationDataOptions from '../application-data-options/ApplicationDataOptions';
import ApplicationSWOInfo from '../application-swo-info/ApplicationSWOInfo';

export interface ApplicationsTableProps {
  data: Application[];
  tab: 'closed' | 'in_progress';
}

const getStyles = (): { [key: string]: SxProps } => {
  return commonTableStyles;
};

export default function ApplicationsTable(props: ApplicationsTableProps) {
  const { data, tab } = props;

  const styles = getStyles();
  return (
    <Card className="no-shadow">
      <Box sx={styles.tableWrapper}>
        <Table sx={styles.table} aria-label="Agency Applications table">
          <TableHead>
            <TableRow>
              <TableCell>Appl. #</TableCell>
              <TableCell>Appl. Type</TableCell>
              <TableCell>PERMIT(S)</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Submission Date</TableCell>
              <TableCell>Application Address</TableCell>

              <TableCell>SSL</TableCell>
              <TableCell>Applicant</TableCell>
              <TableCell>DC REGISTERED SURVEYOR</TableCell>
              {tab !== 'closed' && <TableCell>Division</TableCell>}
              {tab !== 'closed' && <TableCell>Assignment</TableCell>}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: Application) => (
              <TableRow key={item.id} className={item.state === 'swo' || item?.swoSetAt ? 'is-warning' : ''}>
                <TableCell scope="row">
                  <ApplicationDataId applicationId={item.id} permitNumber={item.certificatePermitNumber || ''} />
                  <ApplicationSWOInfo data={item.state} />
                </TableCell>

                <TableCell scope="row">
                  {item.applicationType && <>{getApplicationTypeLabelById(item.applicationType as VariantOptionId)}</>}
                </TableCell>

                <TableCell>
                  {item.permitRecordIds?.map((id) => (
                    <div key={id}>
                      {id}
                      <br />
                    </div>
                  ))}
                </TableCell>

                <TableCell scope="row" className={item.state === 'swo' || item?.swoSetAt ? 'is-warning' : ''}>
                  {getStateBadgeLabel('admin', item.state)}
                </TableCell>

                <TableCell scope="row">
                  {item.slaDeadlineAt ? parseDate(item.slaDeadlineAt, UI_DATE_FORMAT_STRING) : '-'}
                </TableCell>

                <TableCell scope="row">
                  {item.submittedAt ? parseDate(item.submittedAt, UI_DATE_FORMAT_STRING) : '-'}
                </TableCell>

                <TableCell scope="row">
                  <ApplicationAddresses application={item} />
                </TableCell>

                <TableCell scope="row">{item.ssl}</TableCell>
                <TableCell scope="row">
                  {!item.applicantPersonalInformation && <>-</>}
                  {item.applicantPersonalInformation && <>{item.applicantPersonalInformation.name || '-'}</>}
                </TableCell>

                <TableCell scope="row">
                  {!item.surveyor && <>-</>}
                  {item.surveyor && <>{item.surveyor.fullName}</>}
                </TableCell>

                {tab !== 'closed' && (
                  <TableCell scope="row">
                    {item.currentDivision && <>{getDivisionNameLabel(item.currentDivision)}</>}
                  </TableCell>
                )}

                {tab !== 'closed' && (
                  <TableCell scope="row">
                    {item.currentDivision === 'surveyorsOffice' && item.surveyorTechnicianProfile?.fullName}
                    {item.currentDivision === 'zoning' && item.zoningTechnicianProfile?.fullName}
                  </TableCell>
                )}

                <TableCell align="right">
                  <ApplicationDataOptions item={item} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
}
