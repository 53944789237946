import { Box } from '@mui/material';

export interface ButtonIconProps {
  icon: JSX.Element;
  noMargin?: boolean;
  color?: string;
  isActive: boolean;
}

export default function ButtonIcon(props: ButtonIconProps) {
  const { icon, noMargin = false, color = "#000", isActive } = props;

  if (!isActive) {
    return null;
  }

  return (
    <Box display="flex" mr={noMargin ? 0 : 2} sx={{ color }}>
      {icon}
    </Box>
  );
}
