import { updateOneCacheItem } from 'core/cache/helpers';
import { useState } from 'react';
import { AdditionalSslItem, Application } from 'types';
import ErrorsList from 'ui/components/errors-list/ErrorsList';

import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import ItemDialog from '../item-dialog/ItemDialog';
import ItemForm from '../item-form/ItemForm';
import { FormValues } from '../item-form/validation-schema';
import ItemView from '../item-view/ItemView';
import { updateSsls } from 'core/api/applications';

export interface ListItemProps {
  parentId: string;
  item: AdditionalSslItem;
  isEditable: boolean;
  items: AdditionalSslItem[];
}

export default function ListItem(props: ListItemProps) {
  const { item, isEditable, items, parentId } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [resetTrigger, setResetTrigger] = useState<number>(0);
  const [submitTrigger, setSubmitTrigger] = useState<number>(0);

  const queryClient = useQueryClient();
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: (data: AdditionalSslItem[]) => updateSsls(parentId, data),
    onSuccess: (newItems: AdditionalSslItem[]) => {
      updateOneCacheItem<Application>(queryClient, ['applications', parentId], { additionalSsls: newItems });
      setIsOpened(false);
      setSubmitTrigger(0);
    },
  });

  const handleCancel = () => {
    setSubmitTrigger(0);
    setIsOpened(false);
    setResetTrigger((v) => v + 1);
  };

  return (
    <>
      <ItemDialog
        title="Edit SSL"
        submitText="Save SSL"
        open={isOpened}
        onCancel={handleCancel}
        onSubmit={() => setSubmitTrigger((v) => v + 1)}
        isLoading={isPending}
      >
        <ItemForm
          onFormSubmit={(data: AdditionalSslItem) => {
            mutate(items.map((i) => (i.id === item.id ? data : i)));
          }}
          defaults={item as Partial<FormValues>}
          resetTrigger={resetTrigger}
          submitTrigger={submitTrigger}
        />

        {isError && (
          <Box mt={2}>
            <ErrorsList title="Error editing address" errors={error} />
          </Box>
        )}
      </ItemDialog>

      <ItemView
        parentId={parentId}
        items={items}
        item={item}
        onButtonEdit={() => {
          setIsOpened(true);
        }}
        isEditable={isEditable}
      />
    </>
  );
}
