import { Params } from 'types';
import StepSuccessIcon from 'ui/icons/StepSuccessIcon';

import { StepIconProps, Theme, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

export interface StatusStepperProps {
  status: string; //"pending" | "surveyor_office_review" | "zoning_review" | "closed";
  subStatus: string;
}

const STATUSES: Params[] = [
  { name: 'Pending', id: 'pending', visibleOnStepper: true },
  {
    name: 'Surveyor’s Review',
    id: 'surveyor_office_review',
    visibleOnStepper: true,
  },
  { name: 'Zoning Review', id: 'zoning_review', visibleOnStepper: true },
  { name: 'Closed', id: 'closed', visibleOnStepper: true },
  { name: 'SWO', id: 'swo', visibleOnStepper: false },
];

function getSubStatuses(theme: Theme) {
  const SUB_STATUSES: Params[] = [
    {
      pending_invitation: {
        name: 'Pending Invitation',
        color: theme.palette.background.paper,
        bgBody: theme.palette.primary.dark,
        borderColor: theme.palette.grey[600],
      },
      pending_documents: {
        name: 'Pending Documents',
        color: theme.palette.background.paper,
        bgBody: theme.palette.primary.dark,
        borderColor: theme.palette.grey[600],
      },
    },
    {
      in_progress: {
        name: 'In Progress',
        color: theme.palette.background.paper,
        bgBody: theme.palette.primary.dark,
        borderColor: theme.palette.grey[600],
      },
      hold_for_correction: {
        name: 'Hold for Correction',
        color: theme.palette.text.primary,
        bgBody: theme.palette.warning.light,
        borderColor: theme.palette.warning.dark,
      },
    },
    {
      in_progress: {
        name: 'In Progress',
        color: theme.palette.background.paper,
        bgBody: theme.palette.primary.dark,
        borderColor: theme.palette.grey[600],
      },
      hold_for_correction: {
        name: 'Hold for Correction',
        color: theme.palette.text.primary,
        bgBody: theme.palette.warning.light,
        borderColor: theme.palette.warning.dark,
      },
    },
    {
      approved: {
        name: 'Approved',
        color: theme.palette.text.primary,
        bgBody: theme.palette.success.light,
        borderColor: theme.palette.success.dark,
      },
      denied: {
        name: 'Denied',
        color: theme.palette.text.primary,
        bgBody: theme.palette.error.light,
        borderColor: theme.palette.error.dark,
      },
      partially_approved: {
        name: 'Partially approved',
        color: theme.palette.background.paper,
        bgBody: theme.palette.primary.dark,
        borderColor: theme.palette.grey[600],
      },
      bellow_grade: {
        name: 'Below Grade',
        color: theme.palette.background.paper,
        bgBody: theme.palette.primary.dark,
        borderColor: theme.palette.grey[600],
      },
      cancelled: {
        name: 'Cancelled',
        color: theme.palette.text.primary,
        bgBody: theme.palette.error.light,
        borderColor: theme.palette.error.dark,
      },
    },
    {
      swo: {
        name: 'SWO',
        color: theme.palette.text.primary,
        bgBody: theme.palette.error.light,
        borderColor: theme.palette.error.dark,
      },
    },
  ];

  return SUB_STATUSES;
}

export function getSubStateName(
  status: string,
  subStatus: string,
  theme: Theme
): string {
  const subStatuses = getSubStatuses(theme);
  const index = STATUSES.findIndex((s) => s.id === status);
  return subStatuses[index][subStatus].name;
}

export default function StatusStepper(props: StatusStepperProps) {
  const { status, subStatus } = props;
  const theme = useTheme();
  const subStatuses = getSubStatuses(theme);
  let activeStep = STATUSES.findIndex((s) => s.id === status);

  activeStep = activeStep === 3 ? 4 : activeStep;

  return (
    <Box
      sx={{
        width: '100%',
        background: '#F0F6FA',
        padding: '24px',
        borderRadius: '4px',
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        {STATUSES.filter((i) => i.visibleOnStepper).map((stepData, index) => (
          <Step key={stepData.name}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              sx={{ padding: '0px' }}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>
                {stepData.name}
              </Typography>
            </StepLabel>
            {activeStep === 4 && subStatuses[index][subStatus] && (
              <Box
                mt={0}
                mb={3}
                ml={7}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    height: '32px',
                    borderRadius: '4px',
                    borderColor: subStatuses[index][subStatus].borderColor,
                    color: subStatuses[index][subStatus].color,
                    backgroundColor: subStatuses[index][subStatus].bgBody,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    padding: '4px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  <Typography noWrap>
                    {subStatuses[index][subStatus].name}
                  </Typography>
                </Box>
              </Box>
            )}

            {activeStep === index && subStatuses[index][subStatus] && (
              <StepContent sx={{ position: { xs: 'absolute', md: 'initial' } }}>
                <Box
                  mt={-1}
                  mb={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    sx={{
                      height: '32px',
                      borderRadius: '4px',
                      borderColor: subStatuses[index][subStatus].borderColor,
                      color: subStatuses[index][subStatus].color,
                      backgroundColor: subStatuses[index][subStatus].bgBody,
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      padding: '4px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    <Typography noWrap>
                      {subStatuses[index][subStatus].name}
                    </Typography>
                  </Box>
                </Box>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

function CustomStepIcon(props: StepIconProps) {
  const { active, completed, icon } = props;

  const theme = useTheme();
  let bg = theme.palette.background.paper;
  if (completed) {
    bg = theme.palette.success.main;
  }
  if (active) {
    bg = theme.palette.primary.main;
  }

  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        borderRadius: '50%',
        background: bg,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {completed && <StepSuccessIcon />}
      {!completed && (
        <Box
          sx={{
            fontSize: '2rem',
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
            color: active ? '#fff' : '',
          }}
        >
          {icon}
        </Box>
      )}
    </Box>
  );
}
