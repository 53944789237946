import { getApplicationById } from 'core/api/applications';
import { useLocation, useParams } from 'react-router-dom';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import LinesSkeleton from 'ui/components/skeletons/lines-skeleton/LinesSkeleton';

import { Box, Card, CardContent, Container, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import Content from './SummaryContent';
import { getZoningReviews, zoningReviewsGroupedByPermit } from 'core/api/zoning-reviews';
import { ZoningReview } from 'types';

export default function Summary() {
  const { id: activeApplicationId = "" } = useParams();

  const applicationQueryKey = ["applications", activeApplicationId];
  const location = useLocation();

  const { isLoading, isError, data: applicationData, error, isSuccess } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId, location.pathname.includes('/public/applications')),
    enabled: activeApplicationId !== "",
  });

  const zoningReviewQueryKey = ["applications", applicationData?.id || "", "zoning_reviews"];
  const {
    data: zoningReviewsData,
  } = useQuery({
    enabled: applicationData && applicationData.permitRecordIds.length > 0,
    queryKey: zoningReviewQueryKey,
    queryFn: () => applicationData ? getZoningReviews<ZoningReview[]>(applicationData.id, applicationData.permitRecordIds) : [],
  });


  const permits = zoningReviewsGroupedByPermit(
    applicationData?.id || "",
    applicationData ? applicationData.permitRecordIds : [],
    zoningReviewsData || []
  );

  return (
    <Box mt={5}>
      <Container className="disable-center">
        <>
          {isLoading && !isError && (
            <Grid container columnSpacing={4} spacing={4}>
              <Grid item xs={12} sm={12} tablet={6} md={7} lg={5}>
                <Card className="rounded-small small-margin-bottom">
                  <CardContent>
                    <Box mt={-4} mb={0}>
                      <LinesSkeleton
                        elements={4}
                        spacing={3}
                        isWithButtons={false}
                        lineHeight={90}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} tablet={6} md={5} lg={7}>
                <Card className="rounded-small small-margin-bottom">
                  <CardContent>
                    <Box mt={-4} mb={0}>
                      <LinesSkeleton
                        elements={4}
                        spacing={3}
                        isWithButtons={false}
                        lineHeight={90}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          {!isLoading && isSuccess && <Content data={applicationData} permits={permits} />}

          {isError && error && (
            <ErrorsList
              title="Unexpected loading error"
              errors={error as string[]}
            />
          )}
        </>
      </Container>
    </Box>
  );
}
