import { useLocation } from 'react-router-dom';
import { AccelaPermitDetails } from 'types';
import AlertTriangleCustom from 'ui/icons/AlertTriangleCustom';
import CheckBadge from 'ui/icons/CheckBadge';

import { Box, Card, Divider, useTheme } from '@mui/material';

import { applicationPublicView } from '../helpers';

export interface PermitCardProps {
  data: AccelaPermitDetails;
  isWithSwo?: boolean;
}

export default function PermitCard(props: PermitCardProps) {
  const { data, isWithSwo } = props;

  const location = useLocation();
  const isPublicView = applicationPublicView(location);
  const theme = useTheme();

  return (
    <Card
      className={`h-full no-shadow rounded ${isWithSwo ? 'card-type-info-light' : ''}`}
      sx={{
        background: theme.palette.grey[200],
        minHeight: '108px',
      }}
    >
      <Box p={3}>
        {isWithSwo && (
          <Box ml={4} sx={{ color: '#b05e11' }}>
            SWO restriction
          </Box>
        )}
        <Box display="flex">
          <Box mr={1} mt={0.5}>
            {isWithSwo && <AlertTriangleCustom sx={{ color: '#b05e11' }} />}
            {!isWithSwo && <CheckBadge />}
          </Box>
          <Box>
            <Box component="h3" m={0}>
              <Box sx={{ fontWeight: 'normal', textTransform: 'uppercase' }}>Permit #</Box>
              <Box component="strong" sx={{ fontSize: '2rem', color: theme.palette.primary.dark }}>
                {data.recordId}
              </Box>
            </Box>
            <Box mt={1} fontSize="1.6rem">
              Permit address
            </Box>
            <Box component="strong" fontSize="1.6rem">
              {data.addressLine}
            </Box>
            <Box mt={1} fontSize="1.6rem">
              SSL
            </Box>
            <Box component="strong" fontSize="1.6rem">
              {data.parcelNumber}
            </Box>
            <Box mt={1} fontSize="1.6rem">
              Scope of work
            </Box>
            <Box component="strong" fontSize="1.6rem">
              {data.workDesc}
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <>
              {data.ownerList?.map((owner) => (
                <Box key={`${owner.capId}-${owner.addressLine}`} mb={2}>
                  <Box mt={1} fontSize="1.6rem">
                    Owner name
                  </Box>
                  <Box component="strong" fontSize="1.6rem">
                    {owner.fullName}
                  </Box>
                  {!isPublicView && (
                    <>
                      <Box mt={1} fontSize="1.6rem">
                        Owner address
                      </Box>
                      <Box component="strong" fontSize="1.6rem">
                        {owner.addressLine}
                      </Box>
                    </>
                  )}
                </Box>
              ))}
            </>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
