import ApplicationStepsLayout from 'core/layouts/components/application-steps-layout/ApplicationStepsLayout';
import Error404Page from 'core/layouts/error-pages/Error404Page';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

import SidebarLayout from '../../layouts/components/sidebar-layout/SidebarLayout';
import ErrorPage from '../../layouts/error-pages/ErrorPage';
import MustBeLoggedIn from '../protected-routes/must-be-logged-in/MustBeLoggedIn';
import { applicationRoutes } from './applicationRoutes';

const Applications = lazy(
  () => import("app-sections/user/applications/Applications")
);

const CreateNewApplication = lazy(
  () =>
    import(
      "app-sections/user/applications/create-new-application/CreateNewApplication"
    )
);

const Dashboard = lazy(
  () => import("../../../app-sections/user/dashboard/Dashboard")
);

const Notifications = lazy(
  () => import("app-sections/common/notifications/Notifications")
);

const Profile = lazy(() => import("app-sections/user/profile/Profile"));

export const userGeneralRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <MustBeLoggedIn role="client">
        <SidebarLayout />
      </MustBeLoggedIn>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "/applications",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Applications />
          </Suspense>
        ),
      },
      {
        path: "/applications/create-new",
        element: <ApplicationStepsLayout />,
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<DataLoader />}>
                <CreateNewApplication />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "/applications/:id",
        children: applicationRoutes,
      },

      {
        path: "/profile",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: "/notifications",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Notifications />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/404",
    element: <Error404Page />,
  },
];
