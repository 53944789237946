import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import InfoMessage from 'ui/components/info-message/InfoMessage';
import AddressFields from 'ui/form-fields/address-fields/AddressFields';
import FormWrapper from 'ui/form-fields/form-wrapper/FormWrapper';
import { mergeData } from 'ui/form-utils';

import { yupResolver } from '@hookform/resolvers/yup';

import { defaultValues, FormValues, getValidationSchema } from './validation-schema';

export interface ItemFormProps {
  defaults?: Partial<FormValues>;
  resetTrigger?: number;
  submitTrigger?: number;
  onFormSubmit: (data: any) => void;
}

export default function ItemForm(props: ItemFormProps) {
  const { onFormSubmit, defaults, resetTrigger = 0, submitTrigger = 0 } = props;

  const { handleSubmit, control, reset } = useForm<FormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: mergeData(defaultValues, defaults ?? {}),
    resolver: yupResolver(getValidationSchema()),
  });

  useEffect(() => {
    if (resetTrigger > 0) {
      reset();
    }
  }, [resetTrigger]);

  useEffect(() => {
    // programmatically trigger form submit
    if (submitTrigger > 0) {
      handleSubmit(onSubmit)();
    }
  }, [submitTrigger]);

  const onSubmit = (data: FormValues) => {
    onFormSubmit(data);
  };

  return (
    <>
      <FormWrapper<FormValues> onChangeCallback={() => {}} onSubmitCallback={onSubmit} handleSubmit={handleSubmit}>
        <>
          <InfoMessage
            type="neutral-inversed"
            notClosable={true}
            isSlim={true}
            title=""
            content="If your address is a numbered street, please include the “th”, “rd”, “st”, etc. as in 4th Street SW. Also, if your street name includes North, South, East or West, spell out the words, e.g. North Capitol. "
          />
          <AddressFields control={control} fieldNamePrefix="" isDCAddress={true} />
        </>
      </FormWrapper>
    </>
  );
}
