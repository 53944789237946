import { useAuthStore } from 'core/store/auth';
import { useEffect, useState } from 'react';

import UpdatesListener from '../updates-listener/UpdatesListener';

/**
 * Wrapper for updates listener.
 * Gets current user data from store.
 * @param props
 * @returns
 */
export default function UpdatesListenerWrapper() {
  const { currentUser } = useAuthStore();

  const [userId, setUserId] = useState<string | null>(currentUser?.id || null);

  useEffect(() => {
    setUserId(currentUser?.id || null);
  }, [currentUser?.id]);

  if (!userId) {
    return null;
  }

  return <UpdatesListener userId={userId} />;
}
