import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { changeAssignment } from 'core/api/applications';
import { getUserProfiles } from 'core/api/users';
import { useAuthStore } from 'core/store/auth';
import { useState } from 'react';
import { Application, UserRole } from 'types';
import FormSectionHeader from 'ui/form-section-header/FormSectionHeader';
import { isAReviewerForApplication } from 'ui/helpers/users';
import MyReviewDetails from './MyReviewDetails';
import SelectUser from './SelectUser';
import { compact, uniq } from 'lodash-es';

export interface ReviewManagementProps {
  application: Application;
  role: UserRole;
};

const DIVISIONS_MAP = {
  zoning: 'Zoning',
  surveyor_office: 'Surveyor’s Office',
};

export default function ReviewManagement(props: ReviewManagementProps) {
  const { application, role, } = props;

  const zoningRoles:UserRole[]  = ['zoning_manager', 'zoning_technician'];
  const divisionType = zoningRoles.includes(role) ? 'zoning' : 'surveyor_office';
  const divisionName = DIVISIONS_MAP[divisionType];
  const technicianRole = `${divisionType}_technician` as UserRole;
  const managerRole = `${divisionType}_manager` as UserRole;
  const technicianProfileProperty = `${divisionType.split('_')[0]}TechnicianProfile` as keyof Application;
  const managerProfileProperty = `${divisionType.split('_')[0]}ManagerProfile` as keyof Application;
  let technicianId, managerId;
  if (zoningRoles.includes(role)){
    managerId = application.zoningManagerId;
    technicianId = application.zoningTechnicianId;
  } else {
    managerId = application.surveyorManagerId;
    technicianId = application.surveyorTechnicianId;
  }

  const theme = useTheme();

  const [savingRole, setSavingRole] = useState<String>("");

  const queryClient = useQueryClient();
  const { data: technicianData } = useQuery({
    queryKey: ["reviewers", "technicians"],
    queryFn: () => getUserProfiles(technicianRole),
    enabled: true,
  });
  const { data: managerData } = useQuery({
    queryKey: ["reviewers", "managers"],
    queryFn: () => getUserProfiles(managerRole),
    enabled: true,
  });

  const mutation = useMutation({
    mutationFn: (data: { role: UserRole, userProfileId: string }) =>
      changeAssignment(application.id, data.role, data.userProfileId),
  });

  const saveRole = (role: UserRole, userId: string) => {
    setSavingRole(role);
    mutation.mutate(
      {
        role: role,
        userProfileId: userId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["applications", application.id] });
        }
      }
    )
  };

  const { currentUser } = useAuthStore();
  const isReviewer = isAReviewerForApplication(currentUser, application);
  const technicians = compact(uniq([...(technicianData || []), ...[application[technicianProfileProperty]]]));
  const managers = compact(uniq([...(managerData || []), ...[application[managerProfileProperty]]]));

  return (
    <>
      <FormSectionHeader
        title="Review management"
        subtitle="Select a reviewer below if not already assigned."
      />
      <Card>
        <CardContent>
          {isReviewer &&
            <MyReviewDetails application={application} divisionType={divisionType} />
          }
          <Card className="no-shadow card-type-neutral-inversed">
            <CardContent>
              <Typography component="h3" variant="h4" mb={3}>
                {divisionName === "Zoning" ? "Reviewers" : "Invite Surveyor Manager to this application"}
              </Typography>
              {divisionName === "Zoning" && (
                <Typography component="h6" variant="subtitle2" mb={3} sx={{ color: theme.palette.text.primary }}>
                  Change Reviewers as needed by reassigning the {divisionName} review to another reviewer or manager.
                </Typography>
              )}
              {divisionName === "Surveyor’s Office" && (
                <Typography component="h6" variant="subtitle2" mb={3} sx={{ color: theme.palette.text.primary }}>
                  When the first review for surveyor’s office is finished, a Surveyor Manager needs to be invited to the applications to sign the Wall Check Examination Report.
                </Typography>
              )}

              <Grid container columnSpacing={4} spacing={2}>
                <SelectUser
                  label={`${divisionName} Technician`}
                  onSave={(userId) => saveRole(technicianRole, userId)}
                  defaultUserId={technicianId}
                  userProfiles={technicians}
                  isLoading={mutation.isPending && technicianRole === savingRole}
                  isBold = {true}
                />
                <SelectUser
                  label={`${divisionName} Manager`}
                  onSave={(userId) => saveRole(managerRole, userId)}
                  defaultUserId={managerId}
                  userProfiles={managers}
                  isLoading={mutation.isPending && managerRole === savingRole}
                />
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </>
  );

}
