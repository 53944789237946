import {
  AdditionalAddressItem,
  AdditionalSslItem,
  ApiCollectionResponse,
  ApiPayResponse,
  Application,
  NewApplication,
  Params,
  UserRole,
} from 'types';

import { getCollectionResponse, request } from '../config';

const APP_URL = 'applications.json';
const APP_BY_ID_URL = 'applications';

export function createApplication(application: NewApplication): Promise<Application> {
  return request(
    {
      url: APP_URL,
      method: 'POST',
      data: { application },
    },
    (data) => data.model
  );
}

export function getApplications(data: Params, signal?: AbortSignal): Promise<ApiCollectionResponse<Application>> {
  return request(
    {
      url: APP_URL,
      method: 'GET',
      params: { ...data, expand: true, notStates: 'draft_deleted_by_client' },
      signal,
    },
    (data) => {
      return getCollectionResponse<Application>(data);
    }
  );
}

export function getApplicationById(id: string, publicScope?: boolean): Promise<Application> {
  const params = publicScope ? { public: true } : {};
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}.json`,
      method: 'GET',
      params: params,
      data: {},
    },
    (data) => data.model
  );
}

export function saveStep<T>(id: string, step: number, lastValidStep: number, data: T): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}.json`,
      method: 'PATCH',
      data: {
        application: {
          ...data,
          lastSavedStep: step,
          currentStep: lastValidStep,
        },
      },
    },
    (data) => data.model
  );
}

export function manualSwoCheck(id: string): Promise<Params> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}/check-swo.json`,
      method: 'PATCH',
      data: {},
    },
    () => {
      return {
        id: id,
        isChecked: null,
        isLoading: true,
      };
    }
  );
}

export function getPayUrl(id: string): Promise<ApiPayResponse> {
  return request({
    url: `applications/${id}/pay.json`,
    method: 'POST',
  });
}

export function submitApplicationToZoningReview(id: string, data: Params): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}/move-to-zoning-review.json`,
      method: 'PATCH',
      data: { application: { ...data } },
    },
    (data) => data.model
  );
}

export function completeApplication(id: string, data: Params): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}/complete.json`,
      method: 'PATCH',
      data: { application: { ...data } },
    },
    (data) => data.model
  );
}

export function completeApplicationBellowGrade(id: string, data: Params): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}/complete-bellow-grade.json`,
      method: 'PATCH',
      data: { application: { ...data } },
    },
    (data) => data.model
  );
}

export function denyApplication(id: string, data: Params): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${id}/deny.json`,
      method: 'PATCH',
      data: { application: { ...data } },
    },
    (data) => data.model
  );
}

export function cancelApplication(applicationId: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/cancel.json`,
      method: 'PATCH',
    },
    (data) => data.model
  );
}

export function updateSurveyor(applicationId: string, surveyorId: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/update-surveyor.json`,
      method: 'PATCH',
      data: { surveyorId: surveyorId },
    },
    (data) => data.model
  );
}

export function acceptSurveyorInvite(applicationId: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/accept-surveyor-invite.json`,
      method: 'PATCH',
    },
    (data) => data.model
  );
}

export function rejectSurveyorInvite(applicationId: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/reject-surveyor-invite.json`,
      method: 'PATCH',
    },
    (data) => data.model
  );
}

export function reopenApplication(applicationId: string, reopenReason: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/reopen.json`,
      method: 'PATCH',
      data: { application: { reopenReason } },
    },
    (data) => data.model
  );
}

export function changeAssignment(applicationId: string, role: UserRole, userId: string): Promise<Application> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/change-assignment.json`,
      method: 'PATCH',
      data: { role, userId },
    },
    (data) => data.model
  );
}

export function updateAdditionalAddresses(
  applicationId: string,
  items: AdditionalAddressItem[]
): Promise<AdditionalAddressItem[]> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/additional-addresses.json`,
      method: 'PATCH',
      data: { application: { additionalAddresses: items } },
    },
    (data) => data.model.additionalAddresses
  );
}

export function updateSsls(applicationId: string, items: AdditionalSslItem[]): Promise<AdditionalSslItem[]> {
  return request(
    {
      url: `${APP_BY_ID_URL}/${applicationId}/additional-ssls.json`,
      method: 'PATCH',
      data: { application: { additionalSsls: items } },
    },
    (data) => data.model.additionalSsls
  );
}
