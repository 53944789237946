export const STREET_TYPES = [
  { name: "ALY", id: "ALY" },
  { name: "AVE", id: "AVE" },
  { name: "BLVD", id: "BLVD" },
  { name: "BRG", id: "BRG" },
  { name: "CIR", id: "CIR" },
  { name: "CRES", id: "CRES" },
  { name: "CT", id: "CT" },
  { name: "DR", id: "DR" },
  { name: "DRWY", id: "DRWY" },
  { name: "EXPY", id: "EXPY" },
  { name: "FWY", id: "FWY" },
  { name: "GDNS", id: "GDNS" },
  { name: "GRN", id: "GRN" },
  { name: "KYS", id: "KYS" },
  { name: "LN", id: "LN" },
  { name: "MEWS", id: "MEWS" },
  { name: "PKWY", id: "PKWY" },
  { name: "PL", id: "PL" },
  { name: "PLZ", id: "PLZ" },
  { name: "PROM", id: "PROM" },
  { name: "RD", id: "RD" },
  { name: "ROW", id: "ROW" },
  { name: "SQ", id: "SQ" },
  { name: "ST", id: "ST" },
  { name: "TER", id: "TER" },
  { name: "WALK", id: "WALK" },
  { name: "WAY", id: "WAY" },
];

export const QUADRANTS = [
  { name: "NW", id: "NW" },
  { name: "NE", id: "NE" },
  { name: "SW", id: "SW" },
  { name: "SE", id: "SE" },
];

export const STREET_NUMBER_SUFFIX = [
  { name: "Non-applicable", id: "" },
  { name: "1/2", id: "1/2" },
  { name: "1/4", id: "1/4" },
  { name: "3/4", id: "3/4" },
  { name: "A", id: "A" },
  { name: "B", id: "B" },
  { name: "B2", id: "B2" },
  { name: "C", id: "C" },
  { name: "C1", id: "C1" },
  { name: "C2", id: "C2" },
  { name: "D", id: "D" },
  { name: "E", id: "E" },
  { name: "F", id: "F" },
  { name: "G", id: "G" },
  { name: "H", id: "H" },
  { name: "I", id: "I" },
  { name: "J", id: "J" },
  { name: "K", id: "K" },
  { name: "L", id: "L" },
  { name: "M", id: "M" },
  { name: "N", id: "N" },
  { name: "O", id: "O" },
  { name: "P", id: "P" },
  { name: "Q", id: "Q" },
  { name: "R", id: "R" },
  { name: "REA", id: "REA" },
  { name: "RR", id: "RR" },
  { name: "REAR", id: "REAR" },
];

export const US_STATES = [
  { name: "Alabama", id: "Alabama" },
  { name: "Alaska", id: "Alaska" },
  { name: "Arizona", id: "Arizona" },
  { name: "Arkansas", id: "Arkansas" },
  { name: "California", id: "California" },
  { name: "Colorado", id: "Colorado" },
  { name: "Connecticut", id: "Connecticut" },
  { name: "Delaware", id: "Delaware" },
  { name: "District Of Columbia", id: "District Of Columbia" },
  { name: "Florida", id: "Florida" },
  { name: "Georgia", id: "Georgia" },
  { name: "Hawaii", id: "Hawaii" },
  { name: "Idaho", id: "Idaho" },
  { name: "Illinois", id: "Illinois" },
  { name: "Indiana", id: "Indiana" },
  { name: "Iowa", id: "Iowa" },
  { name: "Kansas", id: "Kansas" },
  { name: "Kentucky", id: "Kentucky" },
  { name: "Louisiana", id: "Louisiana" },
  { name: "Maine", id: "Maine" },
  { name: "Maryland", id: "Maryland" },
  { name: "Massachusetts", id: "Massachusetts" },
  { name: "Michigan", id: "Michigan" },
  { name: "Minnesota", id: "Minnesota" },
  { name: "Mississippi", id: "Mississippi" },
  { name: "Missouri", id: "Missouri" },
  { name: "Montana", id: "Montana" },
  { name: "Nebraska", id: "Nebraska" },
  { name: "Nevada", id: "Nevada" },
  { name: "New Hampshire", id: "New Hampshire" },
  { name: "New Jersey", id: "New Jersey" },
  { name: "New Mexico", id: "New Mexico" },
  { name: "New York", id: "New York" },
  { name: "North Carolina", id: "North Carolina" },
  { name: "North Dakota", id: "North Dakota" },
  { name: "Ohio", id: "Ohio" },
  { name: "Oklahoma", id: "Oklahoma" },
  { name: "Oregon", id: "Oregon" },
  { name: "Pennsylvania", id: "Pennsylvania" },
  { name: "Rhode Island", id: "Rhode Island" },
  { name: "South Carolina", id: "South Carolina" },
  { name: "South Dakota", id: "South Dakota" },
  { name: "Tennessee", id: "Tennessee" },
  { name: "Texas", id: "Texas" },
  { name: "Utah", id: "Utah" },
  { name: "Vermont", id: "Vermont" },
  { name: "Virginia", id: "Virginia" },
  { name: "Washington", id: "Washington" },
  { name: "West Virginia", id: "West Virginia" },
  { name: "Wisconsin", id: "Wisconsin" },
  { name: "Wyoming", id: "Wyoming" },
];

export const getStreetNameSuffixById = (id: string): { name: string; id: string } => {
  return STREET_TYPES.filter((item: { name: string; id: string }) => {
    return item.id === id;
  })[0];
};

export const getQuadrantById = (id: string): { name: string; id: string } => {
  return QUADRANTS.filter((item: { name: string; id: string }) => {
    return item.id === id;
  })[0];
};
