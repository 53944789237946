import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import SslMaskedTextInput from 'ui/form-fields/basic-fields/ssl-masked-text-input/SslMaskedTextInput';
import FormWrapper from 'ui/form-fields/form-wrapper/FormWrapper';
import { mergeData } from 'ui/form-utils';

import { yupResolver } from '@hookform/resolvers/yup';

import { defaultValues, FormValues, getValidationSchema } from './validation-schema';

export interface ItemFormProps {
  defaults?: Partial<FormValues>;
  resetTrigger?: number;
  submitTrigger?: number;
  onFormSubmit: (data: any) => void;
}

export default function ItemForm(props: ItemFormProps) {
  const { onFormSubmit, defaults, resetTrigger = 0, submitTrigger = 0 } = props;

  const { handleSubmit, control, reset } = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: mergeData(defaultValues, defaults ?? {}),
    resolver: yupResolver(getValidationSchema()),
  });

  useEffect(() => {
    if (resetTrigger > 0) {
      reset();
    }
  }, [resetTrigger]);

  useEffect(() => {
    // programmatically trigger form submit
    if (submitTrigger > 0) {
      handleSubmit(onSubmit)();
    }
  }, [submitTrigger]);

  const onSubmit = (data: FormValues) => {
    onFormSubmit(data);
  };

  return (
    <>
      <FormWrapper<FormValues> onChangeCallback={() => {}} onSubmitCallback={onSubmit} handleSubmit={handleSubmit}>
        <>
          <SslMaskedTextInput control={control} inputName="ssl" label="SSL" />
        </>
      </FormWrapper>
    </>
  );
}
