import { SvgIcon, SvgIconProps } from '@mui/material';

export default function FoldersIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 29 24" fill="none">
      <g fill="none">
        <path
          d="M22.3772 6.75V5.25C22.3772 4.42157 21.6085 3.75 20.6602 3.75H10.3583V2.25C10.3583 1.42157 9.58962 0.75 8.64136 0.75H3.49042C2.54216 0.75 1.77344 1.42157 1.77344 2.25V18.55"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.21094 20.25V8.25C5.21094 7.42157 5.97966 6.75 6.92792 6.75H24.0977C25.046 6.75 25.8147 7.42157 25.8147 8.25V9.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.64062 11.25C8.64062 10.4216 9.3122 9.75 10.1406 9.75H26.0274C26.8558 9.75 27.5274 10.4216 27.5274 11.25V21.75C27.5274 22.5784 26.8558 23.25 26.0274 23.25H10.1406C9.3122 23.25 8.64062 22.5784 8.64062 21.75V11.25Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.375 20.25V17.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6641 17.25H24.098"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0781 14.25H24.097"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0781 17.25H17.2291"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0781 20.25H17.2291"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
