import { create } from 'zustand';

export interface UiStore {
  drawerState: boolean;
  isPublicSection: boolean;
  unreadNotificationCounter: number;
  setDrawerState: (drawerState: boolean) => void;
  setIsPublicSection: (state: boolean) => void;
  setUnreadCount: (count: number) => void;
  toggleDrawerState: () => void;
}

export const useUiStore = create<UiStore>((set) => ({
  drawerState: false,
  isPublicSection: false,
  unreadNotificationCounter: 0,
  setDrawerState: (drawerState: boolean) =>
    set(() => ({ drawerState: drawerState })),
  setUnreadCount: (count: number) =>
    set(() => ({ unreadNotificationCounter: count })),
  toggleDrawerState: () =>
    set((state: UiStore) => ({
      drawerState: state.drawerState ? false : true,
    })),
    setIsPublicSection: (state: boolean) =>
    set(() => ({ isPublicSection: state })),
}));
