import { getLinkToApplication, getPublicLinkToApplication } from 'app-sections/user/applications/steps/config';
import { Link } from 'react-router-dom';
import { Application } from 'types';

import { Box, useTheme } from '@mui/material';

export interface ApplicationDataIdProps {
  item: Application;
  color?: string;
}

export default function ApplicationDataId(props: ApplicationDataIdProps) {
  const theme = useTheme();
  const { item, color = theme.palette.primary.dark } = props;

  return (
    <Box fontSize="1.8rem">
      <Link
        style={{ fontWeight: "bold", color }}
        to={getPublicLinkToApplication(item)}
      >
        {item.state === "draft" && <>Draft {item.id}</>}
        {item.state !== "draft" && <>{item.certificatePermitNumber || item.id || "N/A"}</>}
      </Link>
    </Box>
  );
}
