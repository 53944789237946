import { DIVISIONS, getDivisionNameById } from 'app-sections/common/applications/details/components/sections/status-info/components/divisions-info/helpers';
import { STATE_LABELS } from 'app-sections/common/applications/details/components/sections/summary/components/general-info/helpers';
import { VARIANTS } from 'app-sections/user/applications/steps/step3/config';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ApplicationState, DivisionId, UserProfile } from 'types';
import BasicSelect from 'ui/form-fields/basic-fields/select/BasicSelect';
import BasicTextInput from 'ui/form-fields/basic-fields/text-input/TextInput';
import FormWrapper from 'ui/form-fields/form-wrapper/FormWrapper';
import RangeDatepicker from 'ui/form-fields/range-datepicker/RangeDatepicker';
import { mergeData } from 'ui/form-utils';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Grid } from '@mui/material';

import { DEFAULT_STATES_VALUES } from '../../Applications';
import { ALL_OPT, APP_ASSIGNMENT, defaultValues, FormValues, getValidationSchema, SEARCH_BY_OPTS } from './validation-schema';

function getAppStatuses(params: string[]) {
  return params.map((item) => {
    return {
      id: item,
      name: STATE_LABELS[item as ApplicationState],
    };
  });
}

function getAppAssignments(data: UserProfile[]) {
  return data
    .map((item) => {
      return {
        id: item.userId,
        name: `${item.fullName} (${getDivisionsForUser(item.divisions)})`,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}

function getDivisionsForUser(divisions: DivisionId[]) {
  return divisions
    .map((item) => {
      return getDivisionNameById(item);
    })
    .join(", ");
}

export interface ApplicationSearchFormProps {
  isLoading: boolean;
  onFormSubmit: (values: FormValues) => void;
  onFormChange: (values: FormValues) => void;
  defaults?: FormValues;
  currentParams: any;
  ifReset: number;
  adminProfiles: UserProfile[];
}

export default function ApplicationSearchForm(
  props: ApplicationSearchFormProps
) {
  const {
    onFormSubmit,
    onFormChange,
    isLoading,
    defaults,
    ifReset,
    currentParams,
    adminProfiles,
  } = props;

  const { handleSubmit, control, watch, reset, setValue } = useForm<FormValues>(
    {
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: mergeData(defaultValues, defaults || {}),
      resolver: yupResolver(getValidationSchema()),
    }
  );
  const [resetDatepicker, setResetDatepicker] = useState<number>(0);

  const onSubmit = (data: FormValues) => {
    onFormSubmit(data);
  };

  const handleChange = () => {
    const data = watch();
    onFormChange(data);
  };

  const handleReset = (isSilent: boolean = false) => {
    reset(defaultValues);
    setValue("submittedAtGte", null);
    setValue("submittedAtLte", null);
    setValue("slaDeadlineAtGte", null);
    setValue("slaDeadlineAtLte", null);
    setResetDatepicker((v) => v + 1);
    if (!isSilent) {
      setTimeout(() => {
        handleChange();
      }, 1);
    }
  };

  useEffect(() => {
    if (ifReset > 0) {
      handleReset(true);
    }
  }, [ifReset]);

  const currentStatus = watch("state");

  const appStatusesIds = currentParams?.states || DEFAULT_STATES_VALUES;
  const appStatuses = getAppStatuses(appStatusesIds);
  const tab = currentParams.states.includes("completed")
    ? "closed"
    : "in_progress";

  if (!appStatusesIds.includes(currentStatus) && currentStatus !== "") {
    setTimeout(() => {
      setValue("state", "");
    }, 1);
  }

  return (
    <Card>
      <CardContent>
        <FormWrapper<FormValues>
          onChangeCallback={handleChange}
          onSubmitCallback={onSubmit}
          handleSubmit={handleSubmit}
        >
          <Grid container columnSpacing={4} spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <BasicSelect
                collection={SEARCH_BY_OPTS}
                label="Search by"
                inputName="searchBy"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <BasicTextInput
                label="Search"
                inputName="search"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <BasicSelect
                collection={[ALL_OPT, ...appStatuses]}
                label="Application status"
                inputName="state"
                control={control}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <BasicSelect
                collection={[ALL_OPT, ...VARIANTS]}
                label="Application Type"
                inputName="typeOfApplication"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <BasicSelect
                collection={[
                  ...APP_ASSIGNMENT,
                  ...getAppAssignments(adminProfiles),
                ]}
                label="Assigned To"
                inputName="assignedTo"
                control={control}
              />
            </Grid>
            {tab !== "closed" && (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <BasicSelect
                  collection={[ALL_OPT, ...DIVISIONS]}
                  label="Division"
                  inputName="currentDivision"
                  control={control}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={8}>
              <RangeDatepicker
                control={control}
                dateFromFieldName="submittedAtGte"
                dateFromFieldLabel="Submission Date From"
                dateToFieldName="submittedAtLte"
                dateToFieldLabel="Submission Date To"
                reset={resetDatepicker}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={8}>
              <RangeDatepicker
                control={control}
                dateFromFieldName="slaDeadlineAtGte"
                dateFromFieldLabel="SLA Deadline From"
                dateToFieldName="slaDeadlineAtLte"
                dateToFieldLabel="SLA Deadline To"
                reset={resetDatepicker}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  disabled={isLoading}
                  disableElevation
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </FormWrapper>
      </CardContent>
    </Card>
  );
}
