import { ConfirmationDialog } from "ui/components/confirmation-dialog/ConfirmationDialog";
import InfoMessage from 'ui/components/info-message/InfoMessage';
import FormWrapper from 'ui/form-fields/form-wrapper/FormWrapper';
import FormSectionHeader from 'ui/form-section-header/FormSectionHeader';

import SurveyorData from "app-sections/user/applications/steps/step2/components/registered-surveyor/SurveyorData";

import { acceptSurveyorInvite, rejectSurveyorInvite, updateSurveyor } from "core/api/applications";

import { useAuthStore } from 'core/store/auth';
import { Application } from "types";

import SurveyorsSelect from './SurveyorsSelect';
import { FormValues, validationSchema } from './validation-schema';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { isDcRegisteredSurveyor } from "ui/helpers/users";
import { isDcRegisteredSurveyorDeactivated } from "ui/helpers/users";

import { Review } from 'types';
import { getReviews } from 'core/api/reviews';
import { useQuery } from '@tanstack/react-query';


export interface RegisteredSurveyorFormProps {
  isLoading: boolean;
  application: Application;
}

export default function RegisteredSurveyor(
  props: RegisteredSurveyorFormProps
) {
  const theme = useTheme();
  const statusPending = 'pending';
  const { currentUser } = useAuthStore();
  const queryClient = useQueryClient();
  const [application, setApplication] = useState<Application>(props.application);
  const [edit, setEdit] = useState<boolean>(false || !application.surveyorId);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [surveyorChanged, setSurveyorChanged] = useState<boolean>(false);

  const shouldRenderInvite = () => {
    if (currentUser && currentUser.id && application.clientId === currentUser.id) {
      return edit;
    }
    return false;
  }

  const canEdit = () => {
    if (currentUser && currentUser.id && application.clientId === currentUser.id) {
      return !edit;
    }
    return false;
  }

  const isApplicationCreatedByDcSurveyor = () => {
    return currentUser &&
      (isDcRegisteredSurveyor(currentUser.userProfile.roles) || isDcRegisteredSurveyorDeactivated(currentUser.userProfile.roles)) &&
      application.surveyorId === currentUser.id;
  }

  const shouldRenderReply = () => {
    if (application.surveyorInviteStatus === statusPending && (currentUser && currentUser.id && application.surveyorId === currentUser.id)) {
      return true;
    }
    return false;
  }

  const { handleSubmit, getValues, setValue } = useForm<FormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      surveyorId: application.surveyorId,
    },
  });

  const updateSurveyorMutation = useMutation({
    mutationFn: (data: FormValues) => updateSurveyor(application.id, data.surveyorId),
    onSuccess: (responseData) => {
      setApplication(responseData);
      setEdit(false);
    },
  });

  const handleUpdateSurveyorSubmit = () => {
    const data = getValues();
    updateSurveyorMutation.mutate(data);
    setDialogState(false)
  };

  const acceptSurveyorInviteMutation = useMutation({
    mutationFn: () => acceptSurveyorInvite(application.id),
    onSuccess: (responseData) => {
      setApplication(responseData);
      queryClient.invalidateQueries({ queryKey:["applications", application.id] });
    },
  });

  const handleAcceptSurveyorInviteSubmit = () => {
    acceptSurveyorInviteMutation.mutate();
  };

  const rejectSurveyorInviteMutation = useMutation({
    mutationFn: () => rejectSurveyorInvite(application.id),
    onSuccess: (responseData) => {
      setApplication(responseData);
    },
  });

  const handleRejectSurveyorInviteSubmit = () => {
    rejectSurveyorInviteMutation.mutate();
  };

  const { data: reviewsData } = useQuery({
    enabled: Boolean(application?.id) && application?.id !== "",
    queryKey: ["applications", application?.id || "", "reviews"],
    queryFn: () => getReviews<Review[]>(application?.id || ""),
  });
  const noDocumentsUploaded = reviewsData && reviewsData?.length > 0 && reviewsData.at(-1)?.state  && reviewsData.at(-1)?.state === 'pending';

  return (
    <>
      {!shouldRenderReply() && application.surveyorId && (
        <FormSectionHeader
          title="DC Registered Surveyor"
          subtitle="The DC Registered Surveyor assigned to this application will be authorized to take action on behalf of the applicant."
        />
      )}

      {!shouldRenderReply() && !application.surveyorId && (
        <FormSectionHeader
          title="DC Registered surveyor"
          subtitle="Select and invite DC Registered Surveyor from the list to the application who will be authorized to take action on behalf of the applicant."
        />
      )}

      {shouldRenderReply() && (
        <FormSectionHeader
          title="DC Registered surveyor"
          subtitle="You are invited as the DC Registered Surveyor assigned to this application and will be authorized to upload the necessary documents for the DOB review."
        />
      )}

      {application.surveyorInviteStatus === "accepted" &&
        application.clientId === currentUser?.id &&
        application.surveyorId !== currentUser?.id && (
          <InfoMessage
            type="success"
            notClosable={true}
            isSlim={true}
            title=""
            content="Your invitation has been accepted by the DC Registered Surveyor. However, you may still click on Edit to select another available DC Registered Surveyor until the necessary documents have been uploaded for review."
          />
        )}
      {application.surveyorInviteStatus === "rejected" &&
        application.clientId === currentUser?.id &&
        application.surveyorId !== currentUser?.id &&  (
        <InfoMessage
          type="error"
          notClosable={true}
          isSlim={true}
          title=""
          content="Your invitation has been denied by the DC Registered Surveyor, please click on Edit to check the list for another available DC Registered Surveyor. Only DC registered surveyors can upload the necessary documents for review."
        />
      )}

      {application.surveyorInviteStatus === "pending" &&
        application.surveyorId &&
        !shouldRenderReply() && (
          <InfoMessage
            type="neutral"
            notClosable={true}
            isSlim={true}
            title=""
            content="Your invitation to DC Registered Surveyor is awaiting acceptance. You can click on Edit to select another available DC Registered Surveyor."
          />
        )}

      {!shouldRenderReply() &&
        !application.surveyorId && (
          <InfoMessage
            type="neutral"
            notClosable={true}
            isSlim={true}
            title=""
            content="If you already know who the DC Registered Surveyor is on your application, select their name from the dropdown, but make sure you only select a surveyor if you already contracted with one of them. If  you have not done it yet, please contact them first via phone. "
          />
        )}

      <FormWrapper<FormValues>
        onChangeCallback={() => {}}
        onSubmitCallback={() => {}}
        handleSubmit={handleSubmit}
      >
        <Card>
          {shouldRenderInvite() && (
            <>
              <CardContent>
                <SurveyorsSelect
                  defaultId={application.surveyorId || ""}
                  onPick={(id: string) => {
                    setValue("surveyorId", id);
                    if (id !== application.surveyorId) {
                      setSurveyorChanged(true);
                    } else {
                      setSurveyorChanged(false);
                    }
                  }}
                />
              </CardContent>
            </>
          )}
          <Box
            p={2}
            sx={
              shouldRenderReply()
                ? {
                    borderRadius: "10px",
                    borderColor: theme.palette.warning.main,
                    backgroundColor: theme.palette.warning.light,
                    borderStyle: "solid",
                    borderWidth: "1px",
                    padding: "24px",
                  }
                : {}
            }
          >
            {shouldRenderReply() && (
              <>
                <Typography component="h3" variant="h4">
                  DC Registered Surveyor Information
                </Typography>
                <Box mt={2} mb={2}>
                  <Typography component="h6" variant="subtitle2">
                  Please accept or decline the invitation below. 
                  Once accepted, you will be required to upload the 
                  Wall Check related documents to this application.
                  </Typography>
                </Box>
              </>
            )}

            {application.surveyorId && (
              <>
                <SurveyorData
                  surveyor={application.surveyor}
                  status={
                    application.clientId === currentUser?.id || application.surveyorId === currentUser?.id
                      ? application.surveyorInviteStatus
                      : undefined
                  }
                />
                {canEdit() && noDocumentsUploaded && !isApplicationCreatedByDcSurveyor() && (
                  <Box display={"flex"} justifyContent="flex-end">
                    <Button
                      sx={{ m: 1 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => setEdit(true)}
                    >
                      Edit
                    </Button>
                  </Box>
                )}
              </>
            )}
            <Box display={"flex"} justifyContent="flex-end">
              {shouldRenderInvite() && (
                <>
                  {edit && application.surveyorId && (
                    <Button
                      sx={{ m: 1 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => setEdit(false)}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    sx={{ m: 1 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      setDialogState(true);
                    }}
                    disabled={!surveyorChanged}
                  >
                    {" "}
                    {edit ? (
                      <span>Save and send invitation</span>
                    ) : (
                      <span>Send invitation</span>
                    )}
                  </Button>
                </>
              )}
              {shouldRenderReply() && (
                <>
                  <Button
                    sx={{ m: 1 }}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={handleRejectSurveyorInviteSubmit}
                  >
                    Decline
                  </Button>
                  <Button
                    sx={{ m: 1 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleAcceptSurveyorInviteSubmit}
                  >
                    Accept
                  </Button>
                </>
              )}
            </Box>
          </Box>
          <ConfirmationDialog
            title="Invite new surveyor"
            description={
              <Box mb={2}>
                You are about to invite a new DC Registered Surveyor to this application.
                The previously selected surveyor will be notified about your decision and
                won’t be able to access this application afterwards. 
                Are you sure you want to proceed?
              </Box>
            }
            opened={dialogState}
            confirmButtonVariant="contained"
            onConfirm={() => {
              handleUpdateSurveyorSubmit();
            }}
            onCancel={() => {
              setDialogState(false);
            }}
          />
        </Card>
      </FormWrapper>
    </>
  );
};
