import { getAddressNumberValidation } from 'ui/form-validations/addressNumberValidation';
import * as Yup from 'yup';

export interface FormValues {
  streetNumber: string;
  streetNumberFraction: string;
  streetName: string;
  streetNameSuffix: string;
  unitNumber: string;
  quadrant: string;
  city: string;
  zip: string;
  state: string;
}

export const defaultValues: FormValues = {
  streetName: '',
  streetNumber: '',
  streetNumberFraction: '',
  streetNameSuffix: '',
  unitNumber: '',
  quadrant: '',
  zip: '',
  state: '',
  city: '',
};

const detailsFields = {
  streetName: Yup.string().trim().required('Field is required'),
  streetNumber: getAddressNumberValidation().required('Field is required'),
  unitNumber: getAddressNumberValidation(),
  streetNameSuffix: Yup.string().trim().required('Field is required'),
  quadrant: Yup.string().trim().required('Field is required'),
};

export const getValidationSchema = () => {
  return Yup.object({
    ...detailsFields,
  });
};
