import { Box, useTheme } from '@mui/material';
import { parseTime } from 'ui/helpers/parseDate';
import CheckSuccessIcon from 'ui/icons/CheckSuccessIcon';
import CircleSmallIcon from 'ui/icons/CircleSmallIcon';

export interface DivisionDatesProps {
  label: string;
  startedOn?: string ;
  completedOn?: string;
  completed: boolean;
}

export default function DivisionDates(props: DivisionDatesProps) {
  const { label, startedOn, completedOn, completed,  } = props;
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          fontSize: "2rem",
          fontWeight: "bold",
          lineHeight: 1.3,
          paddingTop: 1,
        }}
      >
        {completed
          ?
            <CheckSuccessIcon sx={{ fontSize: "2rem", color: theme.palette.success.main, marginRight: 1 }}/>
          :
            <CircleSmallIcon sx={{ fontSize: "2rem", color: theme.palette.success.main, marginRight: 1 }}/>
        }
        {label}
      </Box>
      <Box
        sx={{
          fontSize: "2rem",
          lineHeight: 1.5,
        }}
      >Started on - {startedOn && parseTime(startedOn)}</Box>
      <Box
        sx={{
          fontSize: "2rem",
          lineHeight: 1.5,
        }}
      >
        Completed on - {completedOn && parseTime(completedOn)}
      </Box>
    </>
  );
}
