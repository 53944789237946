import { Application, Params, ReviewSectionStateId, ReviewTabSectionId } from 'types';

export const APP_DETAILS_NAV_TABS: Params[] = [
  {
    id: "summary",
    name: "Summary",
  },
  {
    id: "review-details",
    name: "Review",
  },
];

export const APP_DETAILS_REVIEWS_NAV_TABS: {
  id: ReviewTabSectionId;
  name: string;
  sectionStateId?: ReviewSectionStateId;
}[] = [
  {
    id: "surveyorsOffice",
    name: "Surveyor’s Office",
    sectionStateId: "surveyorsOfficeState",
  },
  {
    id: "zoning",
    name: "Zoning",
    sectionStateId: "zoningState",
  }
];

export const STEPS: Params[] = [
  { name: "Permits", url: "1" },
  { name: "Authorization Form", url: "2" },
  { name: "Payment", url: "3" }
];

export const getSectionNameById = (id: string): string => {
  return APP_DETAILS_REVIEWS_NAV_TABS.find((item) => item.id === id)?.name || "";
};

export const getLinkToApplication = (item: Application): string => {
  if (item.state !== "draft") {
    return getLinkToDetails(item.id, APP_DETAILS_NAV_TABS[0].id);
  }
  return getLinkToStep(item.id, item.currentStep + 1);
};

export const getPublicLinkToApplication = (item: Application): string => {
  return `/public/applications/${item.id}/details/summary`;
};

export const getLinkToStep = (applicationId: string, step: number): string => {
  return `/applications/${applicationId}/step/${step}`;
};

export const getLinkToDetails = (
  applicationId: string,
  section: string
): string => {
  return `/applications/${applicationId}/details/${section}`;
};

export const getLinkToReviewDetails = (
  applicationId: string,
  section: string
): string => {
  return `/applications/${applicationId}/details/${APP_DETAILS_NAV_TABS[1].id}/${section}`;
};
