import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import SwoInfo from './SwoInfo';

export default function SwoWrapper() {
  const { activeApplicationId, hasSwoError, setSwoError } = useApplicationsStore();

  const applicationQueryKey = ['applications', activeApplicationId];

  const { isSuccess, data } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== '',
  });

  useEffect(() => {
    if (isSuccess && data) {
      setSwoError(Boolean(data?.swoSetAt));
    }
  }, [isSuccess]);

  if (hasSwoError) {
    return <SwoInfo data={data} />;
  }
  return null;
}
