import { Fragment } from 'react';

import { Box, Grid, useTheme } from '@mui/material';

export interface SummaryTextGridItem {
  label: string;
  desc: string | JSX.Element;
  color?: string;
}

export interface SummaryTextGridProps {
  items: SummaryTextGridItem[];
  firstColumnWidth?: number;
  color?: string;
}

export default function SummaryTextGrid(props: SummaryTextGridProps) {
  const theme = useTheme();
  const { items, firstColumnWidth = 4, color = theme.palette.primary.main } = props;

  const secondColumnWidth = 12 - firstColumnWidth;

  return (
    <Grid container spacing={1} sx={{ mb: 3 }}>
      {items.map((item, index) => (
        <Fragment key={index}>
          <Grid
            item
            xs={12}
            sm={12}
            md={firstColumnWidth}
            lg={firstColumnWidth}
          >
            <Box fontSize="2rem">{item.label}</Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={secondColumnWidth}
            lg={secondColumnWidth}
          >
            <Box display="flex" minWidth={2}>
              <Box fontSize="2rem" display={{xs: "none", md: "block"}} mr={{xs: 0, md: 4}}>-&nbsp;</Box>
              {(typeof item.desc === "string" ||
                typeof item.desc === "number") && (
                <Box
                  component="strong"
                  fontSize="2rem"
                  color={item.color || color}
                  sx={{
                    wordWrap: "break-word",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  {item.desc}
                </Box>
              )}
              {typeof item.desc !== "string" &&
                typeof item.desc !== "number" && <>{item.desc}</>}
            </Box>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}
