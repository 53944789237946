import { fileUrlForDocument } from 'core/api/documents';
import FileTypeImageIcon from 'ui/icons/FileTypeImageIcon';

import { Box, Button, IconButton, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

import FileTypePdfIcon from '../../icons/FileTypePdfIcon';
import TrashIcon from '../../icons/TrashIcon';
import { FileInfo } from '../multi-file-uploader/MultiFileUploader';

export interface FileItemProps {
  item?: FileInfo;
  remove?: (id: string) => void;
  isLoading?: boolean;
  canRemoveFiles?: boolean;
  showIcon?: boolean;
}

const isImage = (name: string | undefined): boolean => {
  if (!name) {
    return false;
  }
  return name?.split('.').pop() !== 'pdf';
};

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    link: {
      color: `${theme.palette.primary.dark} !important`,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  };
};

export default function FileItem(props: FileItemProps) {
  const { remove, item, isLoading, canRemoveFiles = true, showIcon = true } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const styles = getStyles(theme);

  if (!item) {
    return null;
  }

  return (
    <Box display="flex" width="100%" alignItems="center" key={item.id} mb={2}>
      {showIcon && (
        <Box display="flex" mr={1.5} color={theme.palette.primary.dark}>
          {isImage(item?.name) && <FileTypeImageIcon />}
          {!isImage(item?.name) && <FileTypePdfIcon />}
        </Box>
      )}
      <Box display="flex" flexGrow="1" overflow="hidden" textOverflow="ellipsis" mr={1.5} fontSize="2rem">
        {item.name && item.url && (
          <Box
            component="a"
            rel="noopener noreferrer"
            target="_blank"
            href={fileUrlForDocument(item.documentId)}
            download
            sx={styles.link}
          >
            {item.name}
          </Box>
        )}

        {item.name && !item.url && <Box>{item.name}</Box>}
      </Box>
      {remove && canRemoveFiles && (
        <>
          {matches && (
            <Button
              variant="outlined"
              className="error"
              disabled={isLoading}
              onClick={() => remove(item.documentId || item.uppyId)}
            >
              Delete
            </Button>
          )}
          {!matches && (
            <IconButton
              aria-label="Delete"
              className="error"
              disabled={isLoading}
              onClick={() => remove(item.documentId || item.uppyId)}
            >
              <TrashIcon />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
}
