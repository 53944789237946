import ApplicationRemainingTimeCounter from 'app-sections/common/applications/details/components/sections/summary/components/remaining-time-counter/ApplicationRemainingTimeCounter';
import { useAuthStore } from 'core/store/auth';
import { Application, ApplicationState } from 'types';
import SummaryTextGrid from 'ui/components/summary-text-grid/SummaryTextGrid';
import { isCompleted, isCompletedByFullStatus } from 'ui/helpers/applications';
import { parseDate, UI_DATE_FORMAT_STRING } from 'ui/helpers/parseDate';

import { Box, Divider, Typography, useTheme } from '@mui/material';

export interface MyReviewDetailsProps {
  application: Application;
  divisionType: 'zoning' | 'surveyor_office';
}

const DIVISIONS_MAP = {
  zoning: 'Zoning',
  surveyor_office: 'Surveyor’s Office',
};

const reviewStatus = (
  divisionType: 'zoning' | 'surveyor_office',
  state: ApplicationState
) => {
  switch (state) {
    case 'draft':
    case 'pending_payment':
    case 'paid':
    case 'pending':
      return 'Not started';
    case 'surveyor_office_review':
      return divisionType === 'surveyor_office'
        ? 'Review in Progress'
        : 'Not started';
    case 'zoning_review':
      return divisionType === 'zoning' ? 'Review in Progress' : 'Completed';
    case 'completed':
    case 'completed_below_grade':
    case 'completed_partially':
    case 'denied':
      return 'Completed';
    case 'client_action_needed':
      return 'Hold for correction';
    case 'swo':
      return 'SWO';
    case 'deleted_by_client':
      return 'Cancelled';
  }
};

export default function MyReviewDetails(props: MyReviewDetailsProps) {
  const { application, divisionType } = props;

  const theme = useTheme();
  const { currentUser } = useAuthStore();

  const divisionName = DIVISIONS_MAP[divisionType];
  const surveyorOfficeStartedOn = application.statuses.find(
    (status) =>
      status.name === 'app.statuses.applications.surveyor_office_review'
  )?.createdAt;
  const zoningOfficeStartedOn = application.statuses.find(
    (status) => status.name === 'app.statuses.applications.zoning_review'
  )?.createdAt;
  const surveyorOfficeCompletedOn = zoningOfficeStartedOn;
  const zoningOfficeCompletedOn = application.statuses.find((status) =>
    isCompletedByFullStatus(status)
  )?.createdAt;
  const startDate =
    divisionType === 'surveyor_office'
      ? surveyorOfficeStartedOn
      : zoningOfficeStartedOn;
  const endDate =
    divisionType === 'surveyor_office'
      ? surveyorOfficeCompletedOn
      : zoningOfficeCompletedOn;
  const applicationCompleted = isCompleted(application);
  const reviewDetailsData: {
    label: string;
    desc: string | JSX.Element;
    color?: string;
  }[] = [
    {
      label: 'Reviewer',
      desc: currentUser ? currentUser?.userProfile.fullName : '',
    },
    { label: 'Division', desc: divisionName },
    {
      label: 'Review start date',
      desc: startDate ? parseDate(startDate, UI_DATE_FORMAT_STRING) : '-',
    },
    {
      label: 'Review end date',
      desc: endDate ? parseDate(endDate, UI_DATE_FORMAT_STRING) : '-',
    },
    {
      label: 'Review status',
      desc: reviewStatus(divisionType, application.state),
      color: theme.palette.primary.main,
    },
    {
      label: 'Remaining divisional time (SLA)',
      desc: (
        <Box
          component="strong"
          fontSize="2rem"
          color={theme.palette.text.primary}
          sx={{ wordWrap: 'break-word', display: 'block', maxWidth: '100%' }}
        >
          {!applicationCompleted && application.slaDeadlineAt ? (
            <ApplicationRemainingTimeCounter
              application={application}
              onExpiration={() => {}}
            />
          ) : (
            '-'
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Typography component="h3" variant="h4" sx={{ mb: 2 }}>
        My review details
      </Typography>
      <SummaryTextGrid
        items={reviewDetailsData}
        color={theme.palette.text.primary}
      />

      <Box my={3}>
        <Divider />
      </Box>
    </>
  );
}
