import { SvgIcon, SvgIconProps } from "@mui/material";

export default function InstaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <g fill="none">
        <rect
          x="0.73999"
          y="0.75"
          width="22.5"
          height="22.5"
          rx="6"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.99 17C14.7514 17 16.99 14.7614 16.99 12C16.99 9.23858 14.7514 7 11.99 7C9.22857 7 6.98999 9.23858 6.98999 12C6.98999 14.7614 9.22857 17 11.99 17Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.34 5.15039C19.34 5.42653 19.1161 5.65039 18.84 5.65039C18.5638 5.65039 18.34 5.42653 18.34 5.15039"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.34 5.15039C18.34 4.87425 18.5638 4.65039 18.84 4.65039C19.1161 4.65039 19.34 4.87425 19.34 5.15039"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
