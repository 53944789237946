import React, { useEffect, useState } from 'react';

import { Alert, AlertColor, Snackbar } from '@mui/material';

export interface CommonSuccessNotificationProps {
  isOpened: number | boolean;
  mainText: string;
  autoHide?: number | null;
  severity: AlertColor | undefined;
}

export default function CommonSuccessNotification(
  props: CommonSuccessNotificationProps
) {
  const { isOpened, mainText, autoHide = 3000, severity } = props;

  const [open, setOpen] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (Boolean(isOpened)) {
      setOpen(true);
    }
  }, [isOpened]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {mainText}
      </Alert>
    </Snackbar>
  );
}
