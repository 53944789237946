import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import AdminProfilesIcon from "ui/icons/AdminProfilesIcon.";
import AppWindowUser from "ui/icons/AppWindowUser";
import BookingsIcon from "ui/icons/BookingsIcon";
import DashboardIcon from "ui/icons/DashboardIcon";
import ProfileIcon from "ui/icons/ProfileIcon";

import { INavigationElement } from "../NavigationElement";

export const getAdminNavigation = (id: string): INavigationElement[] => [
  {
    id: "dashboard",
    name: "Dashboard",
    url: "/admin",
    path: "/admin",
    icon: <DashboardIcon />,
    disabled: false,
    show: true,
  },
  {
    id: "client-projects",
    name: "Applications",
    url: "/admin/applications",
    path: "/admin/applications",
    icon: <BookingsIcon />,
    disabled: false,
    show: true,
  },
  {
    id: "admin-management",
    name: "Reviewers",
    url: "/admin/admin-profiles",
    path: "/admin/admin-profiles",
    icon: <AdminProfilesIcon />,
    disabled: false,
    show: true,
  },
  {
    id: "registered-surveyor-management",
    name: "DC Registered Surveyors",
    url: `/admin/registered-surveyor-profiles`,
    path: `/admin/registered-surveyor-profiles`,
    icon: <AppWindowUser />,
    disabled: false,
    show: true,
  },
  {
    id: "admin-profile",
    name: "Profile",
    url: `/admin/admin-profiles/${id}/edit`,
    path: `/admin/admin-profiles/${id}/edit`,
    icon: <ProfileIcon />,
    disabled: false,
    show: true,
  },
  {
    id: "reports",
    name: "Reports",
    url: `/admin/reports`,
    path: `/admin/reports`,
    icon: <SummarizeOutlinedIcon />,
    disabled: false,
    show: true,
  },
  // {
  //   id: "settings",
  //   name: "Settings",
  //   url: "/settings",
  //   path: "/settings",
  //   icon: <SettingsIcon />,
  //   disabled: false,
  //   show: true,
  //   settings: true,
  // },
];
