import * as Yup from 'yup';
import { ApplicationState } from '../../../../../../types.d';

export interface FormValues {
  state: ApplicationState | "";
  searchByAddress: string | null;
  searchByPermitNumber: string | null;
}

export const defaultValues: FormValues = {
  state: "",
  searchByAddress: "",
  searchByPermitNumber: "",
};

const detailsFields = {};

export const getValidationSchema = () => {
  return Yup.object({
    ...detailsFields,
  });
};
