import { APP_VER, BASE_PATH } from 'core/config';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import rg4js from 'raygun4js';
import { RouterProvider } from 'react-router-dom';

import { NoSsr } from '@mui/base';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { router } from './core/routes/Routes';
import mainTheme from './core/theme/mainTheme';
import { isRaygunEnabled, raygunKey } from 'core/errors/errors.lib';

if (isRaygunEnabled()) {
  rg4js('apiKey', raygunKey());
  rg4js('enableCrashReporting', true);
  rg4js("setVersion", APP_VER);
}

TimeAgo.addDefaultLocale(en);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 0,
    },
  },
});

if (!window.location.pathname.includes(BASE_PATH)) {
  window.location.replace("/app/home");
}

function App() {
  return (
    <NoSsr>
      <ThemeProvider theme={mainTheme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <RouterProvider router={router}></RouterProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </NoSsr>
  );
}

export default App;
