import Error404Page from 'core/layouts/error-pages/Error404Page';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

import SidebarLayout from '../../layouts/components/sidebar-layout/SidebarLayout';
import ErrorPage from '../../layouts/error-pages/ErrorPage';
import MustBeLoggedIn from '../protected-routes/must-be-logged-in/MustBeLoggedIn';
import { applicationRoutes } from './applicationRoutes';

const Dashboard = lazy(() => import("app-sections/admin/dashboard/Dashboard"));
const Applications = lazy(
  () => import("app-sections/admin/applications/Applications")
);
const MyTasks = lazy(
  () => import("app-sections/admin/dashboard/components/my-tasks/MyTasks")
);
const EditAdmin = lazy(
  () => import("app-sections/admin/admin-profiles/edit-admin/EditAdmin")
);
const CreateAdmin = lazy(
  () => import("app-sections/admin/admin-profiles/create-admin/CreateAdmin")
);
const AdminProfiles = lazy(
  () => import("app-sections/admin/admin-profiles/AdminProfiles")
);
const EditRegisteredSurveyor = lazy(
  () => import("app-sections/admin/registered-surveyor-profiles/edit-registered-surveyor/EditRegisteredSurveyor")
);
const CreateRegisteredSurveyor = lazy(
  () => import("app-sections/admin/registered-surveyor-profiles/create-registered-surveyor/CreateRegisteredSurveyor")
);
const RegisteredSurveyorProfiles = lazy(
  () => import("app-sections/admin/registered-surveyor-profiles/RegisteredSurveyorProfiles")
);


const TeamAssignments = lazy(
  () =>
    import(
      "app-sections/admin/dashboard/components/team-assignments/TeamAssignments"
    )
);

const Notifications = lazy(
  () => import("app-sections/common/notifications/Notifications")
);

const Reports = lazy(() => import("app-sections/admin/reports/Reports"));

export const adminGeneralRoutes: RouteObject[] = [
  {
    path: "/admin",
    element: (
      <MustBeLoggedIn role="admin">
        <SidebarLayout />
      </MustBeLoggedIn>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Dashboard />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<DataLoader />}>
                <TeamAssignments />
              </Suspense>
            ),
          },

          {
            path: "my-tasks",
            element: (
              <Suspense fallback={<DataLoader />}>
                <MyTasks />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "applications",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Applications />
          </Suspense>
        ),
      },
      {
        path: "applications/:id",
        children: applicationRoutes,
      },
      {
        path: "admin-profiles",
        element: (
          <Suspense fallback={<DataLoader />}>
            <AdminProfiles />
          </Suspense>
        ),
      },
      {
        path: "admin-profiles/add-new",
        element: (
          <Suspense fallback={<DataLoader />}>
            <CreateAdmin />
          </Suspense>
        ),
      },
      {
        path: "admin-profiles/:id/edit",
        element: (
          <Suspense fallback={<DataLoader />}>
            <EditAdmin />
          </Suspense>
        ),
      },
      {
        path: "registered-surveyor-profiles",
        element: (
          <Suspense fallback={<DataLoader />}>
            <RegisteredSurveyorProfiles />
          </Suspense>
        ),
      },
      {
        path: "registered-surveyor-profiles/add-new",
        element: (
          <Suspense fallback={<DataLoader />}>
            <CreateRegisteredSurveyor />
          </Suspense>
        ),
      },
      {
        path: "registered-surveyor-profiles/:id/edit",
        element: (
          <Suspense fallback={<DataLoader />}>
            <EditRegisteredSurveyor />
          </Suspense>
        ),
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Notifications />
          </Suspense>
        ),
      },
      {
        path: "reports",
        element: (
          <Suspense fallback={<DataLoader />}>
            <Reports />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/404",
    element: <Error404Page />,
  },
];
