import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import Error404Page from './Error404Page';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  if (isRouteErrorResponse(error)) {
    return <Error404Page />;
  }

  return <p>{"Unknown Error"}</p>;
}
