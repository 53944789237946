import Error404Page from 'core/layouts/error-pages/Error404Page';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

const ReviewDetailsTabsContent = lazy(
  () =>
    import(
      "app-sections/user/applications/details/components/sections/review-details/components/review-details-tabs-content/ReviewDetailsTabsContent"
    )
);

export const applicationDetailsReviewRoutes: RouteObject[] = [
  {
    path: "",
    element: <Navigate replace to="surveyorsOffice" />,
  },
  {
    path: "surveyorsOffice",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetailsTabsContent type="surveyorsOffice" />
      </Suspense>
    ),
  },
  {
    path: "zoning",
    element: (
      <Suspense fallback={<DataLoader />}>
        <ReviewDetailsTabsContent type="zoning" />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: <Error404Page />,
  },
  {
    path: ":any/*",
    element: <Error404Page />,
  },
];
