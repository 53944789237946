import { forwardRef } from 'react';

export const ExternalButton = forwardRef<HTMLInputElement, any>(
  (props, ref) => (
    <a
      {...props}
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
      ref={ref}
    >
      {props.children}
    </a>
  )
);
