import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

export const TextMaskCustom = forwardRef(function TextMaskCustom(
  props: any,
  ref: any
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value: any) =>
        onChange({ target: { name: props?.name || {}, value } })
      }
      overwrite
    />
  );
});