import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import LinesSkeleton from 'ui/components/skeletons/lines-skeleton/LinesSkeleton';
import FormSectionHeader from 'ui/form-section-header/FormSectionHeader';
import { Box, Card, CardContent, Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import Step3Content from './Step3Content';
import PaymentStatusInfo from 'app-sections/common/applications/details/components/sections/payment/payment-status-info/PaymentStatusInfo';

export const PAYMENT_STEP = 3;
export default function Step3() {
  const { setActiveStep } = useApplicationsStore();
  useEffect(() => {
    setActiveStep(2);
  }, [setActiveStep]);

  const { id: activeApplicationId = "" } = useParams();

  const applicationQueryKey = ["applications", activeApplicationId];

  const { isLoading, isError, data: applicationData, error, isSuccess } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== "",
  });

  const [searchParams] = useSearchParams();
  const checkPaymentStatus = searchParams.get('payment_status') === "check";

  return (
    <Box mt={5}>
      <Container className="disable-center">
        <>
          {applicationData && applicationData.paymentState && (applicationData.paymentState !== "new" || checkPaymentStatus ) &&
            <PaymentStatusInfo status={applicationData.paymentState} applicationId={applicationData.id} />
          }

          <FormSectionHeader title="Summary" />
          {!isLoading && isSuccess && <Step3Content applicationData={applicationData} />}
          {isLoading && !isError && (
            <Card>
              <CardContent>
                <LinesSkeleton elements={3} isWithButtons={false} />
              </CardContent>
            </Card>
          )}
          {isError && error && (
            <ErrorsList
              title="Unexpected loading error"
              errors={error as string[]}
            />
          )}
        </>
      </Container>
    </Box>
  );
}
