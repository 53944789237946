import { Link } from 'react-router-dom';
import ArrowLeftIcon from 'ui/icons/ArrowLeftIcon';

import { Box, Button } from '@mui/material';

export interface BackButtonProps {
  isLoading: boolean;
  backButtonLink: string;
  backButtonLabel?: string;
  matches?: boolean;
}

export default function BackButton(props: BackButtonProps) {
  const {
    isLoading,
    backButtonLabel = "Back",
    backButtonLink,
    matches
  } = props;

  return (
    <Box mx={1}>
      <Button
        sx={matches ? { width: "100%", mb: 2 } : {}}
        type="button"
        variant="text"
        color="primary"
        disabled={isLoading}
        disableElevation
        component={Link}
        to={backButtonLink}
      >
        <Box display="flex" mr={1}>
          <ArrowLeftIcon sx={{ fontSize: "2rem" }} />
        </Box>
        {backButtonLabel}
      </Button>
    </Box>
  );
}
