import { Params } from 'types';

export const APP_FILTER_TABS: Params[] = [
  {
    id: "draft",
    name: "Drafts",
  },
  {
    id: "pending",
    name: "Pending",
  },
  {
    id: "in-progress",
    name: "In Progress",
  },
  {
    id: "closed",
    name: "Closed",
  },
];

export const getIndexById = (id: string, tabs: Params[]): number => {
  return tabs.findIndex((item) => id === item.id);
};

export const getNameById = (id: string): string => {
  return APP_FILTER_TABS.find((item) => id === item.id)?.name || "";
};
