import { Box, Card, Divider, Typography, useTheme } from '@mui/material';
import { get } from 'lodash-es';
import { Application } from 'types';
import SummaryTextGrid from 'ui/components/summary-text-grid/SummaryTextGrid';

export interface QuestionAnswerProps {
  data: Application;
  title: string;
  questionField: keyof Application;
  questionText: string;
  acknowledgementField?: keyof Application;
  acknowledgementStatus?: boolean;
  acknowledgementText: string;
  acknowledgementExtraText?: string;
  acknowledgementText2?: string;
  acknowledgementExtraText2?: string;
}

export default function QuestionAnswer(props: QuestionAnswerProps) {
  const { data, title, questionField, questionText, acknowledgementField, acknowledgementStatus, acknowledgementText, acknowledgementExtraText, acknowledgementText2, acknowledgementExtraText2 } = props;
  const theme = useTheme();

  const isQuestionAccepted = get(data, questionField);
  let isAccepted: boolean;
  if (acknowledgementField) {
    isAccepted = get(data, acknowledgementField);
  } else {
    isAccepted = acknowledgementStatus || false;
  }

  return (
    <Box mb={4}>
      <Typography component="h4" variant="h4" sx={{ mb: 1, fontWeight: "bold", color: theme.palette.grey[700] }}>
        { title }
      </Typography>
      <Divider sx={{mb: 2, borderBottomWidth: "1px", borderColor: "#213A6D"  }}/>
      <SummaryTextGrid
        firstColumnWidth={9.5}
        items={[
          {
            label: questionText,
            desc: isQuestionAccepted ? 'Yes' : 'No'
          }
        ]}
      />

      { isQuestionAccepted && isAccepted && !!acknowledgementExtraText && (
        <p>
          <Typography component="h4" variant="h4" sx={{ textTransform: "none" }}>
            { acknowledgementExtraText }
          </Typography>
        </p>
      )}
      { isQuestionAccepted && isAccepted && acknowledgementText.length > 0 && (
        <Card
          className="h-full no-shadow"
          sx={{background: theme.palette.grey[200], minHeight: "108px",}}
        >
          <Box p={3}>
            <Typography component="h3" variant="h3" sx={{ mb: 3, textTransform: "none" }}>
              Acknowledgement
            </Typography>
            <p>
              {acknowledgementText}
            </p>
          </Box>
        </Card>
      )}

      { isQuestionAccepted && isAccepted && !!acknowledgementExtraText2 && (
        <p>
          <Typography component="h4" variant="h4" sx={{ textTransform: "none" }}>
            { acknowledgementExtraText2 }
          </Typography>
        </p>
      )}
      { isQuestionAccepted && isAccepted && acknowledgementText2 && (
        <Card
          className="h-full no-shadow"
          sx={{background: theme.palette.grey[200], minHeight: "108px",}}
        >
          <Box p={3}>
            <Typography component="h3" variant="h3" sx={{ mb: 3, textTransform: "none" }}>
              Acknowledgement
            </Typography>
            <p>
              {acknowledgementText2}
            </p>
          </Box>
        </Card>
      )}
    </Box>
  );
}
