import consumer from 'core/websockets';
import { useEffect, useState } from 'react';
import { WebsocketMessage } from 'types';

let connection: any = null;

export function useWebSockets(channel: string, roomId: string) {
  const [message, setMessage] = useState<WebsocketMessage | null>(null);

  useEffect(() => {
    if (connection) {
      connection.unsubscribe();
    }

    connection = consumer.subscriptions.create(
      {
        channel,
        room_id: roomId,
      },
      {
        received: function (data: any) {
          // function responsible for handling new message
          // console.log(data);
          setMessage(data);
        },
        connected: function () {
          // console.log("connected");
        },
      }
    );

    // console.log(`connection created for ${channel} ${roomId}`);
    // console.log(connection);

    return () => {
      if (!connection) {
        connection.unsubscribe();
      }
    };
  }, [channel, roomId]);

  return message;
}
