import { useUiStore } from 'core/store/ui';

import { SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import NavigationElement, { INavigationElement } from './NavigationElement';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(5),
      "& .main-panel": {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      },
      "& .settings-panel": {
        position: "relative",
        "&:before": {
          position: "absolute",
          top: 0,
          left: 0,
          content: '""',
          display: "block",
          width: `calc(100% - ${theme.spacing(3)}px)`,
          height: 1,
          background: theme.palette.divider,
        },
      },
    },
  };
};

interface NavigationProps {
  navigation: Array<INavigationElement>;
}
export default function Navigation(props: NavigationProps) {
  const { toggleDrawerState } = useUiStore();
  const theme = useTheme();
  const styles = getStyles(theme);

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { navigation } = props;

  const settings = navigation.filter((item: INavigationElement) => {
    return item.show && item.settings;
  });

  const nav = navigation.filter((item: INavigationElement) => {
    return item.show && !item.settings;
  });

  return (
    <Box sx={styles.root} onClick={() => matches && toggleDrawerState()}>
      <div className="main-panel">
        {nav.map((item: INavigationElement) => (
          <NavigationElement element={item} key={item.id} />
        ))}
      </div>
      <div className="settings-panel">
        {settings.map((item: INavigationElement) => (
          <NavigationElement element={item} key={item.id} />
        ))}
      </div>
    </Box>
  );
}
