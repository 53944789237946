import StepsHeader from "app-sections/user/applications/steps/components/steps-header/StepsHeader";
import CustomRoutesProvider from "core/layouts/custom-routes-provider/CustomRoutesProvider";

export default function ApplicationStepsLayout() {
  return (
    <>
      <StepsHeader />
      <CustomRoutesProvider mobileAnimationType="slide" />
    </>
  );
}
