import { useNavigate } from 'react-router-dom';
import StepSuccessIcon from 'ui/icons/StepSuccessIcon';

import { StepButton, StepIconProps, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import { STEPS } from '../../config';

export interface HorizontalStepperProps {
  activeStep: number;
  latestValidStep: number;
  disabled?: boolean | null;
}

/**
 * Renders a horizontal stepper for a multi-step process.
 * It uses the Material-UI library to create the stepper and defines a custom step icon to display the current step's status.
 * The component takes two props: activeStep and
 * latestValidStep, which are numbers representing the current and the last valid step of the process.
 * @param props
 * @returns
 */
export default function HorizontalStepper(props: HorizontalStepperProps) {
  const navigate = useNavigate();
  const { activeStep, disabled } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const currentStep = STEPS[activeStep];

  const handleStep = (i: number) => {
    const step = STEPS[i];
    navigate(`${step.url}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel >
        {STEPS.map((stepData, index) => (
          <Step key={stepData.name}>
            <StepButton
              color="inherit"
              onClick={() => {
                handleStep(index);
              }}
              disabled={!!disabled}
            > 
              {matches && (
                <StepLabel StepIconComponent={CustomStepIcon}>
                  {stepData.name}
                </StepLabel>
              )}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {!matches && (
        <Box
          mt={2}
          sx={{ fontSize: "1.4rem", fontWeight: "bold", textAlign: "center" }}
        >
          {currentStep.name} ({activeStep + 1} of {STEPS.length})
        </Box>
      )}
    </Box>
  );
}

function CustomStepIcon(props: StepIconProps) {
  const { active, completed, icon } = props;

  const theme = useTheme();
  let bg = theme.palette.grey[400];
  if (completed) {
    bg = theme.palette.success.main;
  }
  if (active) {
    bg = theme.palette.primary.main;
  }

  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        borderRadius: "50%",
        background: bg,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {completed && <StepSuccessIcon />}
      {!completed && (
        <Box
          sx={{
            fontSize: "2rem",
            fontWeight: "bold",
            fontFamily: "Montserrat",
            color: active ? "#fff" : "",
          }}
        >
          {icon}
        </Box>
      )}
    </Box>
  );
}
