import { UserRole } from "types";

import { getAdminNavigation } from "./admin-config/admin";
import { INavigationElement } from "./NavigationElement";
import { getUserNavigation } from "./users-config/user";

export const getNavigationConfig = (
  roles: UserRole[],
  id: string
): INavigationElement[] => {
  if (roles.includes("admin")) {
    return getAdminNavigation(id);
  }

  return getUserNavigation();
};
