import { create } from 'zustand';

export interface ApplicationsStore {
  activeStep: number;
  latestValidStep: number;
  activeApplicationId: string;
  hasSwoError: boolean;
  setActiveStep: (activeStep: number) => void;
  setLatestValidStep: (activeStep: number) => void;
  setActiveApplicationId: (id: string) => void;
  setSwoError: (hasSwoError: boolean) => void;
}

export const useApplicationsStore = create<ApplicationsStore>((set) => ({
  activeStep: 0,
  latestValidStep: 0,
  activeApplicationId: "",
  hasSwoError: false,
  setActiveStep: (activeStep: number) => set(() => ({ activeStep })),
  setLatestValidStep: (latestValidStep: number) =>
    set(() => ({ latestValidStep })),
  setActiveApplicationId: (activeApplicationId: string) =>
    set(() => ({ activeApplicationId })),
  setSwoError: (hasSwoError: boolean) => set(() => ({ hasSwoError })),
}));
