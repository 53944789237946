

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function FileTypePdfIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <path
        d="M6.24597 24.248H3.24597C2.41754 24.248 1.74597 23.5765 1.74597 22.748V3.24805C1.74597 2.41962 2.41754 1.74805 3.24597 1.74805H13.875C14.273 1.74813 14.6547 1.90642 14.936 2.18805L20.807 8.05905C21.0882 8.34049 21.2462 8.72215 21.246 9.12005V13.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M21.246 9.24805H15.246C14.4175 9.24805 13.746 8.57647 13.746 7.74805V1.74805"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.24597 24.248V16.748"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.24597 16.748H9.99997C11.2426 16.748 12.25 17.7554 12.25 18.998C12.25 20.2407 11.2426 21.248 9.99997 21.248H9.24997"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.246 24.248C16.9028 24.248 18.246 22.9049 18.246 21.248V19.748C18.246 18.0912 16.9028 16.748 15.246 16.748V24.248Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M21.246 24.248V18.248C21.246 17.4196 21.9175 16.748 22.746 16.748H24.246"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M21.246 21.248H23.496"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
