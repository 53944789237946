import {
  getDivisionNameLabel,
  getStateBadgeLabel,
} from 'app-sections/common/applications/details/components/details-header/components/application-state-badge/helpers';
import ApplicationAddresses from 'app-sections/user/applications/components/applications/application-addresses/ApplicationAddresses';
import { getApplicationTypeLabelById, VariantOptionId } from 'app-sections/user/applications/steps/step3/config';
import { Fragment } from 'react';
import { Application } from 'types';
import { parseDate } from 'ui/helpers/parseDate';

import { Box, Card, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme, useTheme } from '@mui/material';

import ApplicationDataId from '../application-data-id/ApplicationDataId';
import ApplicationDataOptions from '../application-data-options/ApplicationDataOptions';
import ApplicationSWOInfo from '../application-swo-info/ApplicationSWOInfo';

export interface ApplicationsListProps {
  data: Application[];
  tab: 'closed' | 'in_progress';
}
const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    table: {
      width: '100%',
      margin: '0 0 24px 0',
      '& tbody td.is-warning': {
        color: '#d50037',
      },
    },
    dateCell: {
      fontWeight: 'bold',
    },
  };
};

export default function ApplicationsList(props: ApplicationsListProps) {
  const { data, tab } = props;

  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <Card className="no-shadow">
      {data.map((item: Application, index: number) => (
        <Fragment key={item.id}>
          <Table className="no-borders" sx={styles.table} aria-label="Applications">
            <TableHead>
              <TableRow>
                <TableCell>
                  Appl. #
                  <ApplicationSWOInfo data={item.state} />
                </TableCell>
                <TableCell>
                  <ApplicationDataId
                    applicationId={item.id}
                    permitNumber={item.certificatePermitNumber || ''}
                    color="#fff"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell scope="row">Appl. Type</TableCell>
                <TableCell>
                  {item.applicationType && <>{getApplicationTypeLabelById(item.applicationType as VariantOptionId)}</>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Permit(s)</TableCell>
                <TableCell>
                  {item.permitRecordIds?.map((id) => (
                    <div key={id}>
                      {id}
                      <br />
                    </div>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Status</TableCell>
                <TableCell className={item.state === 'swo' ? 'is-warning' : ''}>
                  {getStateBadgeLabel('admin', item.state)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Due date</TableCell>
                <TableCell>{item.slaDeadlineAt ? parseDate(item.slaDeadlineAt) : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Submission Date</TableCell>
                <TableCell>{item.submittedAt ? parseDate(item.submittedAt) : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Application Address</TableCell>
                <TableCell>
                  <ApplicationAddresses application={item} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">SSL</TableCell>
                <TableCell>{item.ssl}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Applicant</TableCell>
                <TableCell>
                  {!item.applicantPersonalInformation && <>-</>}
                  {item.applicantPersonalInformation && <>{item.applicantPersonalInformation.name || '-'}</>}
                </TableCell>
              </TableRow>
              {tab !== 'closed' && (
                <TableRow>
                  <TableCell scope="row">Division</TableCell>
                  <TableCell>{item.currentDivision && <>{getDivisionNameLabel(item.currentDivision)}</>}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell scope="row">Assignment</TableCell>
                <TableCell>
                  {item.currentDivision === 'surveyorsOffice' && item.surveyorTechnicianProfile?.fullName}
                  {item.currentDivision === 'zoning' && item.zoningTechnicianProfile?.fullName}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box mb={2} width="100%" display="flex" justifyContent="center">
            <ApplicationDataOptions item={item} />
          </Box>
        </Fragment>
      ))}
    </Card>
  );
}
