import ValidBadgeIcon from 'ui/icons/ValidBadgeIcon';

import { Box, Typography, useTheme } from '@mui/material';

export interface FormSectionHeaderProps {
  id?: string;
  title: string;
  subtitle?: string | JSX.Element;
  isValid?: boolean;
  icon?: JSX.Element;
  size?: "h1" | "h2" | "h3";
  display?: string;
}

export default function FormSectionHeader(props: FormSectionHeaderProps) {
  const {
    id,
    title,
    subtitle,
    isValid,
    icon,
    size = "h2",
    display = "flex",
  } = props;

  const theme = useTheme();

  return (
    <Box
      display={display}
      justifyContent="space-between"
      alignItems="center"
      id={id}
    >
      <Box display={icon ? "flex" : "block"}>
        {icon && <Box marginRight={2}>{icon}</Box>}
        <div>
          <Typography component="h3" variant={size}>
            {title}
          </Typography>
          {subtitle && (
            <Box mt={size === "h3" ? 0.5 : -1.5} mb={2}>
              <Typography component="h6" variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
                {subtitle}
              </Typography>
            </Box>
          )}
        </div>
      </Box>
      {isValid && <ValidBadgeIcon sx={{ color: theme.palette.success.main }} />}
    </Box>
  );
}
