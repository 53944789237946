import { APP_DETAILS_NAV_TABS } from 'app-sections/user/applications/steps/config';
import { useAuthStore } from 'core/store/auth';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Params } from 'types';
import { isFromZoning } from 'ui/helpers/users';

import { Box, Tab, Tabs } from '@mui/material';

import { getIndexById, getSectionByPath } from './config';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DetailsTabs() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuthStore();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const section = APP_DETAILS_NAV_TABS[newValue].id;
    if (
      currentUser &&
      isFromZoning(currentUser.userProfile.roles) &&
      APP_DETAILS_NAV_TABS[newValue].id === "review-details"
    ) {
      navigate(section + "/zoning");
    } else {
      navigate(section);
    }
  };

  useEffect(() => {
    const index = getIndexById(getSectionByPath(location.pathname));
    if (index > -1) {
      setValue(index);
    }
  }, [location.key]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Application details navigation"
        indicatorColor="secondary"
        className="uppercase"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {APP_DETAILS_NAV_TABS.map((item: Params, index: number) => (
          <Tab key={item.id} label={item.name} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Box>
  );
}
