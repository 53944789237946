import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CheckBadge(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26 24" fill="none">
      <g fill="none">
        <path
          d="M16.75 9.74902L12.769 15.057C12.6385 15.2308 12.439 15.3394 12.2222 15.3548C12.0055 15.3701 11.7927 15.2907 11.639 15.137L9.25 12.749"
          stroke="#166D12"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7319 1.35726C12.0413 0.972684 12.5083 0.749023 13.0019 0.749023C13.4955 0.749023 13.9625 0.972684 14.2719 1.35726L15.7839 3.23826C16.1319 3.67194 16.6771 3.89816 17.2299 3.83826L19.6299 3.57726C20.1221 3.52259 20.6123 3.69458 20.9625 4.04471C21.3126 4.39484 21.4846 4.88513 21.4299 5.37726L21.1689 7.77726C21.109 8.33007 21.3352 8.87525 21.7689 9.22326L23.6499 10.7353C24.0345 11.0447 24.2582 11.5117 24.2582 12.0053C24.2582 12.4989 24.0345 12.9659 23.6499 13.2753L21.7629 14.7803C21.3294 15.1288 21.1032 15.6742 21.1629 16.2273L21.4239 18.6273C21.479 19.1195 21.3071 19.61 20.9569 19.9602C20.6067 20.3105 20.1161 20.4823 19.6239 20.4273L17.2239 20.1663C16.6711 20.1062 16.1258 20.3324 15.7779 20.7663L14.2719 22.6403C13.9627 23.0252 13.4956 23.2491 13.0019 23.2491C12.5082 23.2491 12.0411 23.0252 11.7319 22.6403L10.2209 20.7603C9.87249 20.3266 9.32702 20.1004 8.77393 20.1603L6.37393 20.4213C5.88162 20.4767 5.39084 20.305 5.04053 19.9547C4.69021 19.6043 4.51847 19.1136 4.57393 18.6213L4.83493 16.2213C4.8948 15.6682 4.66862 15.1227 4.23493 14.7743L2.35493 13.2633C1.97001 12.9541 1.74609 12.487 1.74609 11.9933C1.74609 11.4995 1.97001 11.0325 2.35493 10.7233L4.23493 9.21126C4.66876 8.86337 4.89502 8.3181 4.83493 7.76526L4.57393 5.36526C4.51886 4.87304 4.69073 4.38251 5.04095 4.03229C5.39117 3.68206 5.88171 3.5102 6.37393 3.56526L8.77393 3.82626C9.327 3.88594 9.87237 3.6598 10.2209 3.22626L11.7319 1.35726Z"
          stroke="#166D12"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
