import { IMask } from 'react-imask';

export const cleanMaskedValues = (value: string): string => {
  return value.replace(/\s+/g, '').replace(/-/g, '').replace(/_/g, '');
};

export const formatPhoneNumber = (value: string): string => {
  if (!value) {
    return '';
  }
  const phoneMasker = IMask.createMask({
    mask: '+1 000-000-0000',
  });
  phoneMasker.resolve(value);

  return phoneMasker.value;
};

export const formatSslNumber = (value: string): string => {
  if (!value) {
    return '';
  }
  const phoneMasker = IMask.createMask({
    mask: '0000 0000',
  });
  phoneMasker.resolve(value);

  return phoneMasker.value;
};
