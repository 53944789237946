import { APP_DETAILS_NAV_TABS, getLinkToDetails } from 'app-sections/user/applications/steps/config';
import { Link } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { NotificationItem } from 'types';

import { Box, ButtonBase, Card } from '@mui/material';
import { markNotificationRead } from 'core/api/notifications';

export interface NotificationItemSmallProps {
  data: NotificationItem;
  urlPrefix: string;
  userId: string;
}

export default function NotificationItemSmall(
  props: NotificationItemSmallProps
) {
  const { data, urlPrefix, userId } = props;

  const isRead = data.readFor.includes(userId);

  return (
    <Box mb={1.5}>
      <ButtonBase
        sx={{ display: "block", width: "100%", fontFamily: "Montserrat" }}
        onClick={() => { markNotificationRead(data.id) }}
        component={Link}
        to={`${urlPrefix}${getLinkToDetails(
          data.applicationId,
          APP_DETAILS_NAV_TABS[0].id
        )}`}
      >
        <Card sx={{ m: 0, position: "relative" }}>
          {!isRead && (
            <Box
              sx={{
                position: "absolute",
                width: "12px",
                height: "12px",
                borderRadius: "20px",
                bgcolor: "#0062A0",
                top: 17,
                left: 6,
              }}
            ></Box>
          )}
          <Box fontSize="1.6rem" py={1.5} px={3}>
            {data.content}
          </Box>
          <Box mb={1.5} fontSize="1.2rem" px={3}>
            <ReactTimeAgo date={new Date(data.createdAt)} locale="en-US" />
          </Box>
        </Card>
      </ButtonBase>
    </Box>
  );
}
