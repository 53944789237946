import { UserProfile } from "types";
import SummaryTextGrid from "ui/components/summary-text-grid/SummaryTextGrid";
import { formatPhoneNumber } from 'ui/helpers/maskedValues';
import { Box, useTheme } from '@mui/material';
import { applicationPublicView } from "app-sections/common/applications/helpers";
import { useLocation } from "react-router-dom";

export interface SurveyorDataProps {
  surveyor: UserProfile
  status?: string
}

export default function SurveyorData(
  props: SurveyorDataProps
) {
  const { surveyor, status } = props;

  const location = useLocation();
  const isPublicView = applicationPublicView(location);
  const theme = useTheme();

  let bgBody = theme.palette.warning.light;
  let color = theme.palette.warning.dark;

  switch (status) {
    case "pending":
      color = theme.palette.grey[600];
      bgBody = theme.palette.background.paper;
      break;
    case "accepted":
      color = theme.palette.success.dark;
      bgBody = theme.palette.success.light;
      break;
    case "rejected":
      color = theme.palette.error.dark;
      bgBody = theme.palette.error.light;
      break;
    default:
      break;
  }

  return (
    <Box display="flex" gap={2} p={0}>
      <SummaryTextGrid
        color={status === "pending"  ? color : theme.palette.primary.main}
        items={[
          {
            label: "Name",
            desc: surveyor?.fullName
          },
          ].concat(isPublicView ? [] : [

          {
            label: "Email Address",
            desc: surveyor?.email,
          },
          {
            label: "Mobile Phone Number",
            desc:  surveyor && formatPhoneNumber(surveyor?.contactPhone),
          },
        ])}
      />
      {status && (
        <Box sx={{
          height: "32px",
          width: "102px",
          borderRadius: "4px",
          borderColor: color,
          backgroundColor: bgBody,
          borderStyle: "solid",
          borderWidth: "1px",
          padding: "4px",
          marginRight: "20px",
          textAlign: "center",
          textTransform: "uppercase",
          }}>
            {status}
        </Box>
      )}
    </Box>
  );
}
