import "./sidebar-layout.css";

import LayoutErrorBoundary from "../../../errors/LayoutErrorBoundary";
import CustomRoutesProvider from "../../custom-routes-provider/CustomRoutesProvider";
import AppToolbar from "../app-toolbar/AppToolbar";

export default function NoSidebarLayout() {
  return (
    <div className="root">
      <div className="layout-wrapper">
        <main className="content static-toolbar" id="main-scroll-container">
          <AppToolbar />
          <LayoutErrorBoundary>
            <CustomRoutesProvider mobileAnimationType="opacity" />
          </LayoutErrorBoundary>
        </main>
      </div>
    </div>
  );
}
