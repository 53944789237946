import { getCSV } from 'core/api/documents';
import { updateOneCacheItem } from 'core/cache/helpers';
import { useState } from 'react';
import { CsvType, Params } from 'types';
import useDeepCompareEffect from 'use-deep-compare-effect';

import CachedIcon from '@mui/icons-material/Cached';
import { Button, useTheme } from '@mui/material';
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';

import ButtonIcon from '../button-icon/ButtonIcon';
import ButtonLoader from '../button-loader/ButtonLoader';
import ErrorsList from '../errors-list/ErrorsList';
import { ExternalButton } from '../external-button/ExternalButton';

export interface CsvButtonProps {
  queryParams: Params;
  csvType: CsvType;
  queryKey: QueryKey;
  label?: string;
}

export default function CsvButton(props: CsvButtonProps) {
  const { queryParams, csvType, queryKey, label = "Generate CSV" } = props;
  const queryClient = useQueryClient();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const { isFetching, isError, data, error } = useQuery({
    queryKey,
    queryFn: () => getCSV({}),
    enabled: false,
  });

  const theme = useTheme();

  const clearQueryCache = () => {
    const data = { fileUrl: { url: "" } };
    updateOneCacheItem<any>(queryClient, queryKey, data);
  };

  const fetchQuery = () => {
    setIsSubmitted(true);
    clearQueryCache();

    const params = { ...queryParams, type: csvType };
    queryClient.fetchQuery({
      queryKey,
      queryFn: () => getCSV(params),
    });
  };

  if (data?.fileUrl?.url && isSubmitted) {
    setIsSubmitted(false);
    window.location.href = data?.fileUrl?.url;
  }

  useDeepCompareEffect(() => {
    clearQueryCache();
  }, [queryParams]);

  return (
    <>
      {!data?.fileUrl?.url && (
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          disabled={isFetching || isSubmitted}
          onClick={() => {
            fetchQuery();
          }}
        >
          <ButtonLoader isActive={isFetching || isSubmitted} noMargin={false} />
          <ButtonIcon
            isActive={!isFetching && !isSubmitted}
            noMargin={false}
            icon={<CachedIcon />}
            color={theme.palette.info.light}
          />
          {label}
        </Button>
      )}

      {data?.fileUrl?.url && (
        <Button
          variant="outlined"
          color="primary"
          disabled={data?.fileUrl?.url === ""}
          disableElevation
          component={ExternalButton}
          to={data?.fileUrl?.url}
        >
          <ButtonIcon
            isActive={true}
            noMargin={false}
            icon={<CachedIcon />}
            color={theme.palette.info.light}
          />
          {label}
        </Button>
      )}
      {isError && error && (
        <ErrorsList title="Unexpected error" errors={error as string[]} />
      )}
    </>
  );
}
