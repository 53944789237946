import LoginButton from 'app-sections/auth/login/LoginButton';
import NotificationsPanel from 'app-sections/common/notifications/notifications-panel/NotificationsPanel';
import { useAuthStore } from 'core/store/auth';
import { Link } from 'react-router-dom';
import { isAdmin } from 'ui/helpers/users';
import LogoutIcon from 'ui/icons/LogoutIcon';

import MenuIcon from '@mui/icons-material/Menu';
import { SxProps, Theme, Tooltip, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useUiStore } from '../../../store/ui';
import ToolbarLogo from '../toolbar-logo/ToolbarLogo';
import RegisterButton from 'app-sections/auth/login/RegisterButton';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    wrapper: {
      width: "100%",
      flexGrow: 1,
      position: "absolute",
      zIndex: 5,
    },
    appBar: {
      background: "#fff",
    },
    iconButton: {
      mr: 2,
      color: theme.palette.primary.dark,
    },
    header: {
      flexGrow: 1,
      margin: 0,
    },
  };
};

export default function AppToolbar() {
  const { toggleDrawerState } = useUiStore();
  const { currentUser } = useAuthStore();

  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <Box sx={styles.wrapper}>
      <AppBar position="static" elevation={1} sx={styles.appBar}>
        <Toolbar>
          {currentUser && (
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ ...styles.iconButton, display: { xs: "block", md: "none" } }}
              onClick={toggleDrawerState}
            >
              <MenuIcon />
          </IconButton>
          )}

          <ToolbarLogo />
          <Typography variant="h1" component="div" sx={styles.header}>

          </Typography>

          {!currentUser && (
            <>
              <RegisterButton />
            </>
          )}

          {currentUser && (
            <>
              <NotificationsPanel
                isForAdmin={isAdmin(currentUser.userProfile.roles)}
                userId={currentUser.id}
              />
              <Tooltip title={"Logout"}>
                <IconButton
                  aria-label="Logout"
                  component={Link}
                  to={`/auth/logout`}
                >
                  <LogoutIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
