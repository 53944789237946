import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Params } from 'types';

import { Box, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export interface BasicSelectProps {
  collection: Params[];
  label: string;
  control: any;
  inputName: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  onChange?: (event: SelectChangeEvent<any>) => void;
}

export default function BasicSelect(props: BasicSelectProps) {
  const {
    collection,
    control,
    label,
    inputName,
    required,
    disabled,
    onChange,
    helperText,
  } = props;

  // Trigger whole form onchange
  const myRef = useRef<HTMLInputElement>();

  return (
    <Controller
      name={inputName}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState.error}>
          <InputLabel
            required={required ? true : false}
            shrink
            htmlFor={inputName}
          >
            {label}
          </InputLabel>
          <Select
            {...field}
            disabled={disabled}
            displayEmpty
            error={!!fieldState.error}
            input={<Input ref={myRef} />}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e);

              if (myRef.current !== null) {
                myRef.current?.dispatchEvent(
                  new Event("change", { bubbles: true })
                );
              }
            }}
          >
            <MenuItem disabled value="" className="empty">
              - Select -
            </MenuItem>
            {collection.map((item: Params, index: number) => (
              <MenuItem key={index} value={item.id}>
                <Box color={`${item.color}`}>{item.name}</Box>
              </MenuItem>
            ))}
          </Select>
          {(!!fieldState.error || helperText) && (
            <FormHelperText>
              {fieldState.error?.message || helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
