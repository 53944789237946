// import { ApplicationDocumentId } from 'app-sections/user/applications/steps/step6/components/documents-form/config';
import { Params } from 'types';
import { FileInfo } from 'ui/components/multi-file-uploader/MultiFileUploader';

import { request } from '../config';
import { parseDocumentResponse } from './parsers';

const DOCUMENTS_URL = "documents.json?return_properties=true";
const DOCUMENTS_CSV_URL = "documents.json";
const DOCUMENTS_DELETE_URL = "documents";

export interface DocumentRequest {
  objectId: string;
  applicationId: string;
  // type: ApplicationDocumentId;
  type: string;
  file: string;
}

export function addDocument(document: DocumentRequest): Promise<FileInfo> {
  return request(
    {
      url: DOCUMENTS_URL,
      method: "POST",
      data: { document },
    },
    (data) =>
      // Added timeout because of s3 404 error when private url is invoked immediately
      new Promise((resolve) => {
        setTimeout(() => resolve(parseDocumentResponse(data.model)), 100);
      })
  );
}

export function deleteDocument(id: string): Promise<FileInfo> {
  return request(
    {
      url: `${DOCUMENTS_DELETE_URL}/${id}.json`,
      method: "DELETE",
    },
    (data) =>
      new Promise((resolve) => {
        setTimeout(() => resolve(data.model.id), 10);
      })
  );
}

export function getDocuments(
  applicationId: string,
  userId: string
): Promise<Params[]> {
  return request(
    {
      url: DOCUMENTS_URL,
      method: "GET",
      params: { applicationId, userId },
    },
    (data) => {
      return data.models.results;
    }
  );
}

export function getCSV(params: Params): Promise<any> {
  return request(
    {
      url: DOCUMENTS_CSV_URL,
      method: "POST",
      params,
    },
    (data) => {
      return data.model;
    }
  );
}

export function fileUrlForDocument(documentId?: string): string {
  return `/api/documents/${documentId}/redirect`;
}
