import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { Grid } from "@mui/material";
import BasicDatePicker from '../basic-fields/single-datepicker/BasicDatepicker';

export interface RangeDatepickerProps {
  control: any;
  dateFromFieldName: string;
  dateFromFieldLabel: string;
  dateToFieldName: string;
  dateToFieldLabel: string;
  reset?: number;
}

function getDateFromIso(d: string): dayjs.Dayjs {
  return dayjs(d);
}

export default function RangeDatepicker(props: RangeDatepickerProps) {
  const {
    control,
    dateFromFieldName,
    dateFromFieldLabel,
    dateToFieldName,
    dateToFieldLabel,
    reset = 0
  } = props;

  const [dateFrom, setDateFrom] = useState<string | null>();
  const [dateTo, setDateTo] = useState<string | null>();

  const shouldDisableDateFrom = (date: dayjs.Dayjs): boolean => {
    if (dateTo && getDateFromIso(dateTo).isBefore(date)) {
      return true;
    }
    return false;
  };
  const shouldDisableDateTo = (date: dayjs.Dayjs): boolean => {
    if (dateFrom && getDateFromIso(dateFrom).isAfter(date)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (reset > 0) {
      setTimeout(() => {
        setDateFrom(null);
        setDateTo(null);
      }, 10);
    }
  }, [reset]);

  return (
    <Grid container columnSpacing={4} spacing={4}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <BasicDatePicker
          label={dateFromFieldLabel}
          inputName={dateFromFieldName}
          control={control}
          shouldDisableDate={shouldDisableDateFrom}
          onChange={setDateFrom}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <BasicDatePicker
          label={dateToFieldLabel}
          inputName={dateToFieldName}
          control={control}
          shouldDisableDate={shouldDisableDateTo}
          onChange={setDateTo}
        />
      </Grid>
    </Grid>
  );
}
