import { APP_DETAILS_NAV_TABS, getLinkToDetails, getLinkToReviewDetails } from 'app-sections/user/applications/steps/config';
import { Link } from 'react-router-dom';

import { Box, useTheme } from '@mui/material';
import { isFromZoning } from 'ui/helpers/users';
import { useAuthStore } from 'core/store/auth';

export interface ApplicationDataIdProps {
  applicationId: string;
  permitNumber?: string;
  color?: string;
}

export default function ApplicationDataId(props: ApplicationDataIdProps) {
  const theme = useTheme();
  const { currentUser } = useAuthStore();
  const {
    applicationId,
    permitNumber,

    color = theme.palette.primary.dark,
  } = props;
  const link = currentUser && isFromZoning(currentUser.userProfile.roles)
    ? `/admin/${getLinkToReviewDetails(applicationId, 'zoning')}`
    : `/admin/${getLinkToDetails(applicationId, APP_DETAILS_NAV_TABS[0].id)}`;

  return (
    <Box fontSize="1.8rem">
      <Link
        style={{ fontWeight: "bold", color }}
        to={link}
      >
        {permitNumber || applicationId}
      </Link>
    </Box>
  );
}
