import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Box } from '@mui/system';

export interface ExpandableSectionProps {
  children: any;
  title: string;
  defaultExpanded?: boolean;
}

export default function ExpandableSection(props: ExpandableSectionProps) {
  const { children, title, defaultExpanded = false } = props;

  return (
    <Accordion elevation={0} defaultExpanded={defaultExpanded} sx={{ mb: 2 }}>
      <AccordionSummary
        sx={{
          padding: "0 16px",
          backgroundColor: "#213A6D",
          borderRadius: "4px",
        }}
        id="inspection-types"
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
      >
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "2.1rem",
            color: "#fff",
            textTransform: "uppercase",
          }}
          component="h3"
          m={0}
        >
          {title}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box px={2} pt={4}>
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
