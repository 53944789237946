import FormSectionHeader from 'ui/form-section-header/FormSectionHeader';
import SummaryTextGrid from 'ui/components/summary-text-grid/SummaryTextGrid';
import { Card, CardContent } from '@mui/material';
import { Application } from "types";

export interface Props {
  application: Application;
}

export default function Content(
  props: Props
) {

  const { application } = props;

  return (
    <>
      <FormSectionHeader
        title="Participating divisions and assignees"
        subtitle="Reviewing technicians and managers assigned to this application."
      />

      <Card>
        <CardContent>
          <SummaryTextGrid
            items={[
              {
                label: "Surveyor's Office Technician",
                desc: application.surveyorTechnicianProfile?.fullName || "Not Assigned",
              },
              {
                label: "Surveyor's Office Manager",
                desc: application.surveyorManagerProfile?.fullName || "Not Assigned",
              },
              {
                label: "Zoning Technician",
                desc: application.zoningTechnicianProfile?.fullName || "Not Assigned",
              },
              {
                label: "Zoning Manager",
                desc: application.zoningManagerProfile?.fullName || "Not Assigned",
              },
            ]}
          />
        </CardContent>
      </Card>
    </>
  );
};
