import ApplicationDetailsLayout from "core/layouts/components/application-details-layout/ApplicationDetailsLayout";
import { Navigate, RouteObject } from "react-router-dom";

import ApplicationsResolver from "../protected-routes/applications-resolver/ApplicationsResolver";
import { applicationDetailsRoutes } from "./applicationDetailsRoutes";

export const applicationRoutes: RouteObject[] = [
  {
    path: "",
    element: <Navigate replace to="/404" />,
  },

  {
    path: "details",
    element: (
      <ApplicationsResolver>
        <ApplicationDetailsLayout />
      </ApplicationsResolver>
    ),
    children: applicationDetailsRoutes,
  },
];
