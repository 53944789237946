import { ApiCollectionResponse, LogItem, Params } from 'types';

import { getCollectionResponse, request } from '../config';

const GET_LOGS_URL = "/changes.json";

export function getLogs(
  params: Params
): Promise<ApiCollectionResponse<LogItem>> {
  return request(
    {
      url: GET_LOGS_URL,
      method: "GET",
      params: params,
    },
    (data) => {
      return getCollectionResponse<LogItem>(data);
    }
  );
}
