export const getErrorMessage = (error: Error): string => {
  if (!error.stack) {
    return error.message || error.toString();
  }
  return error.stack;
};

export const isRaygunEnabled = (): boolean => {
  // only for UAT and PROD
  return (
    window.location.host.includes("uat") ||
    window.location.host.includes("wallcheck.dob.dc.gov") ||
    window.location.host.includes("wallcheck-dob-prod")
  );
};

export const raygunKey = (): string => {
  const host = window.location.host;
  if (host.includes('uat')){
    return '7rRyBIIcEsYR4L1YmUZrA';
  } else if (host.includes('wallcheck.dob.dc.gov') || host.includes("wallcheck-dob-prod")){
    return 'd4anJ7VYqgQ1AynpSllg';
  } else {
    return '';
  }
}
