import DetailsHeader from 'app-sections/common/applications/details/components/details-header/DetailsHeader';
import { useApplicationsStore } from 'core/store/applications';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

export default function ApplicationDetailsLayout() {
  const { hasSwoError } = useApplicationsStore();
  return (
    <>
      <DetailsHeader />
      {/* <CustomRoutesProvider mobileAnimationType="opacity" /> */}
      <Box
        className={hasSwoError ? 'swo-error' : ''}
        sx={
          hasSwoError
            ? {
                filter: 'grayscale(100%)',
                pointerEvents: 'none',
                userSelect: 'none',
                position: 'relative',
              }
            : {}
        }
      >
        <Outlet />
      </Box>
    </>
  );
}
