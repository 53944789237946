import { Box, CircularProgress, LinearProgress } from '@mui/material';

export interface DataLoaderProps {
  size?: number;
  loaderStyle?: "circular" | "linear";
}

export default function DataLoader(props: DataLoaderProps) {
  const { size = 80, loaderStyle = "circular" } = props;

  if (loaderStyle === "circular") {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="secondary" size={size} />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
}
