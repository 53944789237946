import { cleanMaskedValues } from 'ui/helpers/maskedValues';

import MaskedTextInput from '../masked-text-input/MaskedTextInput';

export interface SslMaskedTextInputProps {
  label: string;
  helperText?: string;
  control: any;
  inputName: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: any;
  errorsInputName?: string;
  adornment?: { position: 'start' | 'end'; label: string };
}

export default function SslMaskedTextInput(props: SslMaskedTextInputProps) {
  // mask definition
  const inputProps = {
    mask: '0000 0000',
    // definitions: {
    //   '#': /[2-9]/,
    // },
  };

  return <MaskedTextInput {...props} cleanupFn={cleanMaskedValues} inputProps={inputProps} />;
}
