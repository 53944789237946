import * as Yup from 'yup';

// Client want to have only numbers in address numbers and
export const getAddressNumberValidation = () => {
  return Yup.string()
    .matches(/^[0-9]+$/gi, "Field must contain only numbers")
    .nullable()
    .transform((value: string, originalValue: string) =>
      String(originalValue).trim() === "" ? null : value
    );
};
