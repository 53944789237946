import { getDivisionNameById } from "app-sections/common/applications/details/components/sections/status-info/components/divisions-info/helpers";
import { getSectionNameById } from "app-sections/user/applications/steps/config";
import { LogItemChangeType, LogItemMetadata } from "types";

const SPECIAL_TYPES: LogItemChangeType[] = [
  "application_reopened",
  "application_cancelled_by_client",
  "review_rejected",
];

export const CONTENT: Record<LogItemChangeType, string> = {
  application_cancelled_by_client: "Application cancelled by client",
  application_completed_below_grade: "Application closed with Below Grade status",
  application_moved_to_pending: "Application Submitted",
  application_moved_to_review: "Documents submitted by DC registered surveyor",
  application_paid: "Payment finalized",
  application_reopened: "Application Reopened",
  application_reviewers_assigned: "Review assigned to %{currentUserName}",
  application_reviewers_changed: "Assigned reviewer changed from %{previousUserName} to %{currentUserName}",
  application_state_changed_to_denied: "Wall check for Permit %{permitNumber} application denied by Surveyor's Office",
  application_swo_lifted: 'SWO restriction lifted',
  document_uploaded: "Document uploaded",
  review_rejected: "Correction request by Surveyor's Office",
  review_reviewer_assigned: "Review assigned to %{userName}",
  review_reviewer_updated: "Assigned reviewer changed from %{previousUserName} to %{currentUserName}",
  review_signed: "Wall Check Examination Report signed and uploaded by Surveyor's Office",
  review_submitted_to_review: "Applicant response submitted for Surveyor's Office request",
  review_submitted_to_review_by_dc_registered_surveyor: "New set of documents uploaded by DC registered surveyor for Surveyor's office",
  status_changed_to_swo: 'Application blocked due to SWO restriction',
  zoning_review_answer_submitted: "Applicant response submitted for Zoning Office request",
  zoning_review_answered_yes: "Applicant selected to upload new Wall Check examination report",
  zoning_review_answered_no: "Applicant selected to answer Zoning without new Wall Check examination report",
  zoning_review_approved: "Wall check for Permit %{permitNumber} approved",
  zoning_review_correction_request_created: "Correction request for Surveyor's Office",
  zoning_review_created_pending: "Correction request for Applicant",
  zoning_review_rejected: "Wall check for Permit %{permitNumber} application denied by Zoning Office",
};

export const getLogContent = (
  type: LogItemChangeType,
  metadata: LogItemMetadata | null
): string | JSX.Element => {
  if (metadata?.allZoningReviewsRejected){
    return '';
  }
  if (SPECIAL_TYPES.includes(type)) {
    return getSpecialContent(type, metadata);
  }
  let content = CONTENT[type] || "";
  if (!metadata) {
    return content;
  }

  Object.keys(metadata).forEach((key: string) => {
    const toReplace = `%{${key}}`;

    let replaceWith = metadata[key as keyof LogItemMetadata] ?? "";
    if (toReplace.includes("divisionName")) {
      replaceWith = getDivisionNameById(replaceWith);
    } else if (toReplace.includes("sectionName")) {
      replaceWith = getSectionNameById(replaceWith);
    }
    content = content.replace(toReplace, replaceWith);
  });

  return content;
};

export const getSpecialContent = (
  type: LogItemChangeType,
  metadata: LogItemMetadata | null
): JSX.Element => {
  let title = CONTENT[type] || "";

  return (
    <>
      <strong>{title}</strong>
      {metadata?.reason && (
        <>
          <br />
          <br />
          <strong>Reason:</strong>
          <br />
          {metadata?.reason ?? ""}
        </>
      )}
    </>
  );
};
