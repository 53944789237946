import { SvgIcon, SvgIconProps } from "@mui/material";

export default function TrashIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M1.25 3.75H18.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.875 1.25H8.125C7.43464 1.25 6.875 1.80964 6.875 2.5V3.75H13.125V2.5C13.125 1.80964 12.5654 1.25 11.875 1.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.125 14.375V8.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M11.875 14.375V8.125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7208 17.6033C15.6671 18.2514 15.1253 18.7501 14.475 18.75H5.52583C4.8755 18.7501 4.33376 18.2514 4.28 17.6033L3.125 3.75H16.875L15.7208 17.6033Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
