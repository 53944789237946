import { ApplicationPaymentState, ApplicationState, DivisionName } from 'types';

const STATE_BADGE_CLIENT: Record<ApplicationState, string> = {
  draft: "Project Creation",
  pending_payment: "Payment in Progress",
  paid: "Paid",
  pending: "Review is Pending",
  surveyor_office_review: "Review in progress",
  zoning_review: "Review in progress",
  completed: "Approved",
  completed_below_grade: "Below Grade",
  completed_partially: "Partially approved",
  denied: "Denied",
  deleted_by_client: "Cancelled",
  swo: "SWO",
  client_action_needed: "Hold for correction",
};

const STATE_BADGE_ADMIN: Record<ApplicationState, string> = {
  draft: "Draft",
  pending_payment: "Payment in Progress",
  paid: "Paid",
  pending: "Pending Docments",
  surveyor_office_review: "Review in progress",
  zoning_review: "Review in progress",
  completed: "Approved",
  completed_below_grade: "Below Grade",
  completed_partially: "Partially approved",
  denied: "Denied",
  deleted_by_client: "Cancelled",
  swo: "SWO",
  client_action_needed: "Client action needed",
};

const PAYMENT_STATES: Record<ApplicationPaymentState, string> = {
  new: "Started",
  failed: "Failed",
  paid: "Paid",
  pending: "Pending",
};

const DIVISIONS: Record<DivisionName, string> = {
  surveyorsOffice: "Surveyor's Office",
  zoning: "Zoning",
};

export const getStateBadgeLabel = (
  type: "client" | "admin",
  state: ApplicationState
): string => {
  return type === "client"
    ? STATE_BADGE_CLIENT[state]
    : STATE_BADGE_ADMIN[state];
};

export const getPaymentStateLabel = (
  state: ApplicationPaymentState
): string => {
  return PAYMENT_STATES[state];
};

export const getDivisionNameLabel = (division: DivisionName): string => {
  return DIVISIONS[division];
};
