import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';

import { useUiStore } from '../../../../store/ui';

interface AppNavbarToggleButtonProps {
  children: JSX.Element;
}

export default function AppNavbarToggleButton(
  props: AppNavbarToggleButtonProps
) {
  const { toggleDrawerState } = useUiStore();
  const children = props.children || <MenuIcon />;

  return (
    <IconButton onClick={toggleDrawerState} color="inherit">
      {children}
    </IconButton>
  );
}
