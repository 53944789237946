import { Box, Card, CardContent, SxProps, Theme, useTheme } from '@mui/material';
import { Application } from 'types';
import { parseMoney } from 'ui/helpers/parseMoney';

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    paymentTable: {
      fontSize: "1.6rem",
      paddingX: "1"
    },

    totalValue: {
      width: "fit-content",
      fontWeight: "bold",
      fontSize: "3.2rem",
      color: theme.palette.primary.main,
    },
    totalLabel: {
      width: "fit-content",
      fontWeight: "bold",
      fontSize: "2rem",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
    },
    summaryLabel: {
      fontWeight: "normal",
      fontSize: "1.8rem",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      mb: 1,
    },
  };
};

function getCardType(isPaid: boolean): string {
  return isPaid ? "card-type-success-light" : "card-type-info-light";
}

export interface PaymentTableProps {
  data: Application;
  isPaid?: boolean;
}

export default function PaymentTable(props: PaymentTableProps) {
  const { data, isPaid = false } = props;

  const theme = useTheme();
  const styles = getStyles(theme);
  const applicationFee = 50; // TODO: load it from contants
  const supplementalFeePercent = 0.1;

  return (
    <Card className={`no-shadow ${getCardType(isPaid)}`}>
      <CardContent
          sx={{
            width: "100%",
            paddingX: {xs: 1.5, sm: 2},
            paddingY: 2,
            paddingBottom: "16px !important"
            }}
        >
        <Box sx={styles.paymentTable} display={"flex"} flexDirection={"column"} >
          <Box sx={styles.summaryLabel} display={"flex"} justifyContent={"space-between"}>
            Summary
          </Box>
          {data.permitNumbers.map(permit =>
            <Box key={permit.recordId} display={"flex"} justifyContent={"space-between"} padding={1}>
              <Box>
                Application / Recordation fee - {permit.recordId}
              </Box>
              <Box pl={1.5}>
                {parseMoney((applicationFee) * 100)}
              </Box>
            </Box>
          )}
          <Box display={"flex"} justifyContent={"space-between"} padding={1}>
            <Box className="with-summary-border" >
              Enhanced service fee ({100 * supplementalFeePercent}%)
            </Box>
            <Box pl={1.5}>
              <span>{parseMoney((data.permitRecordIds.length * applicationFee * supplementalFeePercent) * 100)}</span>
            </Box>
          </Box>
          <Box sx={{ borderTop: `2px solid ${theme.palette.primary.dark}`} }
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={1}
              >
            <Box sx={styles.totalLabel}>Total</Box>
            <Box sx={styles.totalValue}>
              {parseMoney((data.permitRecordIds.length * applicationFee * (1 + supplementalFeePercent)) * 100)}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
