import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ArrowRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 22">
      <g fill="none">
        <path
          d="M4.5835 1.625L13.5168 10.5583C13.6341 10.6754 13.7 10.8343 13.7 11C13.7 11.1657 13.6341 11.3246 13.5168 11.4417L4.5835 20.375"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
