import { SvgIcon, SvgIconProps } from "@mui/material";

export default function AdminProfilesIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 26 24" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9922 9.937C24.9931 16.5253 19.8818 22.152 12.8984 23.25C5.91509 22.152 0.803732 16.5253 0.804688 9.937V2.25C0.804687 1.42157 1.52663 0.75 2.41719 0.75H23.3797C24.2702 0.75 24.9922 1.42157 24.9922 2.25V9.937Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9016 12C14.9053 12 16.5297 10.489 16.5297 8.625C16.5297 6.76104 14.9053 5.25 12.9016 5.25C10.8978 5.25 9.27344 6.76104 9.27344 8.625C9.27344 10.489 10.8978 12 12.9016 12Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6914 16.7517C17.5896 14.7171 15.3494 13.4336 12.9004 13.4336C10.4514 13.4336 8.21124 14.7171 7.10938 16.7517"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
