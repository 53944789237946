import { SvgIcon, SvgIconProps } from '@mui/material';

export default function AppWindowUser(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 22" fill="none">
      <g fill="none">
        <path d="M21.76 8.25305V2.25305C21.76 1.42462 21.0884 0.753052 20.26 0.753052H2.26001C1.43158 0.753052 0.76001 1.42462 0.76001 2.25305V18.7531C0.76001 19.5815 1.43158 20.2531 2.26001 20.2531H9.76001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M0.76001 5.25305H21.76" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.01 15.7531C19.4598 15.7531 20.635 14.5778 20.635 13.1281C20.635 11.6783 19.4598 10.5031 18.01 10.5031C16.5603 10.5031 15.385 11.6783 15.385 13.1281C15.385 14.5778 16.5603 15.7531 18.01 15.7531Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.01 16.5C15.1107 16.5006 12.7606 18.8507 12.76 21.75V23.25H23.26V21.75C23.26 20.3576 22.7069 19.0223 21.7223 18.0377C20.7378 17.0531 19.4024 16.5 18.01 16.5V16.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.76001 9.75305H5.26001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.76001 12.7531H5.26001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.76001 15.7531H5.26001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.26001 9.75305H13.51" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.26001 12.7531H12.76" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.26001 15.7531H12.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </SvgIcon>
  );
}
