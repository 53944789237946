import { APP_VER } from 'core/config';
import { useAuthStore } from 'core/store/auth';

import { Box } from '@mui/material';

export interface ErrorMessageProps {
  errorMessage: string;
}

export default function ErrorMessage(props: ErrorMessageProps) {
  const { errorMessage } = props;
  const { currentUser } = useAuthStore();

  return (
    <Box mx={2}>
      <h1>Sorry... there was an error</h1>
      <pre>User ID: {currentUser?.id || "Not logged in"}</pre>
      <pre>App Version: {APP_VER}</pre>
      <pre>URL: {window.location.href}</pre>
      <pre>Timestamp: {new Date().toUTCString()}</pre>
      <pre>Browser: {navigator.userAgent}</pre>
      <pre>{errorMessage}</pre>
    </Box>
  );
}
