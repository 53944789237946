import { APP_DETAILS_NAV_TABS, getLinkToDetails } from 'app-sections/user/applications/steps/config';
import { Link } from 'react-router-dom';
import { Application } from 'types';
import ViewIcon from 'ui/icons/ViewIcon';

import { Box, IconButton } from '@mui/material';

export interface ApplicationDataOptionsProps {
  item: Application;
}

export default function ApplicationDataOptions(
  props: ApplicationDataOptionsProps
) {
  const { item } = props;

  return (
    <>
      <Box mx={-1} display="flex" justifyContent="flex-end">
        <Box mx={1}>
          <IconButton
            aria-label="Go to Application"
            className="outlined-dark filled"
            component={Link}
            to={`/admin/${getLinkToDetails(
              item.id,
              APP_DETAILS_NAV_TABS[0].id
            )}`}
          >
            <ViewIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
