import { getLogs } from 'core/api/logs';
import ButtonLoader from 'ui/components/button-loader/ButtonLoader';
import CsvButton from 'ui/components/csv-button/CsvButton';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import FormSectionHeader from 'ui/form-section-header/FormSectionHeader';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';

import DataTable from './data-table/DataTable';
import { useAuthStore } from 'core/store/auth';

export interface ReviewLogsProps {
  applicationId: string;
}

export default function ReviewLogs(props: ReviewLogsProps) {
  const { applicationId } = props;

  const queryKey = ["application", applicationId, "logs"];

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error,
    data,
    isLoading,
    isError,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) => getLogs({ applicationId, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.hasNextPage) {
        return allPages.length + 1;
      }
    },
  });

  const items = data?.pages.flatMap((page) => page.results.filter(item => !item.metadata?.allZoningReviewsRejected));
  const { currentUser } = useAuthStore();

  return (
    <Box mb={3}>
      <FormSectionHeader
        title="Application log"
        subtitle="Find all review related update/history here."
      />
      <Accordion defaultExpanded={false} elevation={0}>
        <AccordionSummary
          id="review-divisions"
          expandIcon={<ExpandMoreIcon sx={{ color: "#67707E" }} />}
        >
          <Box
            sx={{
              fontSize: "2rem",
              color: "#0062A0",
              fontWeight: "bold",
            }}
            component="h3"
            display="flex"
            m={0}
          >
            Show details
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!isLoading && !isError && items && (
            <>
              <DataTable data={items} />
              <Box display="flex" justifyContent="space-between">
                <Box mr={2}>
                  <Button
                    disableElevation
                    disabled={isFetchingNextPage || !hasNextPage}
                    onClick={() => fetchNextPage()}
                  >
                    <ButtonLoader
                      isActive={isFetchingNextPage}
                      noMargin={false}
                    />
                    More...
                  </Button>
                </Box>
                {currentUser &&
                  <CsvButton
                    queryParams={{ applicationId }}
                    csvType="csv_export_changes_log"
                    queryKey={["csv_export_changes_log", applicationId]}
                  />
                }
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {isError && (
        <ErrorsList title="Logs load error" errors={error as string[]} />
      )}
    </Box>
  );
}
