import { UserData } from 'types';
import { create } from 'zustand';

export interface AuthStore {
  currentUser: UserData | null;
  setCurrentUser: (currentUser: UserData | null) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  currentUser: null,
  setCurrentUser: (currentUser: UserData | null) =>
    set(() => ({ currentUser })),
  logout: () => set(() => ({ currentUser: null })),
}));
