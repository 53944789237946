import { getCurrentUserData } from 'core/api/auth';
import { isRaygunEnabled } from 'core/errors/errors.lib';
import { useAuthStore } from 'core/store/auth';
import { useUiStore } from 'core/store/ui';
import rg4js from 'raygun4js';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from 'types';
import FullPageLoader from 'ui/components/full-page-loader/FullPageLoader';

import { useQuery } from '@tanstack/react-query';

function checkLocation(location:string): string | null {
  if (location.includes("applications")) {
    const segments = location.split("/");
    const index = segments.indexOf("applications");
    if(segments[index+1]) {
      return segments[index+1];
    }
    return null;
  }
  return null;

}

export interface MustBeLoggedInProps {
  children: any;
  role: UserRole;
}

export default function MustBeLoggedIn(props: MustBeLoggedInProps) {
  const { children, role } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useAuthStore();
  const { setIsPublicSection } = useUiStore();

  const [redirect, setRedirect] = useState<string>("");

  useEffect(() => {
    if (redirect !== "") {
      setRedirect("");
      navigate(redirect);
    }
  }, [redirect, navigate]);

  const { isLoading, isError, data, isSuccess } = useQuery({
    queryKey: ["auth"],
    queryFn: () => getCurrentUserData(),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (isSuccess) {
      if (currentUser?.id !== data.user.id) {
        setIsPublicSection(false);
        setCurrentUser(data.user);
        if (isRaygunEnabled()) {
          rg4js("setUser", {
            identifier: data.user.email, // A unique ID, email address, or another ID such as a UUID
            isAnonymous: false, // Indicates whether the user is anonymous or not
            uuid: data.user.id, // Optional device identifier
          });
        }
      }
      if (!data.user.userProfile.roles.includes(role)) {
        if (role === "client") {
          setRedirect("/admin");
        } else {
          setRedirect("/");
        }
      }
    }

    if (isError) {
      // when user is not logged in but want to get to application, redirect user to public view
      const id = checkLocation(location.pathname);
      if(id) {
        navigate(`/public/applications/${id}/details/summary`);
      }
    }
   
  }, [isError, isSuccess]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!isLoading && isError && !checkLocation(location.pathname)) {
    navigate("/auth/login");
    return null;
  }

  return <>{children}</>;
}
