import { SvgIcon, SvgIconProps } from "@mui/material";

export default function SaveIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33464 1.66602H15.3532L18.3346 4.72999V16.666C18.3346 17.5865 17.5884 18.3327 16.668 18.3327H3.33464C2.41416 18.3327 1.66797 17.5865 1.66797 16.666V3.33268C1.66797 2.41221 2.41416 1.66602 3.33464 1.66602ZM14.168 3.33268V8.33268H5.83464V3.33268H3.33464V16.666H5.0013V9.99935H15.0013V16.666H16.668V5.40706L14.6494 3.33268H14.168ZM7.5013 6.66602V3.33268H12.5013V6.66602H7.5013ZM6.66797 11.666V16.666H13.3346V11.666H6.66797ZM11.668 4.16602H10.0013V5.83268H11.668V4.16602Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}
