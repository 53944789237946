

import { SvgIcon, SvgIconProps } from '@mui/material';

export default function ValidBadgeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26 25">
      <g fill="none">
        <path
          d="M16.75 10.748L12.769 16.056C12.6385 16.2298 12.439 16.3385 12.2222 16.3538C12.0055 16.3691 11.7927 16.2897 11.639 16.136L9.25 13.748"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.73 2.35628C12.0394 1.97171 12.5064 1.74805 13 1.74805C13.4936 1.74805 13.9606 1.97171 14.27 2.35628L15.782 4.23728C16.13 4.67097 16.6752 4.89718 17.228 4.83728L19.628 4.57628C20.1201 4.52161 20.6104 4.69361 20.9605 5.04373C21.3107 5.39386 21.4826 5.88416 21.428 6.37628L21.167 8.77628C21.1071 9.3291 21.3333 9.87428 21.767 10.2223L23.648 11.7343C24.0326 12.0437 24.2562 12.5107 24.2562 13.0043C24.2562 13.4979 24.0326 13.9649 23.648 14.2743L21.761 15.7793C21.3274 16.1278 21.1013 16.6732 21.161 17.2263L21.422 19.6263C21.477 20.1185 21.3052 20.609 20.9549 20.9593C20.6047 21.3095 20.1142 21.4813 19.622 21.4263L17.222 21.1653C16.6691 21.1052 16.1239 21.3314 15.776 21.7653L14.27 23.6393C13.9608 24.0242 13.4937 24.2481 13 24.2481C12.5063 24.2481 12.0392 24.0242 11.73 23.6393L10.219 21.7593C9.87053 21.3256 9.32507 21.0994 8.77197 21.1593L6.37197 21.4203C5.87966 21.4757 5.38889 21.304 5.03857 20.9537C4.68825 20.6034 4.51652 20.1126 4.57197 19.6203L4.83297 17.2203C4.89285 16.6672 4.66667 16.1217 4.23297 15.7733L2.35297 14.2623C1.96806 13.9531 1.74414 13.486 1.74414 12.9923C1.74414 12.4986 1.96806 12.0315 2.35297 11.7223L4.23297 10.2103C4.66681 9.86239 4.89306 9.31712 4.83297 8.76428L4.57197 6.36428C4.51691 5.87206 4.68878 5.38153 5.039 5.03131C5.38922 4.68109 5.87975 4.50922 6.37197 4.56428L8.77197 4.82528C9.32504 4.88496 9.87042 4.65882 10.219 4.22528L11.73 2.35628Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
