import {
  getIndexById as _getIndexById,
  getSectionByPath as _getSectionByPath,
} from "app-sections/common/tabs/tabsHelpers";
import { APP_DETAILS_NAV_TABS } from "app-sections/user/applications/steps/config";

export const getIndexById = (id: string): number => {
  return _getIndexById(id, APP_DETAILS_NAV_TABS);
};

export const getSectionByPath = (path: string): string => {
  return _getSectionByPath(path, "details");
};
