import { updateOneCacheItem } from 'core/cache/helpers';
import { useWebSockets } from 'core/hooks/useWebsockets';
import { canBeOnCurrentSection } from 'core/routes/protected-routes/applications-resolver/resolver-guards';
import { useUiStore } from 'core/store/ui';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Application, Certificate, Params } from 'types';

import { useQueryClient } from '@tanstack/react-query';

import { getQueryKeyByModel } from './helpers';

export interface UpdatesListenerProps {
  userId: string;
}

/**
 * Listen websockets for updates channel and split messages into actions
 * Updates chanel is
 * @param props
 * @returns
 */
export default function UpdatesListener(props: UpdatesListenerProps) {
  const { userId } = props;
  const queryClient = useQueryClient();
  const { setUnreadCount, unreadNotificationCounter } = useUiStore();
  const [idempotenceId, setIdempotenceId] = useState('');
  const message = useWebSockets('updates', userId);
  const location = useLocation();
  const navigate = useNavigate();

  if (message && message.uuid !== idempotenceId) {
    setTimeout(() => {
      switch (message.type) {
        case 'pdf_generated':
          const documentQueryKey = getQueryKeyByModel(message.model);
          const documentData = { document: { fileUrl: message.model.fileUrl } };
          updateOneCacheItem<Certificate>(
            queryClient,
            documentQueryKey,
            documentData
          );
          break;
        case 'notifications_unread_count':
          setUnreadCount(message.model.unread_count);
          break;
        case 'notification':
          setUnreadCount(unreadNotificationCounter + 1);
          break;
        case 'csv_generated':
          const csvQueryKey = getQueryKeyByModel(message.model);
          const csvData = { fileUrl: message.model.fileUrl };
          updateOneCacheItem<any>(queryClient, csvQueryKey, csvData);
          break;
        case 'application_swo':
          const queryKey = ['applications', message.model.id];
          updateOneCacheItem<Application>(queryClient, queryKey, message.model);
          updateOneCacheItem<Params>(queryClient, ['application/check-swo', message.model.id], {
            id: message.model.id,
            isChecked: message.uuid,
            hasSwo: message.model.state === 'swo',
            isLoading: false,
          });
          break;
        case 'application':
          const applicationQueryKey = getQueryKeyByModel(message.model);
          const applicationData = message.model as Application;
          updateOneCacheItem<Application>(
            queryClient,
            applicationQueryKey,
            applicationData
          );
          const redirect = canBeOnCurrentSection(applicationData, location);
          if (redirect !== '') navigate(redirect);
          break;
      }
      setIdempotenceId(message.uuid || '');
    }, 1);
  }

  return null;
}
