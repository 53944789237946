import { Params } from 'react-router-dom';

import { QueryKey } from '@tanstack/react-query';

export const getQueryKeyByModel = (model: Params): QueryKey => {
  if (model.type === "draft_certificate_pdf") {
    return ["applications", model.applicationId, "certificate"];
  }

  if (model.type === "csv_export_changes_log") {
    return ["csv_export_changes_log", model.applicationId];
  }

  if (model.type === "application") {
    return ["applications", model.id];
  }

  if (model.type) {
    return [model.type];
  }

  return [];
};
