import { Dayjs } from 'dayjs';
import { useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { checkErrors, getErrorMessage } from 'ui/form-utils';

import EventIcon from '@mui/icons-material/Event';
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export interface BasicDatePickerProps {
  label: string;
  helperText?: string;
  control: any;
  inputName: string;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  shouldDisableDate?: (date: Dayjs) => boolean;
  onChange?: (date: string) => void;
}

export default function BasicDatePicker(props: BasicDatePickerProps) {
  const {
    disabled = false,
    control,
    label,
    inputName,
    helperText = '',
    disablePast = false,
    disableFuture = false,
    shouldDisableDate = () => {
      return false;
    },
    onChange: onCustomChange,
  } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({
    name: inputName,
    control,
    defaultValue: '',
  });

  const myRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const isErrors = checkErrors(inputName, errors);

  const handleChange = (value: Dayjs | null) => {
    const stringValue = value?.format('YYYY-MM-DD') ?? '';

    onChange(stringValue);
    onCustomChange && onCustomChange(stringValue);

    if (myRef.current !== null) {
      // trigger form onchange§
      myRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  return (
    <>
      <div ref={myRef}></div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          disabled={disabled}
          open={isOpened}
          onOpen={() => setIsOpened(true)}
          onClose={() => setIsOpened(false)}
          label={label}
          disablePast={disablePast}
          disableFuture={disableFuture}
          disableMaskedInput={true}
          shouldDisableDate={shouldDisableDate}
          // inputFormat="M/DD/YYYY"
          onChange={(e: Dayjs | null) => {
            handleChange(e);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => {
                    setIsOpened(true);
                  }}
                >
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              inputProps={{ ...inputProps.inputProps, readOnly: true }}
              error={isErrors}
              helperText={getErrorMessage(inputName, errors) ?? helperText ?? null}
              InputLabelProps={{
                shrink: true,
              }}
              onClick={(e) => setIsOpened(true)}
              sx={{
                '& .MuiInputBase-input': {
                  caretColor: 'transparent',
                  padding: `0 0 0 ${theme.spacing(1)}`,
                  cursor: 'pointer',
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
}
