import { AnimatePresence, motion } from 'framer-motion';
import { getStepFromPath } from 'helpers/getStepFromPath';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AnimatedOutlet } from './AnimatedOutlet';

export interface AnimatedHorizontalRoutesProps {
  children: JSX.Element;
}

export default function AnimatedHorizontalRoutes() {
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [animationParams, setAnimationParams] = useState({
    initial: 100,
    exit: -100,
  });

  useEffect(() => {
    setCurrentStep((prevValue: number) => {
      const step = getStepFromPath(location.pathname);
      if (step < prevValue) {
        setAnimationParams({
          initial: 100,
          exit: -100,
        });
      }
      setAnimationParams({
        initial: 100,
        exit: -100,
      });
      return step;
    });
  }, [location.key]);

  return (
    <div style={{ position: "relative" }}>
      <AnimatePresence>
        <motion.div
          key={location.key}
          initial={{
            opacity: 1,
            position: "absolute",
            top: 0,
            width: "100%",
            transform: `translate(${animationParams.initial}%)`,
          }}
          animate={{
            opacity: 1,
            position: "absolute",
            top: 0,
            width: "100%",
            transform: "translate(0)",
            transitionEnd: {
              position: "relative",
            },
            transitionDuration: "0.2s",
          }}
          exit={{
            opacity: 1,
            position: "absolute",
            width: "100%",
            top: 0,
            transform: `translate(${animationParams.exit}%)`,
            transitionEnd: {
              display: "none",
            },
            transitionDuration: "0.2s",
          }}
        >
          <AnimatedOutlet />
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
