import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CircleSmallIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.664062 8.00057C0.664062 12.0507 3.94731 15.3339 7.9974 15.3339C12.0475 15.3339 15.3307 12.0507 15.3307 8.00057C15.3307 3.95048 12.0475 0.667236 7.9974 0.667236C3.94731 0.667236 0.664062 3.95048 0.664062 8.00057ZM13.9974 8.00057C13.9974 11.3143 11.3111 14.0006 7.9974 14.0006C4.68369 14.0006 1.9974 11.3143 1.9974 8.00057C1.9974 4.68686 4.68369 2.00057 7.9974 2.00057C11.3111 2.00057 13.9974 4.68686 13.9974 8.00057Z"
          fill="#213A6D"
        />
      </g>
    </SvgIcon>
  );
}
