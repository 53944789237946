import { Box, CircularProgress, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { UserProfile } from 'types';

import SaveIcon from 'ui/icons/SavelIcon';

export interface ReviewManagementProps {
  label: string;
  onSave: (userId: string) => void;
  defaultUserId?: string;
  userProfiles: UserProfile[];
  isLoading: boolean;
  isBold?: boolean;
}

export default function IssuanceSummary(props: ReviewManagementProps) {
  const { label, onSave, defaultUserId = "", userProfiles, isLoading, isBold = false } = props;
  const [userId, setUserId] = useState<string>(defaultUserId);
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent) => {
    const v = event.target.value;
    setUserId(v);
  };

  const handleSave = () => {
    onSave(userId);
  };

  return (
    <>
      <Grid item xs={12} tablet={5}>
        <Box mt={3}>
          <Typography
            component="h3"
            variant="h4"
            sx={{
              fontWeight: isBold ? "bold" : "normal",
              textTransform: "none",
              color: theme.palette.text.primary
            }}
          >
            {label}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} tablet={2}>
        <Box fontWeight="bold" display={{ xs: "none", lg: "block" }} mt={3}>
          -
        </Box>
      </Grid>
      <Grid item xs={12} tablet={4}>
        <InputLabel shrink={true}>Select reviewer</InputLabel>
        <Select
          value={userId}
          onChange={handleChange}
          displayEmpty
          className="white-bg"
        >
          <MenuItem value="" disabled>
            <Box className="placeholder">-- Not assigned --</Box>
          </MenuItem>
          {userProfiles.map((item) => (
            <MenuItem key={item.id} value={item.userId}>
              {item.fullName}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} tablet={1} mt={2}>
        <IconButton
          aria-label="Save"
          className="outlined-dark filled"
          to="#"
          component={Link}
          disabled={isLoading}
          onClick={handleSave}
        >
          {isLoading && <CircularProgress color="inherit" size={24} />}
          {!isLoading && <SaveIcon />}
        </IconButton>
      </Grid>
    </>
  );
}
