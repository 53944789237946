import { useRef } from 'react';
import Draggable from 'react-draggable';
import ButtonLoader from 'ui/components/button-loader/ButtonLoader';

import { Box, Paper, PaperProps } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export interface ItemDialogProps {
  children: any;
  open: boolean;
  title: string;
  submitText: string;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

function PaperComponent(props: PaperProps) {
  const nodeRef = useRef(null);
  return (
    <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} ref={nodeRef} />
    </Draggable>
  );
}

export default function ItemDialog(props: ItemDialogProps) {
  const { open, onCancel, onSubmit, children, isLoading, title, submitText } = props;

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move', fontSize: '2.8rem', fontWeight: 'bold' }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>

      <DialogActions disableSpacing>
        <Box mr={2}>
          <Button
            variant="outlined"
            onClick={() => {
              onCancel();
            }}
            autoFocus
          >
            Cancel
          </Button>
        </Box>
        <Button
          color="primary"
          onClick={() => {
            onSubmit();
          }}
          disableElevation
          variant="contained"
          disabled={isLoading}
        >
          <ButtonLoader isActive={isLoading} noMargin={false} />
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
