export const commonTableStyles = {
  table: {
    width: '100%',
    'thead th': {
      background: '#213A6D',
      padding: '16px 24px',
    },
    '& tbody td': {
      padding: '16px',
    },
    '& tbody td.is-warning': {
      color: '#d50037',
    },
    '& tbody tr.is-warning td': {
      background: '#ffe9ee',
    },
    '& tbody td:first-of-type': {
      paddingLeft: '24px',
    },
    '& tbody td:last-child': {
      paddingRight: '24px',
    },
    cellHelper: {
      fontSize: '1.2rem',
    },
  },
  tableWrapper: {
    width: '100%',
    background: '#fff',
    overflow: 'auto',
  },
};
