import { ApplicationPaymentState } from 'types';
import StatusCard, { StatusCardState } from 'ui/components/status-card/StatusCard';
import { scrollToSectionId } from 'ui/helpers/scrollToSectionId';
import { Button } from '@mui/material';
import { getLinkToDetails } from 'app-sections/user/applications/steps/config';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const getCardState = (type: ApplicationPaymentState): StatusCardState => {
  if (type === "failed") {
    return "failed";
  }

  if (type === "paid") {
    return "success";
  }

  return "pending";
};

const getTitle = (type: ApplicationPaymentState): string => {
  if (type === "failed") {
    return "Failed payment";
  }

  if (type === "paid") {
    return "Successful payment";
  }

  return "Payment processing";
};

const getContent = (type: ApplicationPaymentState): string | JSX.Element=> {
  if (type === "failed") {
    return "Your payment attempt was unsuccessful. Please review your payment details and try again.";
  }

  if (type === "paid") {
    return "Payment has been successfully processed. The Authorization Form is accepted and the Wall Check application number is created.";
  }

  return  (
    <>
      Thank you for your payment! We are currently processing your transaction. Please allow some time for the payment to be completed.
      <br/>
      <br/>
      ACH payments can take up to 4 days to process. Once your payment is successfully processed, you can proceed with your application.
    </>
  );
};

const getButtonForStatus = (status: ApplicationPaymentState, applicationId: string, navigate: NavigateFunction): JSX.Element | null => {
  switch (status) {
    case "failed":
      return getButton("Try again", () => scrollToSectionId("payments-section"));
    case "paid":
      return getButton("Download form", () => {
        navigate(getLinkToDetails(applicationId, "summary?payment_status=success#download-form-section"));
        scrollToSectionId("download-form-section");
      });
  }
  return null;
}

const getButton = (label: string, onClickHandle: () => void): JSX.Element | null => {
  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      disableElevation
      onClick={onClickHandle}
    >
      {label}
    </Button>
  );
};

export interface PaymentStatusInfoProps {
  status: ApplicationPaymentState;
  applicationId: string;
}

export default function PaymentStatusInfo(props: PaymentStatusInfoProps) {
  const { status, applicationId } = props;
  const navigate = useNavigate();

  return (
    <StatusCard
      title={getTitle(status)}
      content={getContent(status)}
      isLoading={status === "pending"}
      status={getCardState(status)}
      button={getButtonForStatus(status, applicationId, navigate)}
    />
  );
}
