import { Application } from 'types';

export interface ApplicationAddressesProps {
  application: Application;
}

export function formatAddress(addressLine?: string): string {
  if (!addressLine) return '';
  return addressLine.split(',')[0].trim();
}

export default function ApplicationAddresses(props: ApplicationAddressesProps) {
  const { application } = props;

  return (
    <>
      {application.permitNumbers.map((permit) => (
        <div key={permit.recordId}>
          {formatAddress(permit.addressLine)}
          <br />
        </div>
      ))}
    </>
  );
}
