import { Outlet } from "react-router-dom";

import { useMediaQuery, useTheme } from "@mui/material";

import AnimatedHorizontalRoutes from "../animated-routes/AnimatedHorizontalRoutes";
import AnimatedRoutes from "../animated-routes/AnimatedRoutes";

/**
 * Turn off animated routes for displays wider than 500px
 *
 * Leave animated routes only on mobile (small screen) devices, in view without sidebar
 *
 * @returns Animated or regular Router `<Outlet/>`
 */

export interface CustomRoutesProviderProps {
  mobileAnimationType: "opacity" | "slide";
  forceNoAnimation?: boolean;
}

export default function CustomRoutesProvider(props: CustomRoutesProviderProps) {
  const { mobileAnimationType, forceNoAnimation = false } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  if (!matches && !forceNoAnimation) {
    if (mobileAnimationType === "opacity") {
      return <AnimatedRoutes />;
    }
    return <AnimatedHorizontalRoutes />;
  }
  return <Outlet />;
}
