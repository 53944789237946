import { SvgIcon, SvgIconProps } from '@mui/material';

export default function AddRoundIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 22" fill="none">
      <g fill="none">
        <path
          d="M11 7.25V14.75"
          stroke="#0062A0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 11H14.75"
          stroke="#0062A0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 20.375C16.1777 20.375 20.375 16.1777 20.375 11C20.375 5.82233 16.1777 1.625 11 1.625C5.82233 1.625 1.625 5.82233 1.625 11C1.625 16.1777 5.82233 20.375 11 20.375Z"
          stroke="#0062A0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
