import { UserData } from 'types';

import { request } from '../config';

interface UserDataResponse {
  user: UserData;
}

export function getCurrentUserData(): Promise<UserDataResponse> {
  return request({
    url: "users/current-user.json",
    method: "GET",
  });
}

export function logout(): Promise<UserDataResponse> {
  return request({
    url: "auth.json",
    method: "DELETE",
  });
}
