import { getLinkToDetails, getLinkToStep } from 'app-sections/user/applications/steps/config';
import { PAYMENT_STEP } from 'app-sections/user/applications/steps/step3/Step3';
import { getStepFromPath } from 'helpers/getStepFromPath';
import { Location } from 'react-router-dom';
import { Application, UserData } from 'types';
import { isAdmin, isDcRegisteredSurveyor } from 'ui/helpers/users';

const getPathParts = (path: string): string[] => {
  return path.split('/');
};

export const applicationsResolverGuards = (
  data: Application,
  location: Location,
  currentUser: UserData | null
): string => {
  const canUserViewResult = canUserViewApp(data, currentUser);
  if (canUserViewResult !== '') {
    return canUserViewResult;
  }

  const pathParts = getPathParts(location.pathname);
  const result = canBeOnCurrentSection(data, location);
  if (result !== '') {
    return result;
  }

  if (pathParts.includes('step')) {
    return canBeOnCurrentStepGuard(data, location);
  }

  return '';
};

export const canBeOnCurrentStepGuard = (data: Application, location: Location): string => {
  const step = getStepFromPath(location.pathname);
  if (step > data.currentStep + 1) {
    console.error('Application not allowed to be on this step.');
    return getLinkToStep(data.id, data.currentStep + 1);
  }
  if (step !== PAYMENT_STEP && data.state === 'pending_payment') {
    console.error('Application is pending_payment and is not allowed to be on other step.');
    return getLinkToStep(data.id, PAYMENT_STEP);
  }
  return '';
};

export const canBeOnCurrentSection = (data: Application, location: Location): string => {
  const pathParts = getPathParts(location.pathname);
  const stepsStates = ['draft', 'pending_payment', 'paid'];
  if (pathParts.includes('details') && stepsStates.includes(data.state)) {
    console.error('Application not allowed to be on section.');
    return getLinkToStep(data.id, data.currentStep + 1);
  }

  if (pathParts.includes('step') && !stepsStates.includes(data.state)) {
    console.error('Application not allowed to be on section.');
    return getLinkToDetails(data.id, 'summary?payment_status=success');
  }

  return '';
};

export const canUserViewApp = (data: Application, currentUser: UserData | null): string => {
  if (!currentUser) {
    return '/';
  }
  const roles = currentUser.userProfile.roles;

  // Admin can always view the Application
  if (!isAdmin(roles)) {
    if (data?.clientId !== currentUser.id) {
      if (isDcRegisteredSurveyor(roles) && data.surveyorId === currentUser.id) {
        return '';
      } else {
        return '/applications';
      }
    }
  }
  return '';
};
