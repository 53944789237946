import { VariantOptionId } from 'app-sections/user/applications/steps/step3/config';
import * as Yup from 'yup';

import { ApplicationState } from '../../../../../types';

export type SearchByOpts = "all" | "id" | "address" | "ssl" | "applicant" | "permit_num";

export const ALL_OPT = {
  id: "",
  name: "All",
};

export const SEARCH_BY_OPTS = [
  {
    id: "id",
    name: "Application Number",
  },
  {
    id: "address",
    name: "Address",
  },
  {
    id: "ssl",
    name: "SSL",
  },
  {
    id: "applicant",
    name: "Applicant",
  },
  {
    id: "permit_num",
    name: "Permit Number",
  },
  {
    id: "dc_registered_surveyor",
    name: "DC Registered Surveyor",
  },
];

export const APP_STATUS = [
  ALL_OPT,
  {
    id: "under_review",
    name: "Under Review",
  },
  {
    id: "waiting_on_client",
    name: "Waiting on Client",
  },
  {
    id: "approved",
    name: "Approved",
  },
  {
    id: "denied",
    name: "Denied",
  },
];

export const APP_ASSIGNMENT = [
  ALL_OPT,
  {
    id: "me",
    name: "Applications assigned to me",
  },
];

export const APP_PAYMENT = [
  ALL_OPT,
  {
    id: "pending",
    name: "Pending",
  },
  {
    id: "completed",
    name: "Completed",
  },
  {
    id: "overdue",
    name: "Overdue",
  },
];

export interface FormValues {
  state: ApplicationState | "";
  typeOfApplication: VariantOptionId | "";
  searchBy: SearchByOpts | "";
  search: string;
  submittedAtGte: string | null;
  submittedAtLte: string | null;
  slaDeadlineAtGte: string | null;
  slaDeadlineAtLte: string | null;
  paymentStatus: string;
  assignedTo: string;
  currentDivision: string;
}

export const defaultValues: FormValues = {
  searchBy: "",
  state: "",
  typeOfApplication: "",
  search: "",
  submittedAtGte: "",
  submittedAtLte: "",
  slaDeadlineAtGte: "",
  slaDeadlineAtLte: "",
  paymentStatus: "",
  assignedTo: "",
  currentDivision: "",
};

const detailsFields = {};

export const getValidationSchema = () => {
  return Yup.object({
    ...detailsFields,
  });
};
