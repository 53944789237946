import { parseFormValuesToParams, parseSearchParams } from 'app-sections/common/applications/helpers';
import { getApplications } from 'core/api/applications';
import { getUserProfiles } from 'core/api/users';
import { useAuthStore } from 'core/store/auth';
import { omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiCollectionResponse, Application, UserRole } from 'types';
import CsvButton from 'ui/components/csv-button/CsvButton';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import LinesSkeleton from 'ui/components/skeletons/lines-skeleton/LinesSkeleton';
import { isFromZoning } from 'ui/helpers/users';

import { Box, Card, CardContent, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import ApplicationsData from './components/applications-data/ApplicationsData';
import ApplicationSearchForm from './components/applications-search/ApplicationsSearchForm';
import { FormValues } from './components/applications-search/validation-schema';
import ApplicationsTabs from './components/applications-tabs/ApplicationsTabs';

export const DRAFT_STATES_VALUES = ['draft', 'pending_payment'];
export const DEFAULT_STATES_VALUES = ['surveyor_office_review', 'zoning_review', 'client_action_needed', 'swo'];
export const PENDING_STATES_VALUES = ['pending_invitation', 'pending_no_pending_invitation', 'swo'];
export const CLOSED_STATES_VALUES = [
  'completed',
  'denied',
  'completed_partially',
  'completed_below_grade',
  'deleted_by_client',
];

const setDefaultValues = (params: any): any => {
  if (!params['states']) {
    params['states'] = DEFAULT_STATES_VALUES;
  }

  return params;
};

const getRole = (roles: UserRole[]): UserRole[] => {
  if (isFromZoning(roles)) {
    return ['zoning_manager', 'zoning_technician'];
  } else {
    return ['surveyor_office_manager', 'surveyor_office_technician'];
  }
};

export default function Applications() {
  const queryClient = useQueryClient();
  const { currentUser } = useAuthStore();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();
  const parsedSearchParams = setDefaultValues(parseSearchParams(searchParams));
  const [ifReset, setIfReset] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<ApiCollectionResponse<Application> | undefined>();

  const profilesQueryKey = ['userProfiles', 'admins', ...getRole(currentUser?.userProfile?.roles || [])];
  const {
    isLoading: isProfilesLoading,
    isError: isProfilesError,
    data: profilesData,
    error: profilesError,
  } = useQuery({
    queryKey: profilesQueryKey,
    queryFn: () => getUserProfiles('admin'),
  });

  const { isLoading, isFetching, isError, data, error, isSuccess } = useQuery({
    queryKey: ['applications/get', searchParamsString],
    queryFn: ({ signal }) => getApplications(parsedSearchParams, signal),
    enabled: Boolean(profilesData),
  });

  const updateSearchParams = (paramsToUpdate: any) => {
    setSearchParams((currentValues) => {
      const combinedParams = {
        ...parseSearchParams(currentValues),
        ...paramsToUpdate,
      };
      return setDefaultValues(combinedParams);
    });
  };

  useEffect(() => {
    if (Object.keys(parsedSearchParams).length === 0) {
      setIfReset((prev) => prev + 1);
    }
    if (isSuccess) {
      setLoadedData(data);
    }
  }, [searchParamsString, queryClient, isSuccess]);

  const anyError = error || profilesError;
  const isAnyError = isError || isProfilesError;

  return (
    <Box mt={5}>
      <Container className="disable-center">
        <>
          <Typography variant="h1" component="h1" className="with-indicator">
            Applications
          </Typography>

          <Box mt={4} display="flex">
            <CsvButton
              queryParams={parsedSearchParams}
              csvType="csv_export_applications"
              queryKey={['csv_export_applications']}
            />
          </Box>

          <Box my={3} fontSize="2rem">
            Select a submitted application, review the provided data and notify the applicant within application upon
            need of any corrections or missing information (e.g. documents).
          </Box>

          {!isProfilesLoading && profilesData && (
            <ApplicationSearchForm
              ifReset={ifReset}
              defaults={omit(parsedSearchParams, ['states']) as FormValues}
              currentParams={parsedSearchParams}
              adminProfiles={profilesData}
              isLoading={isLoading}
              onFormChange={(v) => {
                updateSearchParams(parseFormValuesToParams({ ...v, page: '1' }));
              }}
              onFormSubmit={(v) => {
                updateSearchParams(parseFormValuesToParams({ ...v, page: '1' }));
              }}
            />
          )}

          {isLoading && !isAnyError && !loadedData && (
            <Card>
              <CardContent>
                <Box mb={4}>
                  <LinesSkeleton elements={4} isWithButtons={false} lineHeight={35} />
                </Box>
              </CardContent>
            </Card>
          )}
          {loadedData && !isAnyError && (
            <>
              <Box mb={2}>
                <ApplicationsTabs
                  parsedSearchParams={parsedSearchParams}
                  onChange={(sections: string[]) => {
                    updateSearchParams({
                      states: sections,
                      page: '1',
                    });
                  }}
                />
              </Box>
              <ApplicationsData
                isFetching={isFetching || isLoading}
                data={loadedData.results}
                parsedSearchParams={parsedSearchParams}
                page={parsedSearchParams?.page || '1'}
                totalPages={loadedData.totalPages}
                onPageChange={(page: string) => {
                  updateSearchParams({ page });
                }}
              />
            </>
          )}

          {isAnyError && <ErrorsList title="Unexpected loading error" errors={anyError as string[]} />}
        </>
      </Container>
    </Box>
  );
}
