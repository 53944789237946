import { Params, ReviewSectionStateId } from 'types';

export const getIndexById = (id: string, tabsCollection: Params[]): number => {
  return tabsCollection.findIndex((item) => id === item.id);
};

export const getNameById = (id: string, tabsCollection: Params[]): string => {
  return tabsCollection.find((item) => id === item.id)?.name || "";
};

export const getSectionStateIdById = (
  id: string,
  tabsCollection: Params[]
): ReviewSectionStateId => {
  return tabsCollection.find((item) => id === item.id)?.sectionStateId || "";
};

export const getSectionByPath = (
  path: string,
  parentSection: string
): string => {
  const pathArray = path.split("/");
  const index = pathArray.findIndex((slice: string) => slice === parentSection);

  if (index !== -1) {
    return pathArray[index + 1] || "";
  }
  return "";
};
