import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

import { AnimatedOutlet } from "./AnimatedOutlet";

export interface AnimatedRoutesProps {
  children: JSX.Element;
}

export default function AnimatedRoutes() {
  const location = useLocation();
  // ADD max path length for nested routes
  return (
    <div style={{ position: "relative" }}>
      <AnimatePresence>
        <motion.div
          key={location.pathname}
          initial={{
            opacity: 0,
            position: "absolute",
            top: 0,
            width: "100%",
          }}
          animate={{
            opacity: 1,
            position: "absolute",
            top: 0,
            width: "100%",
            transitionEnd: {
              position: "relative",
            },
            transitionDuration: "1s",
          }}
          exit={{
            opacity: 0,
            position: "absolute",
            top: 0,
            transitionEnd: {
              display: "none",
            },
            transitionDuration: "1s",
          }}
        >
          <AnimatedOutlet />
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
